import { Method } from 'axios';

import api, { apiError } from '../utilities/api';
import { IApi } from '../types/api/serialized';
import { IPayload, IResponse } from './types';

/**
 * getCartStatus
 *
 * @param tripPnr
 * @return Promise with status of the selected cart
 *
 * COMPLETE: Cart was already checked out. We will typically redirect to checkout complete page in this case
 * ACTIVE: Cart has an up to date quote (indicating that it is available) and can be Checked out
 * LOST: Cart is in a state that the Customer should not be able to access
 * SHOULD_REPRICE: We need to perform an availability check on the cart before proceeding
 */
export async function getCartCheckoutStatus(
  tripPnr?: string,
): Promise<{ cartStatus: 'COMPLETE' | 'ACTIVE' | 'LOST' | 'SHOULD_REPRICE'; pnr?: string }> {
  const path = `v1/carts/${tripPnr}/checkout`;

  try {
    const { data } = await api.request({ path, protectRoute: true });
    return data.data;
  } catch (err) {
    throw apiError(err);
  }
}

/*
 * Market::CartCheckoutController#update
 */
export async function postCartCheckout(
  tripPnr: string,
  signed,
  couponCode = null,
  contactDetails = null,
  paymentDetails = null,
): Promise<IApi.Serialized.CartPriceAvailabilityCheckResult> {
  const path = `v1/carts/${tripPnr}/checkout`;
  const method: Method = 'POST';

  try {
    const { data }: IResponse<IPayload<IApi.Serialized.CartPriceAvailabilityCheckResult>> = await api.request({
      path,
      method,
      data: {
        contract_signed: signed,
        coupon_code: couponCode,
        contact_info: contactDetails,
        payment_method: paymentDetails,
      },
      protectRoute: true,
    });
    return data.data;
  } catch (err) {
    throw apiError(err);
  }
}

/*
 * Market::CartCheckoutController#update
 */
export async function patchCartCheckout(tripPnr: string, paymentIntentId: string, marketBookingRequestUuid?: string): Promise<IApi.Serialized.CartPriceAvailabilityCheckResult> {
  // Payments TODO: We could pass up the PaymentIntent ID here instead of the PNR, leaving it as placeholder
  const path = `v1/carts/${tripPnr}/checkout/${paymentIntentId}`;
  const method: Method = 'PATCH';

  try {
    const { data }: IResponse<IPayload<IApi.Serialized.CartPriceAvailabilityCheckResult>> = await api.request({
      path,
      method,
      protectRoute: true,
      data: {
        marketBookingRequestUuid
      }
    });
    return data.data;
  } catch (err) {
    throw apiError(err);
  }
}
