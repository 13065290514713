// @ts-nocheck
import React, { FunctionComponent, useEffect, useState } from 'react';
import Front from '@frontapp/plugin-sdk';
import { useRouter } from 'next/router';
import qs from 'qs';
import { find } from 'lodash';
import { getCustomerFrontContact } from '@jetslash/market-frontend-shared-core/src/api/frontPluginApi';
import Reporty from '../../utility/errorHandling/Reporty';

interface IProps {}

interface IDefaultState {
  currentFrontContext: any;
  selectedUserId: string;
  onFrontPluginPage: boolean;
  setOnFrontPluginPage: () => void;
}

const DefaultState: IDefaultState = {
  currentFrontContext: null,
  selectedUserId: null,
  onFrontPluginPage: false,
  setOnFrontPluginPage: () => {},
};

export const FrontPluginContext = React.createContext(DefaultState);

export const FrontPluginContextProvider: FunctionComponent<IProps> = ({ children }) => {
  const [frontContext, setFrontContext] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const router = useRouter();
  const onFrontPluginPage = router.pathname.startsWith('/internal/front-plugin');
  let authSecret;
  if (typeof window !== 'undefined') {
    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    authSecret = query?.auth_secret;
  }
  const initFrontEventListeners = async () => {
    Front.contextUpdates.subscribe((context) => {
      setFrontContext(context);
      switch (context.type) {
        case 'noConversation':
          console.log('No conversation selected');
          break;
        case 'singleConversation':
          // eslint-disable-next-line no-case-declarations
          const handles = context?.conversation?.recipient?.contact?.handles;
          if (handles?.length && find(handles, ['type', 'custom'])) {
            const customHandle = find(handles, ['type', 'custom']);
            setSelectedUserId(customHandle.handle);
          } else if (context?.conversation?.recipient?.contact?.id) {
            getCustomerFrontContact(authSecret, context?.conversation?.recipient?.contact?.id)
              .then((contact) => {
                if (contact?.handles?.length && find(contact.handles, ['source', 'custom'])) {
                  const customHandle = find(contact.handles, ['source', 'custom']);
                  setSelectedUserId(customHandle.handle);
                } else {
                  console.info('Could not find a custom handle on Front Contact', {
                    handles: contact?.handles,
                    id: context?.conversation?.recipient?.handle,
                    serializedHandles: JSON.stringify(contact?.handles),
                    contact: JSON.stringify(context?.conversation?.recipient?.contact),
                  });
                  setSelectedUserId('nohandle');
                }
              })
              .catch((e) => {
                Reporty.error(e);
                setSelectedUserId('nohandle');
              });
          } else {
            console.info('Conversation recipient does not have a contact:', context?.conversation?.recipient);
            setSelectedUserId('nohandle');
          }
          break;
        case 'multiConversations':
          console.info('Multiple conversations selected', context.conversations);
          break;
        default:
          console.error(`Unsupported context type: ${context.type}`);
          break;
      }
    });
  };

  useEffect(() => {
    initFrontEventListeners()
      .then(() => {})
      .catch((e) => {
        console.error(e);
        Reporty.error(e);
      });
  }, []);

  const value = {
    currentFrontContext: frontContext,
    selectedUserId,
    onFrontPluginPage,
  };

  return <FrontPluginContext.Provider value={value}>{children}</FrontPluginContext.Provider>;
};

export default FrontPluginContextProvider;
