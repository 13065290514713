// @ts-nocheck
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useSpring, useTransition, animated, config } from 'react-spring';

const styles = {
  textTransition: {
    position: 'relative',
  } as React.CSSProperties,
  textTransitionPlaceholder: {
    visibility: 'hidden',
  } as React.CSSProperties,
  textTransitionInner: {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  } as React.CSSProperties,
};

const newText = (text) => ({ key: `${Date.now()}`, data: text });

interface IProps {
  text: string | string[] | number;
  inline?: boolean;
  noOverflow?: boolean;
  left?: boolean;
  right?: boolean;
  delay?: number;
  className?: string;
  // eslint-disable-next-line react/forbid-prop-types
  style?: any;
  // eslint-disable-next-line react/forbid-prop-types
  springConfig?: any;
}

const TextTransition: FunctionComponent<IProps> = ({
  text,
  inline,
  delay = 0,
  className,
  style = {},
  noOverflow = false,
  springConfig = config.default,
  right = false,
  left = false,
}) => {
  const placeholderRef = React.useRef(null);
  const [content, setContent] = React.useState(() => newText(''));
  const [hasSet, setHasSet] = useState(false);
  const [timeoutId, setTimeoutId] = React.useState(0);
  const [containerStyles, setWidth] = useSpring(() => ({
    initial: null,
    to: { width: inline ? 0 : 'auto' },
    config: springConfig,
  }));

  // @ts-ignore
  const transitions = useTransition(content, (item) => item.key, {
    initial: null,
    from: { display: 'none', opacity: 0, asdf: 0 },
    enter: [hasSet ? { asdf: 1 } : {}, { display: 'block', opacity: 1 }],
    leave: [{ opacity: 0 }, { display: 'none' }],
    config: { tension: 120, friction: 18, clamp: true },
  });

  const hasChanged = (arg1: [], arg2: []) => {
    if (typeof arg1 !== 'object' || typeof arg2 !== 'object') {
      return true;
    }

    if (!arg1 || !arg1.length || !arg2 || !arg2.length) {
      return true;
    }

    return arg1.join('') !== arg2.join('');
  };

  useEffect(() => {
    if (text && text.length && !hasSet) {
      setHasSet(true);
    }
  }, [text]);

  React.useEffect(() => {
    // @ts-ignore
    if (hasChanged(text, content.data)) {
      // @ts-ignore
      setTimeoutId(
        // @ts-ignore
        setTimeout(() => {
          if (!placeholderRef.current) return;
          placeholderRef.current.innerHTML = text;
          // @ts-ignore
          if (inline) setWidth({ width: placeholderRef.current.offsetWidth });
          setContent(newText(text));
        }, delay),
      );
    }
  }, [text]);

  const alignmentProps = {};
  if (right) {
    alignmentProps.right = 0;
    alignmentProps.left = null;
  }
  if (left) {
    alignmentProps.left = 0;
    alignmentProps.right = null;
  }

  React.useEffect(() => () => clearTimeout(timeoutId), []);

  return (
    <animated.div
      style={{
        ...styles.textTransition,
        ...containerStyles,
        whiteSpace: inline ? 'nowrap' : 'normal',
        display: inline ? 'inline-block' : 'block',
        ...style,
      }}
      className={`text-transition ${className}`}
    >
      <span ref={placeholderRef} className="text-transition_placeholder" style={styles.textTransitionPlaceholder} />
      <div
        className="text-transition_inner"
        style={{
          ...styles.textTransitionInner,
          ...(noOverflow ? { overflow: 'hidden' } : {}),
          ...alignmentProps,
        }}
      >
        {transitions.map(({ item, props, key }) => (
          <animated.div key={key} style={{ ...alignmentProps, ...props, ...{ position: 'absolute' } }}>
            {item.data}
          </animated.div>
        ))}
      </div>
    </animated.div>
  );
};

export default TextTransition;

export { config as presets };
