import React, { FunctionComponent } from 'react';
import { IRecentRequest } from '@jetslash/market-frontend-shared-core/src/contexts/FlightSearchRequestContext/useRecentRequests';

import OneRecentRequestLinkButton from '@/components/recentRequests/OneRecentRequestLinkButton';
import styles from './RecentRequestsModal.module.scss';

interface IProps {
  recentRequests: IRecentRequest[];
  onSelect: () => void;
}

const RecentRequestsModal: FunctionComponent<IProps> = ({ recentRequests, onSelect }) => (
  <div className={styles.RecentRequestsModal}>
    {recentRequests.map((recentRequest) => (
      <div style={{ marginBottom: 8 }}>
        <OneRecentRequestLinkButton recentRequest={recentRequest} onSelect={onSelect} />
      </div>
    ))}
  </div>
);

export default RecentRequestsModal;
