import { useContext } from 'react';
import { LoadingContext } from './index';

interface IResult {
  // Values
  fullScreenLoading: boolean;
  loading: boolean;
  // Actions
  setFullScreenLoading?: (isLoading: boolean) => void;
  setLoading?: (isLoading: boolean) => void;
}

const useLoadingContext = (): IResult => {
  const context = useContext(LoadingContext);
  return context;
};

export default useLoadingContext;
