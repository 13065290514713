import React, { FunctionComponent, useState } from 'react';
import {
  IPlatformToolReporty,
  IPlatformToolsAnalyticsTracker,
  IPlatformToolsPersistenceProvider,
} from '../../types/platform';

interface IProps {
  Reporty: IPlatformToolReporty; // Exposes generic error reporting interface
  PersistenceProvider: IPlatformToolsPersistenceProvider; // Exposes generic Persistence interface
  useAnalyticsTrackingProvider: () => IPlatformToolsAnalyticsTracker; // Exposes generic hook for analytics reporting
  children: React.ReactNode;
}

interface IProvidedState {
  Reporty: IPlatformToolReporty; // Injected service for reporting errors (backed by Sentry Web and Sentry Native)
  PersistenceProvider: IPlatformToolsPersistenceProvider; // Injected service for persisting data in local storage
  track: (eventName: string, data: any) => void; // Injected service for reporting analytics (backed by Segment Web and Segment Native)
}

const DefaultState: IProvidedState = {
  Reporty: null,
  PersistenceProvider: null,
  track: null,
};

export const PlatformToolsContext = React.createContext(DefaultState);

/**
 * PlatformToolsContext
 *
 * This allows our Web and Native implementations to re-use the same logic even if it is dependent on a platform-specific package
 *
 * For instance, let's say we have a service in Shared Core that needs to report errors. Sentry is platform-specific, so we can't import both
 * Web Sentry and Native Sentry into Shared Core. Instead, we inject the platform-specific Sentry into PlatformToolsContext when the app is spun up
 * so that children can consume a generic interface for error reporting backed by the injected service
 *
 */
export const PlatformToolsContextProvider: FunctionComponent<IProps> = (props) => {
  const { track } = props.useAnalyticsTrackingProvider();
  const [state] = useState({
    Reporty: props.Reporty,
    PersistenceProvider: props.PersistenceProvider,
    track,
  });

  return <PlatformToolsContext.Provider value={state}>{props.children}</PlatformToolsContext.Provider>;
};
