import React from 'react';

import { getUserTestimonials, IUserTestimonialSection } from '@/pageGeneration/services/prismicApi/userTestimonials';
import { getStaticPage, StaticPageStructure } from '@/pageGeneration/services/prismicApi/staticPagesApi';

import packageStaticProps from '@/utility/nextJs/packageStaticProps';
import useCustomPageViews from '@/utility/eventTracking/useCustomPageViews';
import { PAGE_CATEGORIES } from '@/utility/eventTracking/constants';

import ImageHeaderLayout from '@/pages/layouts/ImageHeaderLayout';

import HomePageComponent from '@/components/pageComponents/HomePageComponent';
import PageSeo from '@/components/seo/PageSeo';
import UnprotectedPage from '@/components/navigation/UnprotectedPage';
import CityCarousel from '@/components/landingPages/CityCarousel';
import { Row } from '@/components/layout';
import styles from '@/components/layout/headers/StaticPageHeader.module.scss';
import Header from '@/components/typography/Header';
import PrismicRichText from '@/components/design/PrismicRichText';
import Col from '@/components/layout/Col';

interface IProps {
  pageStructure: StaticPageStructure;
  userTestimonialSection: IUserTestimonialSection;
}

const PAGE_ID = 'home';

const Home = ({ pageStructure, userTestimonialSection }: IProps) => {
  useCustomPageViews({ category: PAGE_CATEGORIES.STATIC, name: 'Home' });

  const headerContent = (
    <div className={styles.container}>
      <Row>
        <Col xl={24} lg={24} md={24} sm={0} xs={0}>
          <CityCarousel />
          <PrismicRichText className={styles.subtitle} items={pageStructure.subtitle} color="white" />
        </Col>
        <Col xl={0} lg={0} md={0} sm={24} xs={24}>
          <Header level={1} strong color="white" responsive className={styles.header1}>
            {pageStructure.title}
          </Header>
        </Col>
      </Row>
    </div>
  );

  return (
    <UnprotectedPage>
      <ImageHeaderLayout imageUrl={pageStructure?.heroImage.url} headerContent={headerContent}>
        <PageSeo title={pageStructure.title} />
        <HomePageComponent
          leftCard={pageStructure.contentTop[0]}
          rightCard={pageStructure.contentTop[1]}
          userTestimonialSection={userTestimonialSection}
          bottomImage={pageStructure.contentBottom[0].image}
          bottomTitle={pageStructure.contentBottom[0].title}
          bottomText={pageStructure.contentBottom[0].text}
        />
      </ImageHeaderLayout>
    </UnprotectedPage>
  );
};

export default Home;

export async function getStaticProps() {
  const homePageData = await getStaticPage(PAGE_ID);
  const userTestimonialData = await getUserTestimonials();

  return packageStaticProps({
    pageStructure: homePageData,
    userTestimonialSection: userTestimonialData,
  });
}
