import qs from 'qs';
import { useCookies } from 'react-cookie';
import cookieConfig from '@/utility/session/cookies/cookieConfig';

interface IUtmParamters {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  initial_utm_source?: string;
  initial_utm_medium?: string;
  initial_utm_campaign?: string;
  initial_utm_term?: string;
  initial_utm_content?: string;
}

class UtmParameters {
  utm_source?: string;

  utm_medium?: string;

  utm_campaign?: string;

  utm_term?: string;

  utm_content?: string;

  initial_utm_source?: string;

  initial_utm_medium?: string;

  initial_utm_campaign?: string;

  initial_utm_term?: string;

  initial_utm_content?: string;

  constructor(
    utm_source = null,
    utm_medium = null,
    utm_campaign = null,
    utm_term = null,
    utm_content = null,
    initial_utm_source = null,
    initial_utm_medium = null,
    initial_utm_campaign = null,
    initial_utm_term = null,
    initial_utm_content = null,
  ) {
    this.utm_source = utm_source;
    this.utm_medium = utm_medium;
    this.utm_campaign = utm_campaign;
    this.utm_term = utm_term;
    this.utm_content = utm_content;
    this.initial_utm_source = initial_utm_source;
    this.initial_utm_medium = initial_utm_medium;
    this.initial_utm_campaign = initial_utm_campaign;
    this.initial_utm_term = initial_utm_term;
    this.initial_utm_content = initial_utm_content;
  }

  static fromUrlParams(params: IUtmParamters) {
    return new UtmParameters(
      params.utm_source,
      params.utm_medium,
      params.utm_campaign,
      params.utm_term,
      params.utm_content,
      null,
      null,
      null,
      null,
      null,
    );
  }

  static fromStoredParams(storedParams: IUtmParamters) {
    return new UtmParameters(
      storedParams.utm_source,
      storedParams.utm_medium,
      storedParams.utm_campaign,
      storedParams.utm_term,
      storedParams.utm_content,
      storedParams.initial_utm_source,
      storedParams.initial_utm_medium,
      storedParams.initial_utm_campaign,
      storedParams.initial_utm_term,
      storedParams.initial_utm_content,
    );
  }

  utmSourcePresent() {
    return !!this.utm_source?.length;
  }

  initialUtmSourcePresent() {
    return !!this.initial_utm_source?.length;
  }

  setSelfWithInitialParams(utmParams) {
    this.initial_utm_source = utmParams.initial_utm_source;
    this.initial_utm_medium = utmParams.initial_utm_medium;
    this.initial_utm_campaign = utmParams.initial_utm_campaign;
    this.initial_utm_term = utmParams.initial_utm_term;
    this.initial_utm_content = utmParams.initial_utm_content;
  }

  copySelfToInitialParams() {
    this.initial_utm_source = this.utm_source;
    this.initial_utm_medium = this.utm_medium;
    this.initial_utm_campaign = this.utm_campaign;
    this.initial_utm_term = this.utm_term;
    this.initial_utm_content = this.utm_content;
  }

  toHash(): IUtmParamters {
    return {
      utm_source: this.utm_source,
      utm_medium: this.utm_medium,
      utm_campaign: this.utm_campaign,
      utm_term: this.utm_term,
      utm_content: this.utm_content,
      initial_utm_source: this.initial_utm_source,
      initial_utm_medium: this.initial_utm_medium,
      initial_utm_campaign: this.initial_utm_campaign,
      initial_utm_term: this.initial_utm_term,
      initial_utm_content: this.initial_utm_content,
    };
  }
}

const generateFromUrlParameters = (queryString = ''): UtmParameters | null => {
  const queryParams = qs.parse(queryString, { ignoreQueryPrefix: true });

  return UtmParameters.fromUrlParams(queryParams);
};

const UTM_PARAMS_KEY = '_app_utm';

const useUtmParameters = () => {
  const [cookies, setCookie, removeCookie] = useCookies([]);

  const setWithQueryString = (queryString) => {
    const utmParams = generateFromUrlParameters(queryString);

    const storedUtmParams = UtmParameters.fromStoredParams(cookies[UTM_PARAMS_KEY] || {});

    if (!utmParams.utmSourcePresent()) return;

    const newUtmParams = utmParams;

    if (storedUtmParams.initialUtmSourcePresent()) {
      newUtmParams.setSelfWithInitialParams(storedUtmParams);
    } else {
      newUtmParams.copySelfToInitialParams();
    }

    const utmHash = newUtmParams.toHash();

    setCookie(UTM_PARAMS_KEY, utmHash, cookieConfig);
  };

  const clearUtmParams = () => {
    removeCookie(UTM_PARAMS_KEY, cookieConfig);
  };

  return {
    setWithQueryString,
    clearUtmParams,
  };
};

export default useUtmParameters;
