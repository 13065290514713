import React, { FunctionComponent } from 'react';
import { Typography } from 'antd';
import TextTransition, { presets } from './TextTransition';

interface IProps {
  children?: string[] | string | Element;
  type?: 'secondary' | 'warning' | 'danger' | 'primary';
  style?: any;
  transition?: boolean;
  alignRight?: boolean;
  alignLeft?: boolean;
  strong?: boolean;
  center?: boolean;
  size?: 'small' | 'default' | 'big' | 'huge';
  className?: string;
}

const FONT_SIZE = {
  small: 10,
  default: 14,
  big: 18,
  huge: 30,
};

const Text: FunctionComponent<IProps> = (props) => {
  const { transition, alignRight, alignLeft, center, strong, style, size, ...otherProps } = props;
  let alignmentClass = '';

  if (center) {
    alignmentClass = 'text-center';
  } else if (alignRight) {
    alignmentClass = 'text-right';
  } else if (alignLeft) {
    alignmentClass = 'text-left';
  }

  let adjustedStyle = style;
  if (size) {
    adjustedStyle = { ...{ fontSize: FONT_SIZE[size] }, ...style };
  }

  if (transition) {
    const { children, ...originalProps } = otherProps;
    return (
      // @ts-ignore
      <Typography.Text {...originalProps} strong={strong} style={adjustedStyle}>
        {/* @ts-ignore */}
        <TextTransition inline text={children} right={alignRight} left={alignLeft} springConfig={presets.wobbly} />
      </Typography.Text>
    );
  }
  // @ts-ignore
  return <Typography.Text {...otherProps} style={adjustedStyle} className={alignmentClass} strong={strong} />;
};

export default Text;
