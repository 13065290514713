import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface IProps {
  open: boolean;
  children: React.ReactNode;
}

const NavMenuBody: FunctionComponent<IProps> = ({ open, children }) => (
  <div style={{ display: open ? '' : 'none' }} className={classNames(styles.NavMenuBody, { [`${styles.open}`]: open })}>
    {children}
  </div>
);

export default NavMenuBody;
