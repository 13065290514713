import React, { FunctionComponent } from 'react';
import styles from './style.module.scss';

interface IProps {
  imageHeader?: boolean;
  children: React.ReactNode;
}

const PageContent: FunctionComponent<IProps> = ({ children, imageHeader }) => (
  <div className={imageHeader ? styles.containerImageHeader : styles.container} data-testid="PageContentContainer">
    {children}
  </div>
);

export default PageContent;
