import api, { apiError } from '../utilities/api';

interface IMarketDestinationResponsePayload {
  data: IMarketDestination;
}

interface IMarketDestination {
  endDatetimeISO8601?: string;
  latitude: number | null;
  longitude: number | null;
  name: string;
  slug: string;
  startDatetimeISO8601?: string;
  subtitle: string;
  title: string | null;
}

export async function getMarketDestination(path: string): Promise<IMarketDestinationResponsePayload> {
  try {
    const { data } = await api.request({ path });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

