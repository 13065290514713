import React from 'react';
import { includes } from 'lodash';
import Reporty from '@/utility/errorHandling/Reporty';

// List of page paths for which we manually call useCustomPageViews for with a custom category and name
const MANUAL_PAGE_PATHS = ['events', 'airports', 'destinations', 'terms', 'safety', 'privacy', 'realtime'];

// Determines whether or not we should prevent usePageViews from recording the current page
// Since we use useCustomPageViews on some pages, we need to skip in usePageViews to prevent duplicate calls to analytics.page
const shouldSkipPage = (path) => {
  if (!path || path === '/') return true;

  const pathParts = path.split('/');
  const firstPathPart = pathParts[1];
  if (includes(MANUAL_PAGE_PATHS, firstPathPart)) return true;

  return false;
};

function usePageViews() {
  React.useEffect(() => {
    try {
      if (!shouldSkipPage(globalThis?.location?.pathname)) {
        if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY) {
          window.analytics.page();
        }
      }
    } catch (e) {
      Reporty.error(e);
    }
  }, [globalThis?.location?.pathname]);
}

export default usePageViews;
