import React, { FunctionComponent } from 'react';
import { Form } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import DesignText from '@/components/typography/DesignText';
import PropTypes from 'prop-types';
import styles from './ValidateStatus.module.scss';

interface IProps {
  error: string;
  touched: boolean;
  children?: React.ReactNode;
}

const HelpComponent = ({ message }) => (
  <div className={styles.HelpComponent}>
    <DesignText color="danger">{message}</DesignText>
  </div>
);

HelpComponent.propTypes = { message: PropTypes.string.isRequired };

const ValidationWrapper: FunctionComponent<IProps> = ({ error, touched, children }) => {
  let errorMessage = null;
  let validateStatus: ValidateStatus = '';
  const showError = error && touched;

  if (showError) {
    errorMessage = <HelpComponent message={error} />;
    validateStatus = 'error';
  }

  return (
    <Form.Item help={errorMessage} validateStatus={validateStatus}>
      {children}
    </Form.Item>
  );
};

export default ValidationWrapper;
