import { cloneDeep, each } from 'lodash';

const renameKeys = (dataHash: { [key: string]: any }, keyMap: { [key: string]: string }): any => {
  const newHash: { [key: string]: any } = {};
  each(cloneDeep(dataHash), (value, key) => {
    key = keyMap[key] || key;
    newHash[key] = value;
  });
  return newHash;
};

export default renameKeys;
