const localConfig = {
  path: '/',
  maxAge: 3153600000,
};

const secureConfig = {
  path: '/',
  maxAge: 3153600000,
  secure: true,
  domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
};

const cookieConfig = process.env.NEXT_PUBLIC_ENV_NAME === 'local' ? localConfig : secureConfig;

export default cookieConfig;
