import { ICurrentUser } from '@jetslash/market-frontend-shared-core/src/types/models/user';
import api, { apiError } from '../utilities/api';

import { ISignUpPhoneNumberConfirmationForm } from '../forms/signUpPhoneNumberConfirmation';
import { ISignUpPhoneNumberEntryForm } from '../forms/signUpPhoneNumberEntry';
import { ISignUpForm } from '../forms/signUp';
import { IResetPasswordEmailEntryForm } from '../forms/resetPasswordEmailEntry';
import { IResetPasswordForm } from '../forms/resetPassword';
import { IAuthResponse, IPayload, IResponse } from './types';

const API_VERSION = 'v1';

export interface ISignInSuccessData {
  jwtToken: string;
  user: ICurrentUser;
  warning?: string;
  message?: string;
}

export interface ISignInSuccessResponse extends IAuthResponse<IPayload<ISignInSuccessData>> {}

/**
 * postSignIn
 *
 * Market::SessionsController#create
 *
 * @param userSignInData: email and password
 *
 * Signs in user using s ign in form data and returns a header with JWT
 */
export async function postSignIn(userSignInData: IUserSignInFormData): Promise<ISignInSuccessResponse> {
  const path = `${API_VERSION}/sessions`;
  const method = 'POST';

  try {
    const { data, headers }: ISignInSuccessResponse = (await api.request({
      path,
      method,
      data: {
        _verify: '1', // Bot prevention flag
        user: userSignInData,
      },
    })) as unknown as ISignInSuccessResponse;
    return { data, headers };
  } catch (err) {
    throw apiError(err);
  }
}

/**
 * postTwoFactorSignIn
 *
 * @param userSignInData
 *
 * Market::SessionsController#two_factor
 *
 * Signs in user using two factor sign in form data and returns a header with JWT
 */

export interface IUserSignInFormData {
  email: string;
  password?: string;
  hashedPassword?: string;
  twoFactorToken?: string;
  twoFactorTempId?: string;
  deviceName?: string;
  deviceYear?: string;
  deviceUuid?: string;
  deviceModel?: string;
  deviceModelDisplayName?: string;
  deviceSystemVersion?: string;
  deviceToken?: string;
  deviceIdiom?: string;
  appVersion?: string;
  buildNumber?: string;
}

export async function postTwoFactorSignIn(userSignInData: IUserSignInFormData) {
  const path = `${API_VERSION}/sessions/two-factor`;
  const method = 'POST';

  try {
    // @ts-ignore
    const { data, headers }: ISignInSuccessResponse = await api.request({
      path,
      method,
      data: {
        user: userSignInData,
      },
    });
    return { data, headers };
  } catch (err) {
    throw apiError(err);
  }
}

/**
 * postRefreshToken
 *
 * Market::SessionsController#create
 *
 * @param userSignInData: data to refresh token using credentials
 */
export async function postRefreshToken(userSignInData: IUserSignInFormData) {
  const path = `${API_VERSION}/sessions`;
  const method = 'POST';

  try {
    // @ts-ignore
    const { data, headers }: ISignInSuccessResponse = await api.request({
      path,
      method,
      data: {
        user: userSignInData,
        isTokenRefresh: true,
      },
    });
    return { data, headers };
  } catch (err) {
    throw apiError(err);
  }
}

/**
 * postRefreshCurrentUser
 *
 * @param userSignInData
 *
 * Market::SessionsController#create
 *
 * Refreshes an existing JWT token along with the updated CurrentUser meta info
 */
export async function postRefreshCurrentUser(
  userSignInData: ICurrentUser,
): Promise<{ data: ISignInSuccessData; headers: any }> {
  const path = `${API_VERSION}/sessions`;
  const method = 'POST';

  try {
    // @ts-ignore
    const { data, headers }: ISignInSuccessResponse = await api.request({
      path,
      method,
      data: {
        user: userSignInData,
        isTokenRefresh: true,
      },
    });
    return { data: data.data, headers };
  } catch (err) {
    throw apiError(err);
  }
}

export interface ISignUpStepSuccessData
  extends IPayload<{
    previouslyConfirmed?: boolean;
    user: ICurrentUser & { warning?: string };
    jwtToken: string;
    userReturnTo?: string;
    message?: string;
  }> {
  userReturnTo?: string;
  previouslyConfirmed?: boolean;
  message?: string;
}

export interface ISignUpStepSuccessResponse extends IResponse<ISignUpStepSuccessData, { authorization?: string }> {}

/**
 * postSignUp
 *
 * @param userSignUpData: all data necessary to create a new user (email, password, name)
 *
 * Users::RegistrationsController#create
 *
 * Creates a user through a shared Portal/Market registrations controller, inheriting from Devise::RegistrationsController
 * Devise has its own way of returning User meta info, and the other non-Devise controllers in the user Sign Up flow attempt
 * to serialize and return User meta data in an identical fashion (see method render_json_user_success_response)
 */
export async function postSignUp(userSignUpData: ISignUpForm): Promise<ISignUpStepSuccessData> {
  const path = `${API_VERSION}/users`;
  const method = 'POST';

  try {
    // @ts-ignore
    const { data }: ISignUpStepSuccessResponse = await api.request({
      path,
      method,
      data: {
        _verify: '1', // Bot prevention flag
        user: userSignUpData,
      },
    });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

/**
 * getSignUpEmailConfirmationToken
 *
 * @param confirmationToken: token generated by Devise that we attach as a param to the get request (should probably be a Post instead, but this is what Devise is doing)
 *
 * Users::ConfirmationsController#show
 *
 * Email confirmation is handled through Devise, so we ping a shared controller that inherits from Devise::ConfirmationsController
 * This action confirms the user by passing up the confirmation token from the email (which is sent here by a magic link on Native)
 *
 * NOTE: We probably only need to use this for Native because web users will just click on a link in a confirmation email
 */
export async function getSignUpEmailConfirmationToken(token: string): Promise<ISignUpStepSuccessData> {
  const path = `${API_VERSION}/users/email-confirmations?token=${token}`;

  try {
    // @ts-ignore
    const { data }: ISignUpStepSuccessResponse = await api.request({ path });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

/**
 * patchSignUpPhoneNumberEntry
 *
 * @param phoneNumberEntryData: form containing the phoneNumber of the new user
 *
 * Market::PhoneNumberController#update
 *
 * Updates the new user with an unconfirmed phone number
 */
export async function patchSignUpPhoneNumberEntry(
  phoneNumberEntryData: ISignUpPhoneNumberEntryForm,
): Promise<IPayload<any>> {
  const path = `${API_VERSION}/profile/phone-number`;
  const method = 'PATCH';

  try {
    // @ts-ignore
    const { data }: ISignUpStepSuccessResponse = await api.request({
      path,
      method,
      data: {
        user: phoneNumberEntryData,
      },
    });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

/**
 * patchSignUpPhoneNumberConfirmation
 *
 * @param phoneNumberConfirmationData: form containing the sms confirmation token
 *
 * Market::PhoneNumberController#confirm
 *
 * Applies the sms confirmation token to the user, finishing off the user creation process
 */
export async function patchSignUpPhoneNumberConfirmation(
  phoneNumberConfirmationData: ISignUpPhoneNumberConfirmationForm,
): Promise<ISignUpStepSuccessData> {
  const path = `${API_VERSION}/profile/phone-number/confirm`;
  const method = 'PATCH';

  try {
    // @ts-ignore
    const { data }: ISignUpStepSuccessResponse = await api.request({
      path,
      method,
      data: {
        user: phoneNumberConfirmationData,
      },
    });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

export async function postResendEmailConfirmationInstructions(currentUserEmail: string) {
  const path = `${API_VERSION}/users/email-confirmations`;
  const method = 'POST';

  try {
    const { data } = await api.request({
      path,
      method,
      data: {
        user: {
          email: currentUserEmail,
        },
      },
    });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

export async function deleteSignOut(params = {}) {
  try {
    await api.request({
      path: `${API_VERSION}/sessions`,
      method: 'DELETE',
      params,
    });
  } catch (err) {
    throw apiError(err);
  }
}

export interface IResetPasswordStepSuccessData extends IPayload<{ user: ICurrentUser & { warning?: string } }> {
  userReturnTo?: string;
  message?: string;
}

export interface IResetPasswordStepSuccessResponse
  extends IResponse<IResetPasswordStepSuccessData, { authorization?: string }> {}

export async function postResetPasswordEmailEntry(
  resetPasswordEmailEntryFormData: IResetPasswordEmailEntryForm,
): Promise<IResetPasswordStepSuccessData> {
  const path = `${API_VERSION}/users/passwords`;
  const method = 'POST';

  try {
    // @ts-ignore
    const resp: IResetPasswordStepSuccessResponse = await api.request({
      path,
      method,
      data: {
        user: resetPasswordEmailEntryFormData,
      },
    });
    const { data } = resp;
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

export async function patchResetPassword(
  resetPasswordFormData: IResetPasswordForm,
): Promise<IResetPasswordStepSuccessData> {
  const path = `${API_VERSION}/users/passwords`;
  const method = 'PATCH';
  try {
    // @ts-ignore
    const resp: IResetPasswordStepSuccessResponse = await api.request({
      path,
      method,
      data: {
        user: resetPasswordFormData,
      },
    });
    const { data } = resp;
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

export async function getUnlockUser(unlockToken: string) {
  const path = `${API_VERSION}/users/unlocks?unlock_token=${unlockToken}`;

  try {
    const { data } = await api.request({ path });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

export async function getCurrentUser(): Promise<IPayload<ICurrentUser>> {
  const path = `${API_VERSION}/current-user`;

  try {
    const { data } = await api.request({ path });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}
