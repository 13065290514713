import { includes } from 'lodash';
import ChatPrefillTypeConstants from './ChatPrefillTypeConstants';
// TODO: Move these to actual enums and types
import ChatPrefillMessages from './ChatPrefillMessages';

class ChatPrefillHelper {
  static path(prefillType: 'asap' | 'international' | 'passenger-count' | 'no-results' | 'too-many-segments' | string | null = null) {
    if (
      includes(
        [
          ChatPrefillTypeConstants.ASAP,
          ChatPrefillTypeConstants.INTERNATIONAL,
          ChatPrefillTypeConstants.PASSENGER_COUNT,
          ChatPrefillTypeConstants.NO_RESULTS,
          ChatPrefillTypeConstants.TOO_MANY_SEGMENTS,
        ],
        prefillType,
      )
    ) {
      return `/concierge?prefill=${prefillType}`;
    }
    return '/concierge';
  }

  static messageForType(prefillType: string) {
    if (
      includes(
        [
          ChatPrefillTypeConstants.ASAP,
          ChatPrefillTypeConstants.INTERNATIONAL,
          ChatPrefillTypeConstants.PASSENGER_COUNT,
          ChatPrefillTypeConstants.NO_RESULTS,
          ChatPrefillTypeConstants.TOO_MANY_SEGMENTS,
        ],
        prefillType,
      )
    ) {
      return ChatPrefillMessages[prefillType];
    }
    return '';
  }

  static typeForSearchError(searchError: string) {
    switch (searchError) {
      case 'SEARCH_TOO_MANY_PAX':
        return ChatPrefillTypeConstants.PASSENGER_COUNT;
      case 'SEARCH_INTERNATIONAL':
        return ChatPrefillTypeConstants.INTERNATIONAL;
      case 'SEARCH_DEPARTS_TOO_SOON':
        return ChatPrefillTypeConstants.ASAP;
      case 'SEARCH_NO_RESULTS':
        return ChatPrefillTypeConstants.NO_RESULTS;
      case 'SEARCH_MULTI_CITY':
        return ChatPrefillTypeConstants.TOO_MANY_SEGMENTS;
      default:
        return ChatPrefillTypeConstants.DEFAULT;
    }
  }
}

export default ChatPrefillHelper;
