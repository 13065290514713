import React, { FunctionComponent } from 'react';
import richTextToHtml from '@/pageGeneration/services/richTextToHtml';
import cx from 'classnames';
import styles from './index.module.scss';

interface IProps {
  items: any[];
  color?: 'default' | 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'white';
  className?: string;
}

const PrismicRichText: FunctionComponent<IProps> = ({ items, color = 'default', className = '' }) => {
  if (typeof items === 'string') {
    return (
      <div className={cx(className, styles.richText, styles[color])} data-testid="PrismicRichTextString">
        {items}
      </div>
    );
  }
  const html = richTextToHtml(items);
  return (
    <div
      className={cx(className, styles.richText, styles[color])}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
      data-testid="PrismicRichText"
    />
  );
};

export default PrismicRichText;
