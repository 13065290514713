import React, { FunctionComponent } from 'react';

import NavBar from '@/components/layout/PageHeader/NavBar';
import NavBtn from '@/components/layout/PageHeader/NavBtn';
import NavLink from '@/components/layout/PageHeader/NavLink';
import NavButtons from '@/components/layout/PageHeader/NavButtons';
import Logo from '@/components/layout/Logo';
import NavMenu from '@/components/layout/PageHeader/NavMenu';
import classNames from 'classnames';
import NavMenuItem from '@/components/layout/PageHeader/NavMenuItem';
import ComponentLink from '@/components/navigation/ComponentLink';

import useIsMarketAppBrowser from '@/hooks/useIsMarketAppBrowser';

import useCurrentUser from '@jetslash/market-frontend-shared-core/src/hooks/useCurrentUser';
import useLogout from '@/utility/auth/useLogout';
import ContentContainer from '@/components/layout/ContentContainer/ContentContainer';
import RouteHelper from '@jetslash/market-frontend-shared-core/src/utilities/navigation/RouteHelper';
import styles from './styles.module.scss';

interface IProps {
  primary?: boolean;
}

const PageHeader: FunctionComponent<IProps> = ({ primary }) => {
  const { currentUser, currentUserIsHydrated } = useCurrentUser();
  const { isMarketAppBrowser } = useIsMarketAppBrowser();
  const isSignedIn = currentUser;
  const conciergeEnabled = currentUser?.conciergeEnabled;
  const { handleLogout } = useLogout();

  if (isMarketAppBrowser) return null;

  const onLogout = () => {
    handleLogout();
  };

  return (
    <div className={classNames({ [styles.HeaderWrapper]: primary })}>
      <div
        className={classNames(styles.Header, {
          [`${styles.primary}`]: primary,
        })}
      >
        <ContentContainer>
          <NavBar>
            <NavMenu>
              {isSignedIn && (
                <>
                  <NavMenuItem to={RouteHelper.tripsPath()} text="My trips" icon="plane" />
                  {conciergeEnabled && <NavMenuItem to="/concierge" text="Concierge" icon="comment" />}
                  <NavMenuItem to="/profile" text="My profile" icon="cog" />
                  <NavMenuItem onClick={onLogout} text="Logout" icon="power-off" />
                </>
              )}
              {!isSignedIn && (
                <>
                  <NavMenuItem to="/safety" text="Safety" />
                  <NavMenuItem to="/realtime" text="Real-time" />
                  <NavMenuItem to="/faq" text="FAQ" />
                  <hr style={{ border: '1px solid white' }} />
                  <NavMenuItem to="/users/sign-up" text="Sign up" icon="user" />
                  <NavMenuItem to="/users/log-in" text="Log in" icon="lock" />
                </>
              )}
            </NavMenu>
            <div className={classNames(styles.LogoLink, styles.hideOnDesktop)}>
              <ComponentLink href="/">
                <Logo color="primary" size="small" />
              </ComponentLink>
            </div>
            <div className={classNames(styles.LogoLink, styles.hideOnMobile)}>
              <ComponentLink href="/">
                <Logo color="white" />
              </ComponentLink>
            </div>
            <NavButtons>
              {currentUserIsHydrated && (
                <>
                  {isSignedIn && (
                    <>
                      <NavLink to={RouteHelper.tripsPath()} text="My trips" />
                      {conciergeEnabled && <NavLink to="/concierge" text="Concierge" />}
                      <NavLink to="/profile" text="My profile" />
                      <NavBtn onClick={onLogout} type="white" text="Logout" />
                    </>
                  )}
                  {!isSignedIn && (
                    <>
                      <NavLink to="/safety" text="Safety" />
                      <NavLink to="/realtime" text="Real-time" />
                      <NavLink to="/faq" text="FAQ" />
                      <NavBtn to="/users/sign-up" type="white" text="Sign up" />
                      <NavBtn to="/users/log-in" type="primary" text="Log in" />
                    </>
                  )}
                </>
              )}
            </NavButtons>
          </NavBar>
        </ContentContainer>
      </div>
    </div>
  );
};

export default PageHeader;
