import React, { FunctionComponent, useEffect } from 'react';
import useUserReturnTo from '@/utility/session/cookies/useUserReturnTo';

interface IProps {
  children: React.ReactNode;
}

const UnprotectedPage: FunctionComponent<IProps> = ({ children }) => {
  const { clearUserReturnTo } = useUserReturnTo();
  useEffect(() => {
    clearUserReturnTo();
  }, []);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default UnprotectedPage;
