import { FlightSearchRequestFormDefinition, IFlightSearchRequestForm } from '../../forms/flightSearchRequest';
import { postFlightSearch } from '../../api/flightSearchApi';
import FlightRequestParamsEncoder from '../../utilities/flightSearch/FlightRequestParamsEncoder';

interface ISearchFlightResultsResponse {
  data: {
    status: number;
    data: {
      requestId: string;
      status: 'pending';
      poll: number;
    };
  };
}

/**
 * searchFlightResults
 *
 * @param formData: form of type FlightSearchRequestForm to be serialized into a param string
 *
 * Service to wrap functionality for kicking off a flight option search
 */
export async function searchFlightResults(formData: IFlightSearchRequestForm): Promise<ISearchFlightResultsResponse> {
  const paramsString = `${FlightSearchRequestFormDefinition.serializers.clientToQuery(
    formData,
  )}&id=${FlightRequestParamsEncoder.encode(formData)}`;

  return postFlightSearch(paramsString);
}
