import { ReactNode, useContext } from 'react';
import { IFlashMessage } from '@jetslash/market-frontend-shared-core/src/types/flashMessage';
import { FlashMessageContext } from '@/contexts/FlashMessageContext';
import useIsMarketAppBrowser from '@/hooks/useIsMarketAppBrowser';
import WebViewMessageDispatcher from '@/services/webview/WebViewMessageDispatcher';

interface IHookReturns {
  flashMessages: IFlashMessage[];
  addFlashMessage: (
    type: 'success' | 'info' | 'warning' | 'error' | 'danger',
    message: string | ReactNode,
    prevFlashMessages?: IFlashMessage[],
  ) => void;
  removeFlashMessage: (uuid: string, prevFlashMessages: IFlashMessage[]) => void;
  removeAllFlashMessages: () => void;
  handleResponseFlashMessage: (response: any) => void;
}

const useFlashMessage = (): IHookReturns => {
  const { flashMessages, addFlashMessage, removeAllFlashMessages, removeFlashMessage } =
    useContext(FlashMessageContext);

  const isMarketAppBrowser = useIsMarketAppBrowser();

  const enhancedAddFlashMessage = (type, message) => {
    // This ensures we display the message as a toast message in the Native webview. We may want to prevent
    // the web side flash message from displaying in this case
    if (isMarketAppBrowser) {
      new WebViewMessageDispatcher().msg().flashMessage(type, message).dispatch();
    }
    addFlashMessage(type, message, flashMessages);
  };

  const handleResponseFlashMessage = (response) => {
    let flashMessagePayload;

    if (response?.flashMessage) flashMessagePayload = response.flashMessage;
    if (response?.data?.flashMessage) {
      flashMessagePayload = response.data.flashMessage;
    } else if (response?.data?.data?.flashMessage) {
      flashMessagePayload = response.data.data.flashMessage;
    }

    if (flashMessagePayload) {
      enhancedAddFlashMessage(flashMessagePayload.type, flashMessagePayload.message);
    }
  };

  return {
    flashMessages,
    addFlashMessage: enhancedAddFlashMessage,
    removeFlashMessage: (uuid) => removeFlashMessage(uuid, flashMessages),
    removeAllFlashMessages,
    handleResponseFlashMessage,
  };
};

export default useFlashMessage;
