import React, { FunctionComponent } from 'react';
import { DefaultSeo } from 'next-seo';

interface IProps {}

const AppSeo: FunctionComponent<IProps> = () => (
  <DefaultSeo
    additionalLinkTags={[
      {
        rel: 'apple-touch-icon',
        href: 'https://cdn.flymoment.com/images/icons/apple-touch-icon.png',
        sizes: '180x180',
      },
      {
        rel: 'icon',
        href: 'https://cdn.flymoment.com/images/icons/favicon-32x32.png',
        sizes: '32x32',
        type: 'image/png',
      },
      {
        rel: 'icon',
        href: 'https://cdn.flymoment.com/images/icons/favicon-16x16.png',
        sizes: '16x16',
        type: 'image/png',
      },
      {
        rel: 'manifest',
        href: 'https://cdn.flymoment.com/images/icons/site.webmanifest',
      },
      {
        rel: 'shortcut icon',
        href: 'https://cdn.flymoment.com/images/icons/favicon.ico',
      },
      {
        rel: 'msapplication-config',
        href: 'https://cdn.flymoment.com/images/icons/browserconfig.xml',
      },
    ]}
    additionalMetaTags={[
      {
        name: 'msapplication-TileColor',
        content: '#1a2e4d',
      },
      {
        name: 'msapplication-config',
        content: 'https://cdn.flymoment.com/images/icons/browserconfig.xml',
      },
      {
        name: 'theme-color',
        content: '#1a2e4d',
      },
    ]}
  />
);

export default AppSeo;
