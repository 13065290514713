/* eslint-disable no-console */

import api from '../utilities/api';
import { IUserSignInFormData } from './userApi';
import {IAPIFlashMessage} from "./types";

interface IDeleteSessionParams {
  deviceUuid?: string;
}

export async function deleteSession(params: IDeleteSessionParams = {}) {
  const path = '/v1/sessions'; // Move this to v1 once we are off the rails world
  const method = 'DELETE';
  await api.request({ path, method, params: {}, data: params });
  return null;
}

interface IGetRefreshTokenResponse {
  status: number;
  data: {
    data: {
      jwtToken: string;
    };
  };
}

interface IGetRefreshTokenResult {
  data: {
    jwtToken: string;
    flashMessage?: IAPIFlashMessage;
  };
}

export async function getRefreshToken(): Promise<IGetRefreshTokenResult> {
  const path = '/v1/sessions/token';
  const method = 'GET';

  try {
    const data: IGetRefreshTokenResponse = await api.request({ path, method });
    return data.data;
  } catch (err) {
    console.error('getRefreshToken errored out');
    console.error(err);
    return null;
  }
}

export async function postRefreshTokenNative(userSignInData: IUserSignInFormData): Promise<IGetRefreshTokenResult> {
  const path = '/v1/sessions/token-native';
  const method = 'POST';

  try {
    const response: IGetRefreshTokenResponse = await api.request({
      path,
      method,
      data: {
        _verify: '1', // Bot prevention flag
        user: userSignInData,
      },
    });
    return response.data;
  } catch (err) {
    console.error('getRefreshToken errored out');
    console.error(err);
    return null;
  }
}
