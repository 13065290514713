import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LoadingAnimation from './LoadingAnimation';
import styles from './FullScreenLoadingOverlay.module.scss';

interface IProps {
  loading: boolean;
  transparent?: boolean;
  noFooter?: boolean;
  noHeader?: boolean;
}

const FullScreenLoadingOverlay: FunctionComponent<IProps> = ({
  loading,
  transparent = false,
  noFooter = false,
  noHeader = false,
}) => {
  if (loading) {
    return (
      <div
        data-testid="FullScreenLoadingOverlay"
        className={classNames(styles.background, {
          [styles.transparent]: transparent,
          [styles.noHeader]: noHeader,
          [styles.noFooter]: noFooter,
        })}
      >
        <div className={styles.animationContainer}>
          <LoadingAnimation />
        </div>
      </div>
    );
  }

  return null;
};

FullScreenLoadingOverlay.propTypes = {
  loading: PropTypes.bool,
};

FullScreenLoadingOverlay.defaultProps = {
  loading: false,
};

export default FullScreenLoadingOverlay;
