import Reporty from '@/utility/errorHandling/Reporty';

class PersistenceProvider {
  static async set(key, value): Promise<any> {
    try {
      return globalThis.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      Reporty.error(e);
      return null;
    }
  }

  static async get(key): Promise<any> {
    try {
      const result = globalThis?.localStorage.getItem(key);
      return JSON.parse(result);
    } catch (e) {
      Reporty.error(e);
      return null;
    }
  }

  static async delete(key): Promise<any> {
    try {
      globalThis?.localStorage.removeItem(key);
      return null;
    } catch (e) {
      return null;
    }
  }
}

export default PersistenceProvider;
