import React, { FunctionComponent } from 'react';
import Icon from '@/components/Icon';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface IProps {
  onClick: any;
  open: boolean;
}

const NavMenuBtn: FunctionComponent<IProps> = ({ onClick, open }) => (
  <div onClick={onClick} className={classNames(styles.NavMenuBtn, { [`${styles.open}`]: open })}>
    <Icon name="bars" />
  </div>
);

export default NavMenuBtn;
