import React, { FunctionComponent } from 'react';
import Head from 'next/head';

interface IProps {
  isMarketAppBrowser: boolean;
}

const StandardMetaTags: FunctionComponent<IProps> = ({ isMarketAppBrowser }) => {
  if (isMarketAppBrowser) {
    return (
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
      </Head>
    );
  }
  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    </Head>
  );
};

export default StandardMetaTags;
