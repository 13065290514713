import api, { apiError } from '../utilities/api';
import { IPayload, IResponse } from './types';
import { IApi } from '../types/api/serialized';

export interface IGetPaymentSourceResponse {
  data: { data: IGetPaymentSourceData };
}

export interface IGetPaymentSourceData {
  paymentSources: IApi.Serialized.PersistedPaymentSource[];
  paymentTypes: IApi.Serialized.PaymentType[];
}
export async function getPaymentSources(): Promise<{
  data: IGetPaymentSourceData;
}> {
  const path = '/v1/payment-sources';
  const method = 'GET';

  try {
    const { data }: IGetPaymentSourceResponse = await api.request({
      path,
      method,
      protectRoute: true,
    });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

interface IPaymentSourceV2Payload {
  payment_source: {
    token: string;
    type: string;
  };
}

export async function createPaymentSource(
  postBody: IPaymentSourceV2Payload,
): Promise<{ data: IApi.Serialized.PersistedPaymentSource }> {
  const path = '/v1/payment-sources';
  const method = 'POST';

  try {
    const { data }: { data: { data: IApi.Serialized.PersistedPaymentSource } } = await api.request({
      path,
      method,
      data: postBody,
      protectRoute: true,
    });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

export async function createLegacyPaymentSource(postBody): Promise<any> {
  const path = '/v1/payment-sources/create-legacy';
  const method = 'POST';

  try {
    const { data }: IGetPaymentSourceResponse = await api.request({
      path,
      method,
      data: postBody,
      protectRoute: true,
    });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

export async function getSetupIntentToken(): Promise<{ setupIntentSecret: string }> {
  const path = `/v1/payment-sources/setup-intent`;
  const method = 'GET';
  try {
    const { data }: any = await api.request({
      path,
      method,
      data: {},
      protectRoute: true,
    });

    return data.data;
  } catch (err) {
    throw apiError(err);
  }
}

interface IDeletePaymentSourceResponse extends IResponse<IPayload<any>> {}

export async function deletePaymentSource(paymentSourceUuid: string) {
  const path = `/v1/payment-sources/${paymentSourceUuid}`;
  const method = 'DELETE';

  try {
    const { data }: IDeletePaymentSourceResponse = await api.request({
      path,
      method,
      protectRoute: true,
    });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

/**
 * postGetLinkToken
 */
export interface IGetLinkTokenResponse {
  data: {
    data: IGetLinkTokenData;
  };
}

export interface IGetLinkTokenData {
  linkToken: string;
}

export async function postGetLinkToken(): Promise<IGetLinkTokenData> {
  const path = '/v1/payment-sources/plaid/link-token';
  const method = 'POST';

  try {
    const { data }: IGetLinkTokenResponse = await api.request({
      path,
      method,
      protectRoute: true,
    });
    return data.data;
  } catch (err) {
    throw apiError(err);
  }
}
