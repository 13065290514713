import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import ComponentLink from '@/components/navigation/ComponentLink';
import style from './styles.module.scss';

interface IProps {
  type?: 'primary' | 'white';
  to?: string;
  text: string;
  onClick?: () => void;
}

const NavBtn: FunctionComponent<IProps> = ({ to, type = 'primary', text, onClick }) => {
  if (to) {
    return (
      <ComponentLink href={to} callback={onClick}>
        <div className={classNames([style.NavBtn, style[type]])}>{text}</div>
      </ComponentLink>
    );
  }
  if (onClick) {
    return (
      <div onClick={onClick} className={classNames([style.NavBtn, style[type]])}>
        {text}
      </div>
    );
  }
  return null;
};

export default NavBtn;
