import React, { FunctionComponent } from 'react';
import Button from '@/components/design/Button';
import cx from 'classnames';
import styles from './ListItem.module.scss';

interface IProps {
  onClick?: () => void;
  children: React.ReactNode;
}

const ListItem: FunctionComponent<IProps> = ({ onClick, children }) => (
  <Button onClick={onClick} block className={styles.button}>
    <i className={cx(['fal fa-chevron-right', styles.icon])} />
    {children}
  </Button>
);

export default ListItem;
