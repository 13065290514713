import api, { apiError } from '../utilities/api';
import { IPayload, IResponse } from './types';
import { ICustomerDelegateRecipient } from '../models/customerDelegateRecipientModel';

const DELEGATE_RECIPIENT_PATH = '/v1/profile/delegates/recipients';

export interface IGetDelegateRecipientsResponse extends IResponse<IPayload<ICustomerDelegateRecipient[]>> {}

/**
 * getDelegateRecipients
 *
 * DelegateRecipientsController
 */
export async function getDelegateRecipients(): Promise<ICustomerDelegateRecipient[]> {
  const path = DELEGATE_RECIPIENT_PATH;

  try {
    const { data }: IGetDelegateRecipientsResponse = await api.request({
      path,
      protectRoute: true,
    });
    return data.data;
  } catch (err) {
    throw apiError(err);
  }
}

export interface IGetCustomerDelegateResponse extends IResponse<IPayload<ICustomerDelegateRecipient>> {}

/**
 * getCustomerDelegate
 * @param token
 *
 * CustomerDelegatesController#SHOW
 */
export async function getDelegateRecipient(token: string): Promise<ICustomerDelegateRecipient> {
  const path = `${DELEGATE_RECIPIENT_PATH}/${token}`;

  try {
    const { data }: IGetCustomerDelegateResponse = await api.request({
      path,
      protectRoute: true,
    });
    return data.data;
  } catch (err) {
    throw apiError(err);
  }
}

/**
 * patchCustomerDelegate
 * @param token
 *
 * CustomerDelegatesController#UPDATE
 *
 * 'Accepts' an invite for the given customer delegate
 */
export async function patchDelegateRecipient(token: string): Promise<ICustomerDelegateRecipient> {
  const path = `${DELEGATE_RECIPIENT_PATH}/${token}`;
  const method = 'PATCH';
  try {
    const { data }: IGetCustomerDelegateResponse = await api.request({
      path,
      method,
      data: {
        accept: true,
      },
      protectRoute: true,
    });
    return data.data;
  } catch (err) {
    throw apiError(err);
  }
}

export interface IPostDelegateRecipientResponse extends IResponse<IPayload<any>> {}

/* eslint-disable @typescript-eslint/no-unused-vars, no-restricted-globals */
export async function postDelegateRecipient({ email }: { email: string }): Promise<any> {
  const path = DELEGATE_RECIPIENT_PATH;
  const method = 'POST';
  try {
    const { data }: IPostDelegateRecipientResponse = await api.request({
      path,
      method,
      data: {
        name,
        email,
      },
      protectRoute: true,
    });

    return data;
  } catch (e) {
    throw apiError(e);
  }
}

export async function deleteDelegateRecipient(customerUuid) {
  const path = `${DELEGATE_RECIPIENT_PATH}/${customerUuid}`;
  const method = 'DELETE';
  try {
    const { data } = await api.request({
      path,
      method,
      protectRoute: true,
    });

    return data;
  } catch (e) {
    throw apiError(e);
  }
}
