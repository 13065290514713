import { Method } from 'axios';
import api, { apiError } from '../utilities/api';
import { IPayload, IResponse } from './types';
import { IApi } from '../types/api/serialized';
import { PaymentTypes } from '../services/checkout/paymentMethodSelection';

/**
 * getCart
 *
 * @param tripPnr
 */
export async function getCart(tripPnr = null, forCheckoutStart = false): Promise<IApi.Serialized.Cart> {
  let path = `v1/carts/${tripPnr}`;
  if (forCheckoutStart) {
    path += '?checkout_start=true';
  }

  try {
    const { data } = await api.request({ path, protectRoute: true });

    return data.data;
  } catch (err) {
    throw apiError(err);
  }
}

interface IGetCartsResponse {
  data: IApi.Serialized.CartTrip[];
  paginationMeta: {
    count: number;
    page: number;
    perPage: number;
  };
}

export async function getCarts(page: number = 1, perPage: number = 5): Promise<IGetCartsResponse> {
  const path = `v1/carts?page=${page}&per_page=${perPage}`;

  try {
    const { data } = await api.request({ path, protectRoute: true });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

// Get trip as Trip Cart. Used in the checkout complete page to display promotion and trip information.
export async function getTripCart(tripPnr: string): Promise<IApi.Serialized.Cart> {
  const path = `v1/carts/${tripPnr}/trip_cart`;

  try {
    const { data } = await api.request({ path, protectRoute: true });

    return data.data;
  } catch (err) {
    throw apiError(err);
  }
}

export async function patchCartContactDetails(contactDetail: IApi.Params.UserProfile, tripPnr?: string) {
  let path = 'v1/carts';
  const method: Method = 'PATCH';
  if (tripPnr) {
    path = `${path}/${tripPnr}`;
  }

  try {
    const { data } = await api.request({
      path,
      method,
      data: { contactInfo: contactDetail },
      protectRoute: true,
    });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

export interface IPatchCartPaymentPayload {
  type: string;
  paymentSourceUuid: string;
  backupPaymentSourceUuid: string;
}

export async function patchCartPayment(paymentBundle: IPatchCartPaymentPayload, tripPnr: string) {
  const method: Method = 'PATCH';
  const path = `v1/carts/${tripPnr}`;

  try {
    const { data } = await api.request({
      path,
      method,
      data: { paymentMethod: paymentBundle },
      protectRoute: true,
    });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

/* eslint-disable no-nested-ternary */
export function packagePatchCartPayment(paymentSourceBundle): {
  type: string;
  paymentSourceUuid: string;
  backupPaymentSourceUuid: string;
} {
  const paymentSourceUuid =
    paymentSourceBundle.type === PaymentTypes.Wire ? null : paymentSourceBundle.paymentSource.uuid;
  // if we have set the holdable payment source - prefer that - else if its a wire use the paymentSource - else be null
  const backupPaymentSourceUuid =
    // eslint-disable no-nested-ternary
    paymentSourceBundle && paymentSourceBundle.holdablePaymentSource
      ? paymentSourceBundle.holdablePaymentSource.uuid
      : paymentSourceBundle.type === PaymentTypes.Wire
      ? paymentSourceBundle.paymentSource.uuid
      : null;

  return {
    type: paymentSourceBundle.type,
    paymentSourceUuid,
    backupPaymentSourceUuid,
  };
}

export async function postCartPriceAvailabilityCheck(
  tripPnr: string,
): Promise<IApi.Serialized.CartPriceAvailabilityCheckResult> {
  const path = `v1/carts/${tripPnr}/reprice`;
  const method = 'POST';

  try {
    const resp: IResponse<IPayload<IApi.Serialized.CartPriceAvailabilityCheckResult>> = await api.request({
      path,
      method,
      protectRoute: true,
    });
    const { data } = resp;
    return data.data;
  } catch (err) {
    throw apiError(err);
  }
}

export async function deleteCart(pnr: string): Promise<IResponse<IPayload<any>>> {
  const path = `v1/carts/${pnr}`;
  const method = 'DELETE';

  try {
    const { data } = await api.request({
      path,
      method,
      protectRoute: true,
    });

    return data;
  } catch (e) {
    throw apiError(e);
  }
}

/**
 * postCartRecord
 * @param pnr
 *
 * Exists solely to record the 'Wishlist Product Added to Cart' analytics in the backend to ensure it is recorded
 * If we put it in the front-end there is a chance it gets blocked by ad-blocking plugins
 */
export async function postCartRecord(pnr: string): Promise<IResponse<IPayload<any>>> {
  const path = `v1/carts/${pnr}/record`;
  const method = 'POST';

  try {
    const resp: IResponse<IPayload<any>> = await api.request({
      path,
      method,
      protectRoute: true,
    });
    const { data } = resp;
    return data.data;
  } catch (err) {
    throw apiError(err);
  }
}
