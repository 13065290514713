import React, { FunctionComponent } from 'react';
import * as PropTypes from 'prop-types';
import { get } from 'lodash';
import moment, { Moment } from 'moment';
import { DatePicker as AntDatePicker } from 'antd';
import { FieldHookConfig, useField, useFormikContext } from 'formik';
import ValidationWrapper from './helpers/ValidationWrapper';

interface IProps {
  name: string;
  adjustTheseFieldsToBeLaterThanThis?: string[];
  adjustTheseFieldsToBeEarlierThanThis?: string[];
  className?: string;
  loading?: boolean;
  autoComplete?: string;
  style?: any;
  defaultValue?: Moment;
  dateFormat?: string;
  placeholder?: string;
}

const DatePickerField: FunctionComponent<IProps> = ({ ...props }: IProps & FieldHookConfig<Moment>) => {
  const {
    adjustTheseFieldsToBeLaterThanThis,
    adjustTheseFieldsToBeEarlierThanThis,
    style,
    defaultValue,
    dateFormat = 'MM/DD/YY',
    placeholder,
  } = props;

  const [field, meta] = useField(props);
  const { name, value, onBlur } = field;
  const { error, touched } = meta;
  const { setFieldValue, values } = useFormikContext();

  const handleChange = async (newDate) => {
    await setFieldValue(name, newDate);
    // For each of the field names in the adjustTheseFieldsToBeLaterThanThis list, will overwrite the
    // that field's value if it is before the currently selected date
    // Example usage: for multi-segment search, if the departure date of a segment following the current segment
    // is after the departure date of the currently selected segment, change the other segment's depart date to the
    // currently selected depart date

    if (adjustTheseFieldsToBeLaterThanThis) {
      adjustTheseFieldsToBeLaterThanThis.forEach((fieldName) => {
        if (fieldName !== name) {
          const valueToOverwrite = get(values, fieldName);

          if (valueToOverwrite && newDate && valueToOverwrite < newDate) {
            setFieldValue(fieldName, moment(newDate).add(1, 'd'));
          }
        }
      });
    }

    if (adjustTheseFieldsToBeEarlierThanThis) {
      adjustTheseFieldsToBeEarlierThanThis.forEach((fieldName) => {
        if (fieldName !== name) {
          const valueToOverwrite = get(values, fieldName);
          if (valueToOverwrite && newDate && valueToOverwrite > newDate) {
            setFieldValue(fieldName, moment(newDate).subtract(1, 'd'));
          }
        }
      });
    }
  };

  return (
    <div
      style={{
        paddingLeft: 5,
        paddingRight: 5,
      }}
    >
      <ValidationWrapper error={error} touched={touched}>
        <AntDatePicker
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={handleChange}
          onBlur={onBlur(name)}
          value={value}
          format={dateFormat}
          style={style}
          data-testid={`${name}-DatePickerField`}
        />
      </ValidationWrapper>
    </div>
  );
};

DatePickerField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default DatePickerField;
