import { FunctionComponent } from 'react';

import {
  fieldsAfterIndex,
  fieldsBeforeIndex,
} from '@jetslash/market-frontend-shared-core/src/utilities/form/dateFieldHelpers';
import { useFormikContext } from 'formik';
import { IFlightSearchRequestForm } from '@jetslash/market-frontend-shared-core/src/forms/flightSearchRequest';
import { Row, Col } from '../../layout';

import LocationAutocompleteField from '../fields/LocationAutocompleteField';
import DatePickerField from '../fields/DatePickerField';
import TimePickerField from '../fields/TimePickerField';

interface IProps {}

const OneWayFlightSearch: FunctionComponent<IProps> = () => {
  const { values } = useFormikContext<IFlightSearchRequestForm>();
  return (
    <Row>
      <Col xs={24} sm={24} md={8} lg={8} xl={8} className="thin-margin-input-sm">
        <LocationAutocompleteField
          name="flightSegments.0.origin"
          placeholder="Origin address"
          shouldOverwriteThisFieldName="flightSegments.1.destination"
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <LocationAutocompleteField
          name="flightSegments.0.destination"
          placeholder="Destination address"
          shouldOverwriteThisFieldName="flightSegments.1.origin"
        />
      </Col>
      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
        <DatePickerField
          name="flightSegments.0.departDate"
          style={{ width: '100%' }}
          adjustTheseFieldsToBeLaterThanThis={fieldsAfterIndex(
            0,
            values.flightSegments.length,
            'flightSegments.!!INDEX!!.departDate',
          )}
          adjustTheseFieldsToBeEarlierThanThis={fieldsBeforeIndex(0, 'flightSegments.!!INDEX!!.departDate')}
        />
      </Col>
      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
        <TimePickerField name="flightSegments.0.departTime" />
      </Col>
    </Row>
  );
};

export default OneWayFlightSearch;
