import React, { FunctionComponent } from 'react';
import { Divider as AntdDivider } from 'antd';
import { DividerProps } from 'antd/lib/divider';
import classNames from 'classnames';
import styles from './Divider.module.scss';

interface IProps extends DividerProps {
  mobileOnly?: boolean;
  noMargin?: boolean;
}

const Divider: FunctionComponent<IProps> = (props) => {
  const { mobileOnly, noMargin, ...remainingProps } = props;
  return (
    <AntdDivider
      className={classNames({
        [styles.mobileOnly]: mobileOnly,
        [styles.noMargin]: noMargin,
      })}
      {...remainingProps}
    />
  );
};

export default Divider;
