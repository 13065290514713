import React, { FunctionComponent } from 'react';
import FlightSearchFormFlat from '@/components/layout/landingPages/FlightSearchFormFlat';
import Row from '@/components/layout/Row';
import { Col } from '@/components/layout';
import Icon from '@/components/Icon';
import Header from '@/components/typography/Header';
import UserTestimonialSection from '@/components/landingPages/UserTestimonialSection';
import LandingPageContentSection from '@/components/landingPages/LandingPageContentSection';
import { IUserTestimonialSection } from '@/pageGeneration/services/prismicApi/userTestimonials';
import {
  IPrismicImageElement,
  IPrismicContentElement,
  IPrismicRichTextElement,
} from '@/pageGeneration/services/prismicApi/types';
import PrismicRichText from '@/components/design/PrismicRichText';
import linkTargetPath from '@/pageGeneration/services/linkTargetPath';
import ComponentLink from '@/components/navigation/ComponentLink';
import colors from '@jetslash/market-frontend-shared-core/src/styles/constants/colors';
import styles from './HomePageComponent.module.scss';

interface IProps {
  leftCard: IPrismicContentElement;
  rightCard: IPrismicContentElement;
  userTestimonialSection: IUserTestimonialSection;
  bottomImage: IPrismicImageElement;
  bottomTitle: string;
  bottomText: IPrismicRichTextElement[];
}

const HomePageComponent: FunctionComponent<IProps> = ({
  leftCard,
  rightCard,
  userTestimonialSection,
  bottomImage,
  bottomTitle,
  bottomText,
}) => (
  <div className={styles.HomePageComponent}>
    <div className={styles.searchContainer}>
      <FlightSearchFormFlat />
    </div>
    <LandingPageContentSection>
      <Row gutter={[30, 0]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <div
            className={styles.imageCard}
            style={{
              background: `linear-gradient(${colors.primaryTransparent}, ${colors.primaryTransparent}), url(${leftCard.image.url}) no-repeat center center`,
            }}
          >
            <ComponentLink href={linkTargetPath(leftCard.linkTarget)}>
              <div className={styles.imageCardBody}>
                <Row>
                  <Col xs={0} sm={6} md={6} lg={6} xl={6} className={styles.imageCardIconContainer}>
                    <Icon name={leftCard.icon} color="white" className={styles.imageCardIcon} useHardCodedIconName />
                  </Col>
                  <Col xs={24} sm={18} md={18} lg={18} xl={18}>
                    <Header level={3} color="white" marginBottom={24}>
                      {leftCard.title}
                    </Header>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Header level={5} thin color="white">
                      {leftCard.text[0].text}
                    </Header>
                  </Col>
                </Row>
              </div>
            </ComponentLink>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <ComponentLink href={linkTargetPath(rightCard.linkTarget)}>
            <div
              className={styles.imageCard}
              style={{
                background: `linear-gradient(${colors.primaryTransparent}, ${colors.primaryTransparent}), url(${rightCard.image.url}) no-repeat center center`,
              }}
            >
              <div className={styles.imageCardBody}>
                <Row>
                  <Col xs={0} sm={6} md={6} lg={6} xl={6} className={styles.imageCardIconContainer}>
                    <Icon name={rightCard.icon} color="white" className={styles.imageCardIcon} useHardCodedIconName />
                  </Col>
                  <Col xs={24} sm={18} md={18} lg={18} xl={18}>
                    <Header level={3} color="white" marginBottom={24}>
                      {rightCard.title}
                    </Header>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Header level={5} thin color="white">
                      {rightCard.text[0].text}
                    </Header>
                  </Col>
                </Row>
              </div>
            </div>
          </ComponentLink>
        </Col>
      </Row>
    </LandingPageContentSection>
    <LandingPageContentSection>
      <Row>
        <Col span={24}>
          <UserTestimonialSection content={userTestimonialSection} />
        </Col>
      </Row>
    </LandingPageContentSection>
    <LandingPageContentSection>
      <Row>
        <Col xs={0} sm={24} md={24} lg={24} xl={24}>
          <div className={styles.graphicContainer}>
            <div className={styles.graphicTopLine} />
            <div className={styles.graphicTextBox}>
              <Header level={3} color="white">
                {bottomTitle}
              </Header>
            </div>
            <div
              className={styles.graphicImageBox}
              style={{
                background: `linear-gradient(${colors.primaryTransparent}, ${colors.primaryTransparent}), url(${bottomImage.url})`,
              }}
            >
              <div className={styles.scheduleContainer}>
                <Row gutter={[30, 0]}>
                  <Col span={8} />
                  <Col span={16}>
                    <PrismicRichText items={bottomText} color="white" />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </LandingPageContentSection>
  </div>
);

export default HomePageComponent;
