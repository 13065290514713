import { publicEnv } from '@/appConfig';

const ENV: any = publicEnv;

const generateUrl = (path) => `${ENV.DOMAIN}${path}`;

const goToUrl = (url, event = null) => {
  if (event && (event.ctrlKey || event.metaKey)) {
    const win = window.open(url, '_blank');
    if (event.shiftKey) {
      win.focus();
    }
  } else {
    window.location.href = url;
  }
};

const goToPath = (path, event = null) => {
  const url = generateUrl(path);
  goToUrl(url, event);
};

export { goToUrl, goToPath, generateUrl };
