/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FunctionComponent, useState, useCallback, ReactNode } from 'react';
import { IFlashMessage } from '@jetslash/market-frontend-shared-core/src/types/flashMessage';
import { generateFlashMessage } from './generateFlashMessage';

interface IProps {
  children: React.ReactNode;
}

interface IDefaultState {
  flashMessages: IFlashMessage[];
  addFlashMessage: (
    type: 'success' | 'info' | 'warning' | 'error' | 'danger',
    message: string | ReactNode,
    prevFlashMessages?: IFlashMessage[],
  ) => void;
  removeFlashMessage: (uuid: string, prevFlashMessages: IFlashMessage[]) => void;
  removeAllFlashMessages: () => void;
}

const DefaultState: IDefaultState = {
  flashMessages: [],
  addFlashMessage: (
    type: 'success' | 'info' | 'warning' | 'error' | 'danger',
    message: string | ReactNode,
    prevFlashMessages: IFlashMessage[],
  ) => {},
  removeFlashMessage: (uuid: string, prevFlashMessages: IFlashMessage[]) => {},
  removeAllFlashMessages: () => {},
};

export const FlashMessageContext = React.createContext(DefaultState);

export const FlashMessageContextProvider: FunctionComponent<IProps> = ({ children }) => {
  const [flashMessages, setFlashMessages] = useState([]);

  const addFlashMessage = (
    type: 'success' | 'info' | 'warning' | 'error' | 'danger',
    message: string | ReactNode,
    prevFlashMessages?: IFlashMessage[],
  ) => {
    // TODO: re-enable ability to have multiple flash messages once we either upgrade Ant to v4 or make the FlashMessage component ourself
    // setFlashMessages(prevFlashMessages.concat(generateFlashMessage(type, message)));
    setFlashMessages([generateFlashMessage(type, message)]);
  };

  const removeAllFlashMessages = () => {
    setFlashMessages([]);
  };

  const removeFlashMessage = (uuid: string, prevFlashMessages: IFlashMessage[]) => {
    setFlashMessages(prevFlashMessages.filter((msg) => msg.uuid !== uuid));
  };

  const contextValue = {
    flashMessages,
    addFlashMessage: useCallback(
      (type, message, prevFlashMessages) => addFlashMessage(type, message, prevFlashMessages),
      [],
    ),
    removeAllFlashMessages: useCallback(() => removeAllFlashMessages(), []),
    removeFlashMessage: useCallback((uuid, prevFlashMessages) => removeFlashMessage(uuid, prevFlashMessages), []),
    setFlashMessages,
  };

  return <FlashMessageContext.Provider value={contextValue}>{children}</FlashMessageContext.Provider>;
};
