import React, { FunctionComponent } from 'react';
import FlightSearchRequestForm from '@/components/forms/FlightSearchRequestForm';
import { useRouter } from 'next/router';
import { IFlightSearchRequestForm } from '@jetslash/market-frontend-shared-core/src/forms/flightSearchRequest';
import FlightSearchRouteHelper from '@jetslash/market-frontend-shared-core/src/utilities/flightSearch/FlightSearchRouteHelper';
import styles from './FlightSearchFormFlat.module.scss';

interface IProps {}

const FlightSearchFormFlat: FunctionComponent<IProps> = () => {
  const router = useRouter();
  const flightSearchRequestCallback = (flightSearchRequestFormValues: IFlightSearchRequestForm, requestId: string) => {
    // The requestId is an encoded version of the form
    router.push(FlightSearchRouteHelper.generateResultsPath(requestId));
  };

  return (
    <div className={styles.container}>
      <FlightSearchRequestForm flightSearchRequestCallback={flightSearchRequestCallback} />
    </div>
  );
};

export default FlightSearchFormFlat;
