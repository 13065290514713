import React, { FunctionComponent } from 'react';
import LogoBackground from '@/components/layout/landingPages/LogoBackground';
import PageHeader from '@/components/layout/PageHeader';
import PageFooter from '@/components/layout/PageFooter';
import PageContent from '@/components/layout/PageContent';
import colors from '@jetslash/market-frontend-shared-core/src/styles/constants/colors';
import ContentContainer from '@/components/layout/ContentContainer/ContentContainer';
import styles from './ImageHeaderLayout.module.scss';

interface IProps {
  imageUrl?: string;
  headerContent: React.ReactNode;
  children: React.ReactNode;
}

const ImageHeaderLayout: FunctionComponent<IProps> = ({ imageUrl, headerContent, children }) => {
  let backgroundString;
  if (imageUrl) {
    backgroundString = `-webkit-linear-gradient(left, ${colors.secondaryTransparent} 0%, ${colors.primaryTransparent} 100%), url(${imageUrl}) no-repeat top center`;
  } else {
    backgroundString = `-webkit-linear-gradient(left, ${colors.secondaryTransparent} 0%, ${colors.primaryTransparent} 100%)`;
  }
  return (
    <>
      <PageHeader />
      <PageContent imageHeader>
        <div
          data-testid="ImageHeaderLayoutContainer"
          className={styles.container}
          style={{
            background: backgroundString,
            backgroundSize: 'cover',
          }}
        >
          <ContentContainer>
            <div className={styles.headerContainer}>{headerContent}</div>
          </ContentContainer>
        </div>
        <ContentContainer>
          <LogoBackground>{children}</LogoBackground>
        </ContentContainer>
      </PageContent>
      <PageFooter />
    </>
  );
};

export default ImageHeaderLayout;
