import { map, range, clone } from 'lodash';

export function fieldsAfterIndex(start: number, end: number, fieldNameTemplate: string): string[] {
  // @ts-ignore
  return map(range(start, end), (segmentIndex: string) => {
    const template = clone(fieldNameTemplate);
    return template.replace('!!INDEX!!', segmentIndex);
  });
}

export function fieldsBeforeIndex(end: number, fieldNameTemplate: string): string[] {
  // @ts-ignore
  return map(range(end), (segmentIndex: string) => {
    const template = clone(fieldNameTemplate);
    return template.replace('!!INDEX!!', segmentIndex);
  });
}
