import humps from 'humps';

const camelizeResponsePayload = (data) => humps.camelizeKeys(data);

const decamelizeRequestPayload = (data) => humps.decamelizeKeys(data, {
    split: /(?=[A-Z])/,
    process: function processKeys(key, convert, options) {
      if (key.endsWith('ISO8601')) {
        const [prefix] = key.split('ISO8601');
        return `${convert(prefix, options)}_ISO8601`;
      }
      return convert(key, options);
    },
  });

export { camelizeResponsePayload, decamelizeRequestPayload };
