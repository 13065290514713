import React, { FunctionComponent } from 'react';
import useIsMarketAppBrowser from '@/hooks/useIsMarketAppBrowser';
import StandardMetaTags from '@/components/layout/pageSetup/StandardMetaTags';
import useLoadingContext
  from "@jetslash/market-frontend-shared-core/src/contexts/LoadingContextProvider/useLoadingContext";
import FullScreenLoadingOverlay from '../loading/FullScreenLoadingOverlay';
import Content from './Content';

interface IProps {
  contentClassName?: string;
  contentStyle?: any;
  noPadding?: boolean;
  noFooter?: boolean; // We need to tell Page that this is getting called by a layout without a footer so it can render loading indicator properly
  noHeader?: boolean;
  opaqueLoadingOnNative?: boolean;
  children: React.ReactNode;
}

const Page: FunctionComponent<IProps> = ({
  contentClassName,
  contentStyle,
  noPadding = false,
  noFooter = false,
  noHeader = false,
  opaqueLoadingOnNative = false,
  children,
}) => {
  const { fullScreenLoading } = useLoadingContext();
  const { isMarketAppBrowser } = useIsMarketAppBrowser();

  return (
    <>
      <StandardMetaTags isMarketAppBrowser={isMarketAppBrowser} />
      <Content
        style={
          contentStyle || (noPadding ? { paddingTop: 0, paddingBottom: 0 } : { paddingTop: 30, paddingBottom: 30 })
        }
        className={contentClassName}
      >
        {children}
      </Content>
      <FullScreenLoadingOverlay
        loading={fullScreenLoading}
        transparent={!opaqueLoadingOnNative && isMarketAppBrowser}
        noFooter={noFooter}
        noHeader={noHeader}
      />
    </>
  );
};

export default Page;
