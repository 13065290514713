import api from '../api';
// import { IAutocompleteOption } from "../../../native/components/locationAutocomplete/types";

export default async function queryLocationAutocompleteOptions(
  query: string,
  autocompleteSessionToken: string,
  currentUserPositionLat: number | null = null,
  currentUserPositionLng: number | null = null,
) {
  if (!query || query.length < 3) return [];

  try {
    // Generate params biasing the location autocomplete search to the userrs current location
    let userLocationParams = '';
    if (currentUserPositionLat && currentUserPositionLng) {
      const biasRadius = 150000; // meters
      userLocationParams = `&bias_lat=${currentUserPositionLat}&bias_lng=${currentUserPositionLng}&bias_radius=${biasRadius}`;
    }

    const response: { data: { data: any[] } } = await api.request({
      path: `/v1/location-autocomplete/search?search_term=${query}&autocomplete_session_token=${autocompleteSessionToken}${userLocationParams}`,
    });

    const { data } = response;
    if (data && data.data && data.data[0]) {
      const autocompleteOptions = data.data.map((option) => option);
      return autocompleteOptions;
    }
    return [
      {
        label: 'No results found',
        value: '',
        source: 'error',
      },
    ];
  } catch (e) {
    return [
      {
        label: 'No results found',
        value: '',
        source: 'error',
      },
    ];
  }
}
