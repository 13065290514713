import React, { useState } from 'react';
import Modal from '../components/layout/Modal';

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  const show = () => setIsVisible(true);
  const hide = () => setIsVisible(false);

  const RenderModal = ({
    children,
    title,
    size,
    onCancel,
  }: {
    children: React.ReactChild;
    title: string;
    size?: string;
    onCancel?: () => void;
  }) => {
    const widthToUse = 600;

    return (
      <Modal
        title={title}
        visible={isVisible}
        width={size || widthToUse}
        wrapClassName={`${size}-modal-wrapper`}
        onCancel={() => {
          hide();
          if (onCancel) {
            onCancel();
          }
        }}
        footer={null}
      >
        {children}
      </Modal>
    );
  };

  return {
    show,
    hide,
    RenderModal,
  };
};
