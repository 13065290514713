interface ITypeParsableForm {
  flightSegments: { originId: string; destinationId: string }[];
}
// eslint-disable-next-line func-names
export default function parseRequestTypeFromFromForm(form: ITypeParsableForm) {
  if (form.flightSegments.length === 1) {
    return 'one_way';
  }

  if (form.flightSegments.length === 2) {
    if (
      form.flightSegments[0].originId === form.flightSegments[1].destinationId &&
      form.flightSegments[1].originId === form.flightSegments[0].destinationId
    ) {
      return 'round_trip';
    }
  }

  return 'multi_city';
}
