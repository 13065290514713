import { sortBy, includes } from 'lodash';

import { IPaymentType } from '../../contexts/PaymentMethodSelectionContext/types';

export enum PaymentTypes {
  Wire = 'wire',
  ACH = 'ach',
  Card = 'card',
}

export type PaymentType = PaymentTypes.Wire | PaymentTypes.Card | PaymentTypes.ACH;

const PAYMENT_TYPE_ORDER = {
  wire: 0,
  ach: 1,
  card: 2,
};

const PAYMENT_TYPE_LABEL = {
  wire: 'Wire',
  ach: 'ACH',
  card: 'Card',
};

export interface IPaymentTypeOption {
  label: string;
  value: string;
  sortOrder: number;
}

export const generatePaymentTypeOptions = (paymentTypes: IPaymentType[]): IPaymentTypeOption[] => {
  if (!paymentTypes) return null;

  const options = paymentTypes.map((ps: IPaymentType) => ({
    label: PAYMENT_TYPE_LABEL[ps.name],
    value: ps.name,
    sortOrder: PAYMENT_TYPE_ORDER[ps.name],
  }));
  return sortBy(options, (opt) => opt.sortOrder);
};

const DEFAULT_PAYMENT_TYPE_ORDER = [PaymentTypes.ACH, PaymentTypes.Card, PaymentTypes.Wire];

// Provided a list of allowed payment types, selects the payment type we should default selection to
export const selectDefaultPaymentType = (paymentTypes: IPaymentType[]) => {
  let defaultPaymentType = PaymentTypes.Wire;
  const allowedPaymentTypes = paymentTypes.map((pt) => pt.name);
  for (let i = 0; i < DEFAULT_PAYMENT_TYPE_ORDER.length; i += 1) {
    const ptName = DEFAULT_PAYMENT_TYPE_ORDER[i];
    if (includes(allowedPaymentTypes, ptName)) {
      defaultPaymentType = ptName;
      break;
    }
  }
  return defaultPaymentType;
};
