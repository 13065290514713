import React, { CSSProperties, FunctionComponent } from 'react';
import Button from '../Button';

interface IProps {
  onClick: any;
  faIconClass: string;
  type?: 'primary' | 'danger' | 'default';
  disabled?: boolean;
  loading?: boolean;
  style?: CSSProperties;
  className?: string;
  text?: string;
  pullRight?: boolean;
  block?: boolean;
  danger?: boolean;
  testid?: string;
}

const IconButton: FunctionComponent<IProps> = ({
  onClick,
  faIconClass,
  disabled,
  loading = false,
  style,
  className,
  type = 'default',
  text = null,
  block = false,
  pullRight = false,
  danger = false,
  testid,
}) => (
  <Button
    onClick={onClick}
    disabled={disabled}
    // @ts-ignore
    type={type}
    className={`btn icon-btn btn-${type} ${className}`}
    style={style}
    loading={loading}
    block={block}
    responsive={false}
    pullRight={pullRight}
    danger={danger}
  >
    <i data-testid={testid} className={faIconClass} />
    {text && <>&nbsp;&nbsp;{text}</>}
  </Button>
);

export default IconButton;
