import { Modal } from 'antd';
import React from 'react';
// type must be one of warning/info/success/error
export const showAlert = (title, body, type = 'info', closable = true) => {
  // Styling can be found in modal.scss, .ant-alert-modal
  const content = (
    <div>
      <div className="ant-modal-header">
        <div className="ant-modal-title">{title}</div>
      </div>
      <div data-testid="alert-body" className="ant-modal-content-body">
        {body}
      </div>
    </div>
  );
  Modal.destroyAll();
  Modal[type]({
    content,
    icon: null,
    className: 'ant-alert-modal',
    closable,
  });
};
