import React, { FunctionComponent, useEffect, useState } from 'react';
import { ICurrentUser } from '@jetslash/market-frontend-shared-core/src/types/models/user';
import { getCurrentUser } from '../../api';
import { SIGN_UP_STATES } from '../../models/user';

interface IProps {
  jwtToken?: string;
  authIsHydrated?: boolean;
  children: React.ReactNode;
}

interface IProvidedState {
  currentUser: any;
  currentUserIsHydrated: boolean;
  currentUserIsAuthenticated: boolean;
  refreshCurrentUser?: () => Promise<any>;
  clearCurrentUser?: () => any;
}

const DefaultState: IProvidedState = {
  currentUser: null,
  currentUserIsHydrated: false,
  currentUserIsAuthenticated: false,
};

export const CurrentUserContext = React.createContext(DefaultState);

export const CurrentUserContextProvider: FunctionComponent<IProps> = (props) => {
  const { jwtToken = null, authIsHydrated = null } = props;
  const [state, setState] = useState(DefaultState);

  // We can ascertain whether or not a user is fully confirmed a few different ways, but this keeps it locked in
  // to a server side attribute
  const userIsAuthenticated = (user: ICurrentUser) =>
    user !== null && user.signUpState === SIGN_UP_STATES.accountFullyConfirmed;

  const refreshCurrentUser = async () => {
    try {
      const payload = await getCurrentUser();
      setState({
        currentUser: payload.data,
        currentUserIsHydrated: true,
        currentUserIsAuthenticated: userIsAuthenticated(payload.data),
      });
      return payload.data;
    } catch (e) {
      setState({
        currentUser: null,
        currentUserIsHydrated: true,
        currentUserIsAuthenticated: false,
      });
      return null;
    }
  };

  // jwtToken and authIsHydrated should stay null on Native and never trigger this
  useEffect(() => {
    if (authIsHydrated) {
      if (jwtToken) {
        refreshCurrentUser()
          .then(() => {})
          .catch(() => {});
      } else {
        setState({
          currentUser: null,
          currentUserIsHydrated: true,
          currentUserIsAuthenticated: false,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwtToken, authIsHydrated]);

  const clearCurrentUser = () => {
    setState({
      currentUser: null,
      currentUserIsHydrated: true,
      currentUserIsAuthenticated: false,
    });
  };

  const contextValue = React.useMemo(
    () => ({
      currentUser: state.currentUser,
      currentUserIsHydrated: state.currentUserIsHydrated,
      currentUserIsAuthenticated: state.currentUserIsAuthenticated,
      refreshCurrentUser,
      clearCurrentUser,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.currentUser, state.currentUserIsHydrated, state.currentUserIsAuthenticated],
  );

  return <CurrentUserContext.Provider value={contextValue}>{props.children}</CurrentUserContext.Provider>;
};
