import { useCookies } from 'react-cookie';
import sessionCookieConfig from '@/utility/session/cookies/sessionCookieConfig';

const USER_RETURN_TO_KEY = 'user_return_to';

const useUserReturnTo = () => {
  const [cookies, setCookie, removeCookie] = useCookies([]);

  const recordUserReturnTo = (url) => {
    const path = url.split('?')[0] || url;
    if (path !== '/users/log-in' && path !== '/users/sign-up') {
      setCookie(USER_RETURN_TO_KEY, url, sessionCookieConfig);
    }
  };

  const getUserReturnTo = () => cookies[USER_RETURN_TO_KEY];

  const clearUserReturnTo = () => {
    removeCookie(USER_RETURN_TO_KEY, sessionCookieConfig);
  };

  return {
    recordUserReturnTo,
    getUserReturnTo,
    clearUserReturnTo,
  };
};

export default useUserReturnTo;
