import { useContext } from 'react';
import { ICurrentUser } from '@jetslash/market-frontend-shared-core/src/types/models/user';
import { CurrentUserContext } from '../contexts/CurrentUserContext';

interface IHookReturns {
  currentUser: ICurrentUser;
  refreshCurrentUser: () => Promise<ICurrentUser>;
  currentUserIsHydrated: boolean;
  currentUserIsAuthenticated: boolean;
  clearCurrentUser: () => any;
}

const useCurrentUser = (): IHookReturns => {
  const { currentUser, refreshCurrentUser, currentUserIsHydrated, currentUserIsAuthenticated, clearCurrentUser } =
    useContext(CurrentUserContext);

  return {
    currentUser,
    refreshCurrentUser,
    currentUserIsHydrated,
    currentUserIsAuthenticated,
    clearCurrentUser,
  };
};

export default useCurrentUser;
