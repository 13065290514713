import React, { FunctionComponent } from 'react';
import DesignText from '@/components/typography/DesignText';
import ErrorPageLayout from './ErrorPageLayout';

interface IProps {
  onBack?: () => void;
}

const ErrorStatus500: FunctionComponent<IProps> = ({ onBack }) => (
  <ErrorPageLayout onBack={onBack}>
    <div style={{ marginBottom: 40 }}>
      <DesignText size="xxl" color="white" responsive>
        Sorry, but we’ve experienced an unexpected error.
      </DesignText>
    </div>
    <div style={{ marginBottom: 40, marginTop: 15 }}>
      <DesignText size="xl" color="white" responsive>
        The issue has been logged and will be resolved promptly. If you need assistance, you can always{' '}
        <a href="/concierge">chat with your Flight Concierge</a>.
      </DesignText>
    </div>
  </ErrorPageLayout>
);

export default ErrorStatus500;
