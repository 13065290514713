import React, { FunctionComponent } from 'react';
import styles from './LandingPageContentSection.module.scss';

interface IProps {
  children: React.ReactNode;
}

/**
 * LandingPageContentSection
 *
 * @param children
 * @constructor
 *
 * Provides standardized spacing and organization for each content section on our SEO pages
 */
const LandingPageContentSection: FunctionComponent<IProps> = ({ children }) => (
  <div className={styles.container} data-testid="LandingPageContentSection">
    {children}
  </div>
);

export default LandingPageContentSection;
