import React, { FunctionComponent } from 'react';
import ComponentLink from '@/components/navigation/ComponentLink';
import Row from '@/components/layout/Row';
import Col from '@/components/layout/Col';
import DesignText from '@/components/typography/DesignText';
import ListItem from '@/components/design/lists/ListItem';
import { truncate } from 'lodash';
import {
  IFlightSearchRequestForm,
  IFlightSearchRequestFormFlightSegment,
} from '@jetslash/market-frontend-shared-core/src/forms/flightSearchRequest';
import { IRecentRequest } from '@jetslash/market-frontend-shared-core/src/contexts/FlightSearchRequestContext/useRecentRequests';

import cx from 'classnames';
import styles from './OneRecentRequestLinkButton.module.scss';

interface IProps {
  recentRequest: IRecentRequest;
  onSelect: () => void;
}

interface IDisplayValues {
  type: 'ONE_WAY' | 'ROUND_TRIP' | 'MULTI_CITY';
  origin: string;
  destination: string;
  departs: string;
  returns: string | null;
  icon: string;
}

const formatDateString = (flightSegment: IFlightSearchRequestFormFlightSegment) =>
  `${flightSegment.departDate.format('ddd, MMM Do, YYYY')} ${flightSegment.departTime.format('h:mm a')}`;

const generateDisplayValuesForTripType = (request: IFlightSearchRequestForm): IDisplayValues => {
  const { flightSegments } = request;
  const firstSegment = flightSegments[0];
  const lastSegment = flightSegments[flightSegments.length - 1];
  if (flightSegments.length === 1 || request.requestType === 'one_way') {
    return {
      type: 'ONE_WAY',
      origin: firstSegment.origin,
      destination: firstSegment.destination,
      departs: formatDateString(firstSegment),
      returns: null,
      icon: 'fa fa-long-arrow-right',
    };
  }
  if (
    flightSegments.length === 2 &&
    flightSegments[0].originId === flightSegments[1].destinationId &&
    flightSegments[1].originId === flightSegments[0].destinationId
  ) {
    return {
      type: 'ROUND_TRIP',
      origin: firstSegment.origin,
      destination: firstSegment.destination,
      departs: formatDateString(firstSegment),
      returns: formatDateString(lastSegment),
      icon: 'fa fa-exchange',
    };
  }
  return {
    type: 'MULTI_CITY',
    origin: firstSegment.origin,
    destination: 'Multiple',
    departs: formatDateString(firstSegment),
    returns: formatDateString(lastSegment),
    icon: 'fa fa-random',
  };
};

const OneRecentRequestLinkButton: FunctionComponent<IProps> = ({ recentRequest, onSelect }) => {
  const displayValues = generateDisplayValuesForTripType(recentRequest.requestParams);
  const originDisplay = truncate(displayValues.origin, { length: 25 });
  const destinationDisplay = truncate(displayValues.destination, {
    length: 25,
  });
  return (
    <ComponentLink href={recentRequest.path} shallow callback={onSelect}>
      <ListItem>
        <div className={styles.container}>
          <Row>
            <Col span={2}>
              <i className={cx([displayValues.icon, styles.icon])} />
            </Col>
            <Col span={22}>
              <Row>
                <Col span={24} style={{ overflowX: 'hidden' }}>
                  <DesignText size="medium" pullLeft color="primary">
                    {originDisplay}
                  </DesignText>
                  <DesignText size="medium" pullLeft color="primary">
                    &nbsp;&nbsp;-&nbsp;&nbsp;
                  </DesignText>
                  <DesignText size="medium" pullLeft color="primary">
                    {destinationDisplay}
                  </DesignText>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <DesignText pullLeft color="default">
                    {displayValues.departs}
                  </DesignText>
                  {displayValues.returns && (
                    <>
                      <DesignText pullLeft color="default">
                        &nbsp;&nbsp;-&nbsp;&nbsp;
                      </DesignText>
                      <DesignText pullLeft color="default">
                        {displayValues.returns}
                      </DesignText>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </ListItem>
    </ComponentLink>
  );
};

export default OneRecentRequestLinkButton;
