import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './Icon.module.scss';

interface IProps {
  name: string;
  className?: string;
  style?: any;
  // eslint-disable-next-line react/no-unused-prop-types
  enabled?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  disabled?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  type?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  outline?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  fab?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  beforeText?: boolean;
  color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'default' | 'white' | 'surface';
  // eslint-disable-next-line react/no-unused-prop-types
  useHardCodedIconName?: boolean; // prevent the class name from fill in fa- . Currently only used for landing pages
}

/**
 * ICON
 * @param props
 * @constructor
 *
 * Note: This currently references FontAwesome v5
 * You can search for icon names here: https://fontawesome.com/v5/search
 */
const Icon: FunctionComponent<IProps> = (props) => {
  const {
    name,
    className = '',
    enabled,
    disabled,
    outline,
    fab,
    beforeText,
    color = 'default',
    style = {},
    useHardCodedIconName = false,
  } = props;
  let iconPrefix = 'fas';
  if (outline) {
    iconPrefix = 'far';
  }
  if (fab) {
    iconPrefix = 'fab';
  }

  return (
    <i
      className={classNames(
        className,
        iconPrefix,
        {
          [`fa-${name}`]: !useHardCodedIconName,
          [name]: useHardCodedIconName,
        },
        styles.base,
        styles[`${color}Color`],
        {
          [styles.disabled]: disabled,
          [styles.enabled]: enabled,
          [styles.beforeText]: beforeText,
        },
      )}
      style={style}
    />
  );
};

const IconForLabel: FunctionComponent<IProps> = (props) => {
  const { name, className = '', style = { width: 26 }, color } = props;
  return <Icon className={classNames(className, styles.IconForLabel)} color={color} style={style} name={name} />;
};

export { IconForLabel };

export default Icon;
