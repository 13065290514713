import qs from 'qs';
import { IFlightSearchRequestForm } from '../../forms/flightSearchRequest';

import FlightRequestParamsEncoder from './FlightRequestParamsEncoder';
import { IFlightSearchResultGroup } from '../../types/flightSearch';
import { IApi } from '../../types/api/serialized';

/**
 * FlightSearchRouteHelper
 *
 * This is the key utility for generating paths for the FlightSearch flow
 */
class FlightSearchRouteHelper {
  static RESULTS_PATH = '/results';

  /**
   * generateResultsPath
   * @param requestId: The ID of the request returned by a Flight Search Request
   * @param requestParams: Object containing the attributes of a FLightSearchRequestForm. This will be encoded on the URL under the `rp` param
   *
   * Returns a string with query params:
   *   request: This is the request ID
   *   rp: Encoded route parameters. These are used to pre-fill the form on the page when sharing links and can also be used to re-request a search after a request has expired
   *
   * This is the path we redirect to when making a search from the Home page or an SEO page. It shows the listed results on this page.
   */
  static generateResultsPath(requestId: string) {
    return `${this.RESULTS_PATH}?request=${requestId}`;
  }

  static generateResultsPathWithParamId(requestParams: IFlightSearchRequestForm = null) {
    return this.generateResultsPath(FlightRequestParamsEncoder.encode(requestParams));
  }

  /**
   *
   * @param requestId: The ID of the specific request we want to display on the page. This will be used to retrieve results
   * @param routeId: Routing ID used to select the FlightSearchResult to display. ie. KSFO::KSAN would display the FlightSearchResult that corresponds to that route
   * @param resultGroup: This is currently a IFlightSearchResultGroup. We will pop off the aircraftTypeGroupSlug as the group so we know which set of options to open
   * @param options: {
   *   position: what position the specific result was displayed in on the page. Used only for analytics
   *   delegate: CustomerUUID of a CustomerDelegate if a customer selected one
   *   requestParams: An un-encoded object containing FlightSearchRequestForm data. If provided, this will be encoded on the URL under the rp= query
   *   encodedRequestParams: Already encoded request parameters. Will be appended under rp
   * }
   *
   * 'Result path' is the path to a page that displays the FlightSearchResultModal on top of the FlightSearchResults page. Once the results have loaded in the background, it
   * display the result that corresponds to the routeId and resultGroup
   */
  static generateResultPath(
    requestId: string,
    routeId: string,
    resultGroup: IFlightSearchResultGroup | IApi.Serialized.FlightSearchResultGroup,
    options: {
      position?: number;
      delegate?: boolean;
      requestParams?: IFlightSearchRequestForm;
      encodedRequestParams?: string;
    } = {},
  ) {
    const position = options?.position || null;
    const delegate = options?.delegate || false;
    let path = `${this.RESULTS_PATH}?request=${requestId}&group=${resultGroup.aircraftTypeGroupSlug}&route=${routeId}`;

    if (position) {
      path += `&position=${position}`;
    }

    if (delegate) {
      path += `&delegate=${delegate}`;
    }

    return path;
  }

  static parseQuery(queryString): {
    requestId?: string;
    flightResultGroupId?: string;
    flightResultRouteId?: string;
    requestParams?: IFlightSearchRequestForm;
  } {
    const urlParamsHash = qs.parse(queryString, {
      ignoreQueryPrefix: true,
    });

    const parseResults = {
      requestId: urlParamsHash.request as string,
      flightResultGroupId: urlParamsHash.group as string,
      flightResultRouteId: urlParamsHash.route as string,
      requestParams: urlParamsHash.request ? FlightRequestParamsEncoder.decode(urlParamsHash.request as string) : null,
      position: urlParamsHash.position || null,
    };

    return parseResults;
  }
}

export default FlightSearchRouteHelper;
