import React, { FunctionComponent, useEffect, useState } from 'react';
import useRecentRequests, {
  IRecentRequest,
} from '@jetslash/market-frontend-shared-core/src/contexts/FlightSearchRequestContext/useRecentRequests';
import { useModal } from '@/hooks/useModal';
import Reporty from '@/utility/errorHandling/Reporty';
import RecentRequestsModal from '@/components/recentRequests/RecentRequestsModal';
import IconButton from '@/components/design/buttons/IconButton';
import usePulse from '@/components/design/containers/PulseContainer/usePulse';


interface IProps {}

const RecentRequestsButton: FunctionComponent<IProps> = () => {
  const { fetchRecentRequests } = useRecentRequests();
  const { triggerPulse } = usePulse();
  const [recentRequests, setRecentRequests] = useState<IRecentRequest[]>([]);
  const { show, RenderModal, hide } = useModal();
  const [clickKey, setClickKey] = useState(0);

  const updateRecentRequests = async () => {
    const results = await fetchRecentRequests();
    setRecentRequests(results);
  };

  useEffect(() => {
    updateRecentRequests().catch((e) => {
      Reporty.error(e);
    });
  }, []);

  const onSelect = () => {
    triggerPulse();
    hide();
    setClickKey(clickKey + 1);
  };

  // Hack to prevent the modal from constantly re-rendering while a search is in progress
  // To achieve this, we memoize the modal component and only re-render when the user performs an action that should
  // change the state of the modal by incrementing a click key
  const modal = React.useMemo(
    () => (
      <RenderModal
        title="Recent searches"
        onCancel={() => {
          setClickKey(clickKey + 1);
        }}
      >
        <RecentRequestsModal recentRequests={recentRequests} onSelect={onSelect} />
      </RenderModal>
    ),
    [clickKey],
  );

  if (recentRequests?.length) {
    return (
      <>
        {modal}
        <IconButton
          testid='recent-requests-button'
          type="default"
          block
          faIconClass="fa fa-redo"
          onClick={() => {
            updateRecentRequests().then(() => {
              show();
              setClickKey(clickKey + 1);
            });
          }}
          text="Recent"
          pullRight
        />
      </>
    );
  }
  return null;
};

export default RecentRequestsButton;
