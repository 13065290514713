import api, { apiError } from '../utilities/api';

import { IPayload, IResponse } from './types';
import { ICustomerDelegateGrantor } from '../models/customerDelegateGrantorModel';

const DELEGATE_GRANTOR_PATH = '/v1/profile/delegates/grantors';

export interface IGetDelegateGrantorsResponse extends IResponse<IPayload<ICustomerDelegateGrantor[]>> {}

/**
 * getDelegateGrantors
 *
 * CustomerDelegateGrantorsController#INDEX
 */
export async function getDelegateGrantors(): Promise<ICustomerDelegateGrantor[]> {
  const path = DELEGATE_GRANTOR_PATH;

  try {
    const { data }: IGetDelegateGrantorsResponse = await api.request({
      path,
      protectRoute: true,
    });
    return data.data;
  } catch (err) {
    throw apiError(err);
  }
}

export interface IGetDelegateGrantorResponse extends IResponse<IPayload<ICustomerDelegateGrantor>> {}

/**
 * getDelegateGrantor
 * @param uuid
 *
 * CustomerDelegateGrantorsController#SHOW
 */
export async function getDelegateGrantor(uuid: string): Promise<ICustomerDelegateGrantor> {
  const path = `${DELEGATE_GRANTOR_PATH}/${uuid}`;

  try {
    const { data }: IGetDelegateGrantorResponse = await api.request({
      path,
      protectRoute: true,
    });
    return data.data;
  } catch (err) {
    throw apiError(err);
  }
}

/**
 * patchDelegateGrantor
 * @param uuid
 *
 * CustomerDelegateGrantorsController#UPDATE
 *
 * 'Accepts' an invite for the given customer grantor
 */
export async function patchDelegateGrantor(uuid: string): Promise<ICustomerDelegateGrantor> {
  const path = `${DELEGATE_GRANTOR_PATH}/${uuid}`;
  const method = 'PATCH';
  try {
    const { data }: IGetDelegateGrantorResponse = await api.request({
      path,
      method,
      data: {
        accept: true,
      },
      protectRoute: true,
    });
    return data.data;
  } catch (err) {
    throw apiError(err);
  }
}

export interface IPostDelegateGrantorResponse extends IResponse<IPayload<any>> {}

/**
 * postDelegateGrantor
 * @param email
 *
 * Creates a delegate grantor (a Primary) for the current user using the specified email
 */
/* eslint-disable no-restricted-globals */
export async function postDelegateGrantor({ email }: { email: string }): Promise<any> {
  const path = DELEGATE_GRANTOR_PATH;
  const method = 'POST';
  try {
    const { data }: IPostDelegateGrantorResponse = await api.request({
      path,
      method,
      data: {
        name,
        email,
      },
      protectRoute: true,
    });

    return data;
  } catch (e) {
    throw apiError(e);
  }
}

export async function deleteDelegateGrantor(uuid) {
  const path = `${DELEGATE_GRANTOR_PATH}/${uuid}`;
  const method = 'DELETE';
  try {
    const { data } = await api.request({
      path,
      method,
      protectRoute: true,
    });

    return data;
  } catch (e) {
    throw apiError(e);
  }
}
