import moment from 'moment-timezone';
import usePlatformTools from '../PlatformToolsContext/usePlatformTools';
import { IFlightSearchRequestForm } from '../../forms/flightSearchRequest';
import FlightRequestParamsEncoder from '../../utilities/flightSearch/FlightRequestParamsEncoder';
import FlightSearchRouteHelper from '../../utilities/flightSearch/FlightSearchRouteHelper';

interface IResult {
  saveRequest: (requestId: string, formValues: IFlightSearchRequestForm) => Promise<any>;
  fetchRecentRequests: () => Promise<IRecentRequest[]>;
  clearRecentRequests: () => Promise<any>;
}

export interface IRecentRequest {
  requestId: string;
  requestParams: IFlightSearchRequestForm;
  rp: string;
  path: string;
}

interface ISavedRecentRequest {
  requestId: string;
  rp: string;
  path: string;
}

const RECENT_REQUESTS_KEY = 'recentRequests';
const MAX_RECENT_REQUESTS_SAVED = 10;

const dedupeRequests = (requests: ISavedRecentRequest[]) => {
  const orderedRps = requests.map((req) => req.rp);
  const dedupedRequests = requests.filter((req, index) => orderedRps.indexOf(req.rp) === index);
  return dedupedRequests;
};

const filterRequests = (requests: ISavedRecentRequest[]) => {
  const dedupedRequests = dedupeRequests(requests);
  const minDate = moment().set({
    hour: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  const validRequests = dedupedRequests.filter(
    (req) => FlightRequestParamsEncoder.decode(req.rp).flightSegments[0].departDate >= minDate,
  );
  return validRequests;
};

const useRecentRequests = (): IResult => {
  const { PersistenceProvider } = usePlatformTools();

  const saveRequest = async (requestId: string, formValues: IFlightSearchRequestForm): Promise<any> => {
    const recentRequest: ISavedRecentRequest = {
      requestId,
      rp: FlightRequestParamsEncoder.encode(formValues),
      path: FlightSearchRouteHelper.generateResultsPath(requestId),
    };

    let savedRequests = await PersistenceProvider.get(RECENT_REQUESTS_KEY);
    if (!savedRequests) {
      savedRequests = [];
    }

    const dedupedSavedRequests = filterRequests(savedRequests);

    if (dedupedSavedRequests.length >= MAX_RECENT_REQUESTS_SAVED) {
      dedupedSavedRequests.pop();
    }

    dedupedSavedRequests.unshift(recentRequest);

    const dedupdeRequests = filterRequests(dedupedSavedRequests);

    return PersistenceProvider.set(RECENT_REQUESTS_KEY, dedupdeRequests);
  };

  const fetchRecentRequests = async (): Promise<IRecentRequest[]> => {
    const persistedRequests: ISavedRecentRequest[] = await PersistenceProvider.get(RECENT_REQUESTS_KEY);
    if (!persistedRequests) return [];

    const dedupedRequests = filterRequests(persistedRequests);

    return dedupedRequests.map((savedRequest) => ({
      requestParams: FlightRequestParamsEncoder.decode(savedRequest.rp),
      ...savedRequest,
    }));
  };

  const clearRecentRequests = async (): Promise<any> => PersistenceProvider.delete(RECENT_REQUESTS_KEY);

  return {
    saveRequest,
    fetchRecentRequests,
    clearRecentRequests,
  };
};

export default useRecentRequests;
