import { useContext } from 'react';
import { PlatformToolsContext } from './index';
import { IPlatformToolReporty, IPlatformToolsPersistenceProvider } from '../../types/platform';
import { EventTrackingEventName } from '../../utilities/eventTracking/events';

interface IResult {
  Reporty: IPlatformToolReporty;
  PersistenceProvider: IPlatformToolsPersistenceProvider;
  track: (eventName: EventTrackingEventName, data: any) => void;
}

const usePlatformTools = (): IResult => {
  const context = useContext(PlatformToolsContext);

  if (context === undefined) {
    throw new Error('usePlatformTools must be used within a PlatformToolsContextProvider');
  }

  return context;
};

export default usePlatformTools;
