const ChatPrefillTypeConstants = {
  ASAP: 'asap',
  INTERNATIONAL: 'international',
  PASSENGER_COUNT: 'passenger-count',
  NO_RESULTS: 'no-results',
  TOO_MANY_SEGMENTS: 'too-many-segments',
  DEFAULT: 'default',
};

export default ChatPrefillTypeConstants;
