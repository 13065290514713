import { FlightSearchRequestFormDefinition, IFlightSearchRequestForm } from '../../../forms/flightSearchRequest';

export interface IQueryPropertyGroup {
  query: string;
}

export class QueryPropGroup {
  static create = (flightSearchRequestFormValues: IFlightSearchRequestForm): IQueryPropertyGroup => {
    const query = FlightSearchRequestFormDefinition.serializers.clientToQuery(flightSearchRequestFormValues);
    return {
      query,
    };
  };
}
