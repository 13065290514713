import request, { AxiosError, Method, ResponseType } from 'axios';
import { IAPIFlashMessage } from '../api/types';
import { camelizeResponsePayload } from './api/keyCamelization';

export interface ParamsObj {
  [key: string]: any;
}

interface IRequestOptions {
  path: string;
  method?: Method;
  params?: ParamsObj | null;
  data?: ParamsObj | null;
  camelize?: boolean;
  protectRoute?: boolean;
}

function apiRequest({
  path,
  method = 'GET',
  params = null,
  data = null,
  camelize = true,
  protectRoute = false,
}: IRequestOptions) {
  const responseType: ResponseType = 'json';
  const contentType = 'application/json';

  const headers = {
    Accept: contentType,
    'Content-Type': contentType,
    'Access-Control-Allow-Origin': '*',
    'Client-Protected': protectRoute, // This tell the appConfigurator to use the handleUnauthorizedRequest method on a 401
  };

  const requestObj = {
    method,
    url: path,
    params,
    responseType,
    headers,
    transformResponse: [].concat(request.defaults.transformResponse, (response) => {
      if (camelize) {
        return camelizeResponsePayload(response);
      }
      return response;
    }),
    data: undefined,
  };

  if (method !== 'GET' && data) {
    requestObj.data = data;
  }

  const req = request(requestObj);

  return req;
}

const api = {
  request: apiRequest,
};

export default api;

export interface ApiError {
  originalError: Error;
  error: string | null;
  errors: {
    [key: string]: Array<string>;
  } | null;
  errorMessages?: Array<string> | null;
  status: number;
  data: any & { flashMessage?: IAPIFlashMessage };
  config?: {
    headers?: {
      [key: string]: any;
    };
  };
}

function serializeApiErrorConfig(originalError: AxiosError) {
  if (!originalError) return {};

  const data = {
    url: originalError?.config?.url,
    method: originalError?.config?.method,
    baseURL: originalError?.config?.baseURL,
    data: originalError?.config?.data,
    headers: originalError?.config?.headers,
    params: originalError?.config?.params,
  };

  return data;
}

function serializeApiResponse(originalError: AxiosError) {
  if (!originalError) return {};

  const data = {
    data: originalError?.response?.data ? JSON.stringify(originalError?.response?.data) : null,
    status: originalError?.response?.status,
    statusText: originalError?.response?.statusText,
    headers: originalError?.response?.headers,
  };

  return data;
}

// Our own ApiError class that inherits from Error
// eslint-disable-next-line @typescript-eslint/no-redeclare
function ApiError() {
  // eslint-disable-next-line prefer-rest-params
  const originalError = arguments[0];
  const errorResponse = originalError?.response;

  // eslint-disable-next-line prefer-rest-params
  const temp = Error.apply(this, arguments);
  // eslint-disable-next-line no-multi-assign
  temp.name = this.name = 'ApiError';
  const data = errorResponse?.data;

  this.type = 'API_ERROR';
  this.originalError = originalError;
  this.status = errorResponse?.status || 0;
  this.data = data && data.data ? data.data : {};

  if (!errorResponse) {
    this.message = 'No internet connection';
    this.error = 'No internet connection';
    this.status = 0;
  } else {
    const config = serializeApiErrorConfig(originalError);
    this.config = config;
    this.response = serializeApiResponse(originalError);
    // @ts-ignore
    this.message = `Request to ${config?.url} failed with status code ${errorResponse.status}`;
    if (errorResponse.status === 500) {
      this.error = 'Internal server error';
    } else {
      this.error = data?.error;
      this.errors = data?.errors;
      this.errorMessages = data?.errorMessages;
      this.validationErrorMessages = data?.validationErrorMessages;
      this.invalidFields = data?.invalidFields;
      this.errorType = data?.errorType;
      this.errorResource = data?.errorResource;
    }
  }

  if (Object.defineProperty) {
    // getter for more optimizy goodness
    /* this.stack = */ Object.defineProperty(this, 'stack', {
      get() {
        return temp.stack;
      },
      configurable: true, // so you can change it if you want
    });
  } else {
    this.stack = temp.stack;
  }
}

// inherit prototype using ECMAScript 5 (IE 9+)
ApiError.prototype = Object.create(Error.prototype, {
  constructor: {
    value: ApiError,
    writable: true,
    configurable: true,
  },
});

export function apiError(originalError: any) {
  try {
    // @ts-ignore
    return new ApiError(originalError);
  } catch (e) {
    // Keeping this here in case we run into issues with new error handler
    const errorResponse = originalError?.response;
    if (!errorResponse) {
      return {
        originalError,
        error: 'No internet connection',
        status: 0,
        data: {},
        type: 'API_ERROR',
      };
    }

    originalError as AxiosError;

    const { status, data } = errorResponse;

    if (status === 500) {
      return {
        config: serializeApiErrorConfig(originalError),
        response: serializeApiResponse(originalError),
        originalError,
        error: 'Internal server error',
        status,
        data: data && data.data ? data.data : {},
        type: 'API_ERROR',
      };
    }

    const ret = {
      originalError,
      response: serializeApiResponse(originalError),
      config: serializeApiErrorConfig(originalError),
      error: data?.error,
      errors: data?.errors,
      errorMessages: data?.errorMessages,
      validationErrorMessages: data?.validationErrorMessages,
      invalidFields: data?.invalidFields,
      status,
      data: data?.data,
      type: 'API_ERROR',
    };

    return ret;
  }
}
