import React, { FunctionComponent, useState } from 'react';

interface IProps {
  // currentUser: CurrentUser;
  children: React.ReactNode;
}

interface IDefaultState {
  // Values
  fullScreenLoading: boolean;
  loading: boolean;
  // Actions
  setFullScreenLoading?: (isLoading: boolean) => void;
  setLoading?: (isLoading: boolean) => void;
}

const DefaultState: IDefaultState = {
  fullScreenLoading: false,
  loading: false,
};

export const LoadingContext = React.createContext(DefaultState);

export const LoadingContextProvider: FunctionComponent<IProps> = ({ children }) => {
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ fullScreenLoading, loading, setFullScreenLoading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};
