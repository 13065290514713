import React, { FunctionComponent } from 'react';
import { useRouter } from 'next/router';

import Logo from '@/components/layout/Logo';
import DesignText from '@/components/typography/DesignText';
import PageSeo from '@/components/seo/PageSeo';
import Button from '../../design/Button';
import styles from './ErrorPageLayout.module.scss';

interface IProps {
  onBack?: () => void;
  children: React.ReactNode;
}

const ErrorPageLayout: FunctionComponent<IProps> = ({ children, onBack }) => {
  const router = useRouter();
  const goBack = () => {
    router.back();
    setTimeout(() => {
      if (onBack) {
        onBack();
      }
    }, 300);
  };
  return (
    <div className={styles.body}>
      <PageSeo title="When you truly need to be there" noIndex noFollow />
      <div className={styles.container}>
        <div
          style={{
            maxWidth: 1050,
            paddingLeft: 10,
            paddingRight: 10,
            margin: 'auto',
          }}
        >
          <div className={styles.logoContainer}>
            <Logo color="white" size="big" />
          </div>
          {children}
          <Button
            onClick={goBack}
            type="white"
            size="large"
            style={{
              width: 200,
              height: 70,
              fontSize: 22,
              marginTop: 30,
              marginBottom: 10,
            }}
          >
            Take me back!
          </Button>
        </div>
      </div>
      <div className={styles.footer}>
        <DesignText size="medium" color="white">
          support@flymoment.com
        </DesignText>
      </div>
    </div>
  );
};

export default ErrorPageLayout;
