import React, { Component } from 'react';

const AppContext = React.createContext({});

type IProps = {
  constants: any;
  exp: {
    errorRecovery: any;
  };
  children: React.ReactNode;
};

type IState = {
  constants: any;
  errorRecovery?: any;
};

/* eslint-disable react/state-in-constructor */
export class AppContextProvider extends Component<IProps, IState> {
  // @ts-ignore
  state: IState;

  constructor(props: IProps) {
    super(props);

    this.state = {
      constants: props.constants,
    };

    if (props.exp) {
      this.state.errorRecovery = props.exp.errorRecovery;
    }
  }

  render() {
    const { children } = this.props;
    return (
      <AppContext.Provider
        value={{
          ...this.state,
        }}
      >
        {children}
      </AppContext.Provider>
    );
  }
}

export const withContext = (ChildComponent: any) =>
  // eslint-disable-next-line func-names
  function (props: any) {
    return (
      <AppContext.Consumer>{(globalState: any) => <ChildComponent {...globalState} {...props} />}</AppContext.Consumer>
    );
  };
