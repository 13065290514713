import { EventTrackingEventName } from '@jetslash/market-frontend-shared-core/src/utilities/eventTracking/events';
import Reporty from '@/utility/errorHandling/Reporty';

export function track(eventName: EventTrackingEventName, data: any) {
  // console.log(`Track ${eventName}:`);
  // console.log(data);
  try {
    window.analytics.track(eventName, data);
  } catch (e) {
    Reporty.error(e);
  }
}

export async function trackAsync(eventName: EventTrackingEventName, data: any) {
  try {
    track(eventName, data);
    await sleep(300); // this mimics the trackLink method in the Segment JS SDK
  } catch (e) {
    Reporty.error(e);
  }
}

// sleep time expects milliseconds
export function sleep(time) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, time));
}
