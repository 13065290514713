import React, { FunctionComponent, useContext } from 'react';
import { AuthenticatedContext } from '@/contexts/AuthenticatedContext';
import JwtTokenDisplay from '@/utility/auth/JwtTokenDisplay';
import styles from './JwtTokenDebugView.module.scss';

interface IProps {}

const JwtTokenDebugView: FunctionComponent<IProps> = () => {
  const { jwtToken } = useContext(AuthenticatedContext);

  if (!jwtToken) {
    return (
      <div className={styles.container}>
        <div>No token set</div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <JwtTokenDisplay jwtToken={jwtToken} />
    </div>
  );
};

export default JwtTokenDebugView;
