class RouteHelper {
  static tripPath(tripPnr: string) {
    return `/trips/${tripPnr}`;
  }

  static tripsPath() {
    return '/trips';
  }

  static tripPaymentPath(tripPnr: string) {
    return `/trips/${tripPnr}/payment`;
  }

  static tripCheckoutPath(tripPnr: string) {
    return `/saved/${tripPnr}/checkout`;
  }

  static tripCheckoutCompletePath(tripPnr: string) {
    return `/saved/${tripPnr}/checkout/complete`;
  }

  static savedTripPath(tripPnr: string) {
    return `/saved/${tripPnr}`;
  }

  static airportsPath() {
    return '/airports';
  }

  static airportRegionPath(region: string) {
    return `${this.airportsPath()}/${region.toUpperCase()}`;
  }

  static airportPath(region: string, airportUid: string) {
    return `${this.airportRegionPath(region)}/${airportUid.toUpperCase()}`;
  }

  static airportRoutePath(region: string, airportUid: string, destRegion: string, destAirportUid: string) {
    return `${this.airportPath(region, airportUid)}/${destRegion.toUpperCase()}/${destAirportUid.toUpperCase()}`;
  }

  static destinationsPath() {
    return '/destinations';
  }

  static destinationRegionPath(region: string) {
    return `${this.destinationsPath()}/${region.toUpperCase()}`;
  }

  static destinationPath(region: string, destinationUid: string) {
    return `${this.destinationRegionPath(region)}/${destinationUid}`;
  }

  static destinationRoutePath(region: string, destinationUid: string, destRegion: string, destDestinationUid: string) {
    return `${this.destinationPath(region, destinationUid)}/${destRegion.toUpperCase()}/${destDestinationUid}`;
  }

  static homePath() {
    return ''
  }

  static safetyPath() {
    return '/safety';
  }

  static realtimePath() {
    return '/realtime';
  }

  static faqPath() {
    return '/faq';
  }
}

export default RouteHelper;
