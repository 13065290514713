import React, { FunctionComponent, useEffect, useState } from 'react';

import { Formik, FormikProps } from 'formik';
import { range, merge, isEmpty } from 'lodash';

import {
  FlightSearchRequestFormDefinition,
  IFlightSearchRequestForm,
  ITripTypesMap,
} from '@jetslash/market-frontend-shared-core/src/forms/flightSearchRequest';
import useFlightSearchRequest from '@jetslash/market-frontend-shared-core/src/contexts/FlightSearchRequestContext/useFlightSearchRequest';

import { trackAsync } from '@/utility/eventTracking/eventTracker';
import { EVENT_PRODUCTS_SEARCHED } from '@jetslash/market-frontend-shared-core/src/utilities/eventTracking/events';
import { QueryPropGroup } from '@jetslash/market-frontend-shared-core/src/utilities/eventTracking/propertyGroupSerializers/QueryPropGroup';

import DesignText from '@/components/typography/DesignText';
import showFlightSearchRequestError from '@/components/flightSearch/showFlightSearchRequestError';

import useRecentRequests from '@jetslash/market-frontend-shared-core/src/contexts/FlightSearchRequestContext/useRecentRequests';
import RecentRequestsButton from '@/components/recentRequests/RecentRequestsButton';
import Reporty from '@/utility/errorHandling/Reporty';
import FlightRequestParamsEncoder from '@jetslash/market-frontend-shared-core/src/utilities/flightSearch/FlightRequestParamsEncoder';
import { FLIGHT_SEARCH_REQUEST_PENDING_STATUS } from '@jetslash/market-frontend-shared-core/src/contexts/FlightSearchRequestContext/constants';
import { searchFlightResults } from '@jetslash/market-frontend-shared-core/src/services/flightSearch/searchFlightResults';
import { ERROR_MESSAGE_DEFAULT_TEXT } from '@jetslash/market-frontend-shared-core/src/utilities/errorHandling/constants';
import Icon from '../Icon';
import OneWayFlightSearch from './flightSearchRequestSearchModes/OneWayFlight';
import RoundTripFlightSearch from './flightSearchRequestSearchModes/RoundTripFlight';
import MultiCityFlightSearch from './flightSearchRequestSearchModes/MultiCityFlight';
import Radio from './fields/Radio';
import Form from './fields/Form';
import SelectField from './fields/SelectField';
import { Button } from '../design';
import { Row, Col } from '../layout';

interface IProps {
  flightSearchRequestCallback?: (flightSearchRequestFormValues: IFlightSearchRequestForm, requestId: string) => void;
  initialFormValues?: IFlightSearchRequestForm | null;
  showRecentRequests?: boolean; // will display Recent Requests modal button
  onPageSearch?: boolean; // True if form is on results page, False if form is on Landing Page
}

const FlightSearchRequestForm: FunctionComponent<IProps> = ({
  flightSearchRequestCallback,
  initialFormValues,
  showRecentRequests,
  onPageSearch = false,
}) => {
  const { saveRequest } = useRecentRequests();
  const { setRequestPending, setRequestError, currentRequest } = useFlightSearchRequest();

  const [initialFormValuesState, setInitialFormValuesState] = useState<IFlightSearchRequestForm>();
  const [loading, setLoading] = useState(false);

  const formDefinition = new FlightSearchRequestFormDefinition();

  const onSubmit = async (formValues: IFlightSearchRequestForm) => {
    setLoading(true);
    // @ts-ignore
    setRequestPending({}); // Triggers the loading indicator to make it feel more responsive
    const flightSearchRequestFormValues = FlightSearchRequestFormDefinition.adjustFormValuesPreSubmit(formValues);
    try {
      // must do this to 'sleep' the request before page unloads
      await trackAsync(EVENT_PRODUCTS_SEARCHED, QueryPropGroup.create(flightSearchRequestFormValues));
    } catch (e) {
      Reporty.error(e);
    }
    try {
      const response = await searchFlightResults(flightSearchRequestFormValues);
      //  Right now, this triggers request polling by pushing the request ID up into the URL
      // We may need to change this if we want to make it more responsive
      await saveRequest(response.data.data.requestId, flightSearchRequestFormValues);
      flightSearchRequestCallback(flightSearchRequestFormValues, response.data.data.requestId);
    } catch (err) {
      const defaultErrorMessage = ERROR_MESSAGE_DEFAULT_TEXT;
      const { error, status, data } = err;
      // This is a  bit different than the way we handle 422s elsewhere
      // Since we introduced the errorType pattern for Flight Options Search, we no longer use the raw_validation_error format
      if (status) {
        switch (status) {
          case 401: // Unauthorized
            // We use the encoded form params as the requestId to mirror the behavior that "logged in" case uses
            // eslint-disable-next-line no-case-declarations
            const encodedFormParams = FlightRequestParamsEncoder.encode(flightSearchRequestFormValues);
            await saveRequest(encodedFormParams, flightSearchRequestFormValues);
            flightSearchRequestCallback(flightSearchRequestFormValues, encodedFormParams);
            break;
          case 422:
            setRequestError({
              status: 'error',
              error: data.error,
              errorType: data.errorType,
            });
            if (!onPageSearch) {
              showFlightSearchRequestError(data.error, data.errorType);
            }
            break;
          case 429:
            setRequestError({
              status: 'error',
              error: "We're currently experiencing a high volume of searches. Please try again a bit later.",
              errorType: 'SEARCH_TOO_MANY_REQUESTS',
            });
            showFlightSearchRequestError(
              "We're currently experiencing a high volume of searches. Please try again a bit later.",
              'SEARCH_TOO_MANY_REQUESTS',
            );
            break;
          case 500:
            setRequestError({ status: 'error', error: defaultErrorMessage });
            showFlightSearchRequestError(defaultErrorMessage);
            break;
          default:
            if (error) {
              setRequestError({ status: 'error', error });
            } else {
              setRequestError({ status: 'error', error: defaultErrorMessage });
            }
            showFlightSearchRequestError(error);
        }
      } else {
        Reporty.error(err);
        setRequestError({ status: 'error', error: defaultErrorMessage });
        flightSearchRequestCallback(flightSearchRequestFormValues, 'error');
      }
    }
    setLoading(false);
  };

  const tripTypesMap: ITripTypesMap = {
    oneWay: OneWayFlightSearch,
    multiCity: MultiCityFlightSearch,
    roundTrip: RoundTripFlightSearch,
  };

  const initialValues = initialFormValues
    ? merge(formDefinition.initialValues(), initialFormValues)
    : formDefinition.initialValues();

  // We must do this to only update initialValues when the intialValues have actually changed, as opposed to a change being detected
  // because of Moment date object comparison
  // Without this, it will evaluate two Moment dates as being different even if they are identical, causing the form to clear
  useEffect(() => {
    setInitialFormValuesState(initialValues);
  }, [FlightRequestParamsEncoder.encode(initialValues)]); // Make sure that this is performant

  if (!initialFormValuesState) return null;

  const disableButton = loading || (currentRequest && currentRequest.status === FLIGHT_SEARCH_REQUEST_PENDING_STATUS);

  return (
    <Formik
      initialValues={initialFormValuesState}
      enableReinitialize
      validationSchema={formDefinition.validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, setFieldValue, errors }: FormikProps<IFlightSearchRequestForm>) => {
        const onTripTypeChange = (val: any) => {
          // Overwrite the return segment with reversed values of the first segment any time we switch to round trip
          if (val.target.value === 'round_trip' && values.requestType !== 'round_trip') {
            // only want to do this if we have segments initialized
            if (values.flightSegments.length >= 2) {
              setFieldValue('flightSegments.1.destination', values.flightSegments[0].origin);
              setFieldValue('flightSegments.1.destinationId', values.flightSegments[0].originId);
              setFieldValue('flightSegments.1.origin', values.flightSegments[0].destination);
              setFieldValue('flightSegments.1.originId', values.flightSegments[0].destinationId);
            }
          }
          setFieldValue('requestType', val.target.value);
        };
        const FormForTripType = FlightSearchRequestFormDefinition.resolveTripType(values, tripTypesMap);

        return (
          <Form>
            <Row
              style={{
                paddingLeft: 5,
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <Col xs={24} sm={24} md={12} lg={10} xl={8}>
                <Radio.Group
                  style={{ width: '100%' }}
                  onChange={onTripTypeChange}
                  defaultValue={values.requestType}
                  value={values.requestType}
                >
                  <Radio.Button style={{ width: '33%', textAlign: 'center' }} value="one_way">
                    <DesignText strong>One way</DesignText>
                  </Radio.Button>
                  <Radio.Button style={{ width: '34%', textAlign: 'center' }} value="round_trip">
                    <DesignText strong>Round trip</DesignText>
                  </Radio.Button>
                  <Radio.Button style={{ width: '33%', textAlign: 'center' }} value="multi_city">
                    <DesignText strong>Multi-city</DesignText>
                  </Radio.Button>
                </Radio.Group>
              </Col>
              <Col xs={24} sm={24} md={24} lg={2} xl={13} />
              <Col xs={24} sm={24} md={24} lg={12} xl={3}>
                {showRecentRequests && <RecentRequestsButton />}
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={19}>
                <FormForTripType values={values} setFieldValue={setFieldValue} />
              </Col>
              {/* used to unset ellipsis in Select.scss} */}
              <Col xs={24} sm={24} md={24} lg={12} xl={2} className="flight-search-form-pax-select">
                <SelectField
                  name="numPax"
                  icon={<Icon outline name="user" beforeText style={{ color: 'rgba(0,0,0,.25)' }} />}
                  mobileIconBefore="user"
                  options={range(1, 16).map((num) => ({
                    value: num,
                    label: num === 15 ? '15+' : num,
                  }))}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={3}>
                <Button
                  block
                  type="primary"
                  onClick={() => handleSubmit()}
                  disabled={!isEmpty(errors) || disableButton}
                  className="btn btn-primary"
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FlightSearchRequestForm;
