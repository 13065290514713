import { FunctionComponent } from 'react';
import styles from './ContentContainer.module.scss';

interface IProps {
  children: React.ReactNode;
}

const ContentContainer: FunctionComponent<IProps> = ({ children }) => (
  <div className={styles.contentContainer}>{children}</div>
);

export default ContentContainer;
