import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';

interface IProps {
  width?: number;
  height?: number;
}

const LoadingAnimation: FunctionComponent<IProps> = ({ width, height }) => (
  <img src="https://cdn.flymoment.com/images/gifs/loading.gif" style={{ width, height }} alt="loading..." />
);

LoadingAnimation.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

LoadingAnimation.defaultProps = {
  width: 160,
  height: 60,
};

export default LoadingAnimation;
