import React, { FunctionComponent } from 'react';
import { ButtonProps } from 'antd/lib/button';
import { Button as AntdButton } from 'antd';

import classNames from 'classnames';
import styles from './Button.module.scss';

// @ts-ignore
interface IProps extends ButtonProps {
  responsive?: boolean;
  actionBtn?: boolean;
  disabled?: boolean;
  className?: string;
  block?: boolean;
  style?: any;
  shape?: 'circle';
  size?: 'default' | 'large';
  type?: 'primary' | 'secondary' | 'default' | 'white' | 'link' | 'text' | 'ghost' | 'dashed';
  onClick?: (e?: any) => void;
  pullRight?: boolean;
  htmlType?: 'submit' | 'button';
  fullWidth?: boolean;
}

const Button: FunctionComponent<IProps> = (props) => {
  const { className, actionBtn, responsive = true, pullRight, htmlType = 'button', fullWidth, ...otherProps } = props;
  return (
    // @ts-ignore
    <AntdButton
      className={classNames(className, styles.base, {
        [styles.responsiveBtn]: responsive,
        [styles.actionBtn]: actionBtn,
        [styles.primary]: props.type && props.type === 'primary',
        [styles.secondary]: props.type && props.type === 'secondary',
        [styles.disabled]: props.disabled,
        [`${styles.pullRight}`]: pullRight,
        [`${styles.fullWidth}`]: fullWidth,
        [`${styles.large}`]: props.size === 'large',
      })}
      htmlType={htmlType}
      {...otherProps}
    />
  );
};

export default Button;
