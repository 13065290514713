import { logout } from '@/utility/auth/authWrapper';
import useUtmParameters from '@/hooks/useUtmParamters';
import useCurrentUser from '@jetslash/market-frontend-shared-core/src/hooks/useCurrentUser';
import useUserReturnTo from '@/utility/session/cookies/useUserReturnTo';
import Reporty from '@/utility/errorHandling/Reporty';
import useRecentRequests
  from "@jetslash/market-frontend-shared-core/src/contexts/FlightSearchRequestContext/useRecentRequests";

const useLogout = () => {
  const { clearUtmParams } = useUtmParameters();
  const { clearCurrentUser } = useCurrentUser();
  const { clearUserReturnTo } = useUserReturnTo();
  const { clearRecentRequests } = useRecentRequests();
  const handleLogout = () => {
    clearUtmParams();
    clearUserReturnTo();

    clearRecentRequests().catch((e) => {
      Reporty.error(e);
    });
    logout(clearCurrentUser);
  };

  return {
    handleLogout,
  };
};

export default useLogout;
