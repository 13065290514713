import React, { CSSProperties, FunctionComponent } from 'react';
import cx from 'classnames';
import richTextToHtml from '@/pageGeneration/services/richTextToHtml';
import { IPrismicRichTextElement } from '@/pageGeneration/services/prismicApi/types';
import styles from './Header.module.scss';

interface IProps {
  level: 1 | 2 | 3 | 4 | 5;
  color?: 'default' | 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'white';
  strong?: boolean;
  thin?: boolean;
  style?: CSSProperties;
  marginBottom?: number;
  className?: string;
  richTextChildren?: IPrismicRichTextElement[];
  sizeLevel?: 1 | 2 | 3 | 4 | 5;
  responsive?: boolean;
  children?: React.ReactNode;
}

const Header: FunctionComponent<IProps> = ({
  level,
  sizeLevel,
  children,
  style = {},
  color = 'default',
  marginBottom = null,
  strong,
  thin,
  className = '',
  richTextChildren,
  responsive = false,
}) => {
  const HeaderLevelTag = `h${level}` as keyof JSX.IntrinsicElements;
  const newStyle = { ...style, marginBottom };
  return (
    <HeaderLevelTag
      className={cx(
        className,
        styles.header,
        styles[color],
        { [`${styles.strong}`]: strong },
        { [`${styles.thin}`]: thin },
        styles[`size${sizeLevel || level}`],
        { [`${styles.responsive}`]: responsive },
      )}
      style={newStyle}
      dangerouslySetInnerHTML={richTextChildren ? { __html: richTextToHtml(richTextChildren) } : null}
    >
      {children}
    </HeaderLevelTag>
  );
};

export default Header;
