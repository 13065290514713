import {
  fieldsAfterIndex,
  fieldsBeforeIndex,
} from '@jetslash/market-frontend-shared-core/src/utilities/form/dateFieldHelpers';
import { FunctionComponent } from 'react';
import { Col, Row } from '../../layout';
import LocationAutocompleteField from '../fields/LocationAutocompleteField';
import DatePickerField from '../fields/DatePickerField';
import TimePickerField from '../fields/TimePickerField';

interface IProps {
  values: any;
}

const RoundTripFlightSearch: FunctionComponent<IProps> = ({ values }) => (
  <Row data-testid='RoundTripFlightSearch'>
    <Col xs={24} sm={24} md={12} lg={6} xl={6} className="thin-margin-input-sm">
      <LocationAutocompleteField
        name="flightSegments.0.origin"
        placeholder="Origin address"
        shouldOverwriteThisFieldName="flightSegments.1.destination"
      />
    </Col>
    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
      <LocationAutocompleteField
        name="flightSegments.0.destination"
        placeholder="Destination address"
        shouldOverwriteThisFieldName="flightSegments.1.origin"
      />
    </Col>
    <Col xs={12} sm={12} md={6} lg={3} xl={3} className="thin-margin-input-sm">
      <DatePickerField
        name="flightSegments.0.departDate"
        adjustTheseFieldsToBeLaterThanThis={fieldsAfterIndex(
          0,
          values.flightSegments.length,
          'flightSegments.!!INDEX!!.departDate',
        )}
        adjustTheseFieldsToBeEarlierThanThis={fieldsBeforeIndex(0, 'flightSegments.!!INDEX!!.departDate')}
        style={{ width: '100%' }}
      />
    </Col>
    <Col xs={12} sm={12} md={6} lg={3} xl={3} className="thin-margin-input-sm">
      <TimePickerField name="flightSegments.0.departTime" />
    </Col>
    <Col xs={12} sm={12} md={6} lg={3} xl={3}>
      <DatePickerField
        name="flightSegments.1.departDate"
        style={{ width: '100%' }}
        adjustTheseFieldsToBeLaterThanThis={fieldsAfterIndex(
          1,
          values.flightSegments.length,
          'flightSegments.!!INDEX!!.departDate',
        )}
        adjustTheseFieldsToBeEarlierThanThis={fieldsBeforeIndex(1, 'flightSegments.!!INDEX!!.departDate')}
      />
    </Col>
    <Col xs={12} sm={12} md={6} lg={3} xl={3}>
      <TimePickerField name="flightSegments.1.departTime" />
    </Col>
  </Row>
);

export default RoundTripFlightSearch;
