import React, { FunctionComponent } from 'react';
import { NextSeo } from 'next-seo';

interface IProps {
  title;
  noIndex?: boolean;
  noFollow?: boolean;
}

const PageSeo: FunctionComponent<IProps> = ({ title, noIndex = false, noFollow = false }) => (
  <NextSeo title={title ? `Moment - ${title}` : null} nofollow={noFollow} noindex={noIndex} />
);

export default PageSeo;
