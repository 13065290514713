import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';
import { Jwt } from './types';

const subMinutes = (dt: Date, minutes: number) => new Date(dt.getTime() - minutes * 60000);

class JwtUtility {
  static EXP_BUFFER_MINUTES = 3;

  static decodeToken(token: string): Jwt.IToken {
    return jwtDecode<Jwt.IToken>(token);
  }

  static tokenIsExpired(
    token: string,
    bufferMinutes: number = this.EXP_BUFFER_MINUTES,
    comparisonDateOverride: Date = null,
  ): boolean {
    let comparisonDate;
    if (comparisonDateOverride) {
      comparisonDate = comparisonDateOverride;
    } else {
      comparisonDate = new Date();
    }

    const exp = this.getExp(token);
    if (!exp || typeof exp !== 'number') {
      return true;
    }

    const expDate = this.getExpDate(token);
    const expiresAtWithBuffer = subMinutes(expDate, bufferMinutes);
    return comparisonDate > expiresAtWithBuffer;
  }

  static getExp(token: string) {
    const decodedToken = this.decodeToken(token);
    return decodedToken.exp;
  }

  static getExpDate(token: string) {
    return new Date(this.getExp(token) * 1000);
  }

  static prettyJwt(token): Jwt.IPrettyToken {
    if (!token) return null;
    const decodedToken = this.decodeToken(token);

    const base = {
      expiresAt: moment(decodedToken.exp * 1000),
      expiresIn: `${(decodedToken.exp * 1000 - Date.now()) / 1000} seconds`,
      createdAt: moment(decodedToken.created_at * 1000),
      exp: decodedToken.exp,
    };
    return base;
  }
}

export default JwtUtility;
