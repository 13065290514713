/* eslint-disable class-methods-use-this,@typescript-eslint/no-unused-vars,func-names */

import * as Yup from 'yup';
import { merge, cloneDeep } from 'lodash';
import moment from 'moment';

import { IFormDefinition } from '../formDefinitionInterface';
import {
  IFlightSearchRequestForm,
  IFlightSearchRequestFormFlightSegment,
  IMappedLandingPageProps,
  ITripTypesMap,
} from './types';
import Serializers from './serializers';

const DEFAULT_DEPART_DATE_OFFSET_DAYS = process.env.NEXT_PUBLIC_FLIGHT_SEARCH_DEPART_DATE_DEFAULT_OFFSET_DAYS
  ? parseInt(process.env.NEXT_PUBLIC_FLIGHT_SEARCH_DEPART_DATE_DEFAULT_OFFSET_DAYS)
  : 7;

export class FlightSearchRequestFormDefinition implements IFormDefinition {
  private flightSegmentValidationSchema = Yup.object().shape({
    origin: Yup.string().required('You must select an origin').nullable(),
    originId: Yup.string().required('Please select an origin from the provided options').nullable(),
    destination: Yup.string().required('You must select a destination').nullable(),
    destinationId: Yup.string().required('Please select a destination from the provided options').nullable(),
    departDate: Yup.date()
      .required('You must specify a depart date')
      .min(moment().set({ hour: 0, minutes: 0, seconds: 0, milliseconds: 0 }).toDate(), 'You cannot depart in the past')
      .typeError('Invalid date'),
    departTime: Yup.string().required().required('You must specify a depart time'),
  });

  public static serializers = Serializers;

  public validationSchema: any = Yup.object().shape({
    flightSegments: Yup.array()
      .when('requestType', {
        is: 'one_way',
        then: Yup.array()
          // Transform the list of flight segments to ensure we only validate the depart segment
          .transform((currentValue, _originalValue) => {
            if (currentValue && currentValue.length) {
              return [currentValue[0]];
            }
            return currentValue;
          })
          .of(this.flightSegmentValidationSchema),
      })
      .when('requestType', {
        is: 'round_trip',
        then: Yup.array()
          // Transform the list of flight segments to ensure we only validate the depart and return segments
          .transform((currentValue, _originalValue) => {
            if (currentValue && currentValue.length > 1) {
              return [currentValue[0], currentValue[1]];
            }
            return currentValue;
          })
          // Validate that the return date and time make sense relative to the depart date and time
          .of(
            this.flightSegmentValidationSchema.test(
              'rt-dates-make-sense',
              'Return must be after depart',
              function (value) {
                const index = parseInt(this.path.split('[')[1].split(']')[0], 10);

                if (!index) return true;

                const { departDate } = value;
                const prevDepartDate = this.parent[index - 1].departDate;

                const testDepartDate = moment(departDate);
                const testPrevDepartDate = moment(prevDepartDate);

                testDepartDate.set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                });
                testPrevDepartDate.set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                });

                if (testPrevDepartDate > testDepartDate) {
                  return this.createError({
                    path: `${this.path}.departDate`,
                  });
                }
                return true;
              },
            ),
          ),
      })
      .when('requestType', {
        is: 'multi_city',
        // Validate that each segment's deaprt date and time make sense relative to the previous segment's depart date and time
        then: Yup.array().of(
          this.flightSegmentValidationSchema.test(
            'mc-dates-make-sense',
            'Must depart after previous segment',
            function (value) {
              const index = parseInt(this.path.split('[')[1].split(']')[0], 10);

              if (!index) return true;

              const { departDate, departTime } = value;
              const prevDepartDate = this.parent[index - 1].departDate;

              if (prevDepartDate > departDate) {
                return this.createError({
                  path: `${this.path}.departDate`,
                });
              }
              return true;
            },
          ),
        ),
      })
      .required('You must have at least one segment'),
    numPax: Yup.number().min(1, 'You must have at least 1 passenger'),
    requestType: Yup.string(),
  });

  public initialValues(): IFlightSearchRequestForm {
    const values: IFlightSearchRequestForm = {
      flightSegments: [
        FlightSearchRequestFormDefinition.getDefaultFlightSegment(),
        FlightSearchRequestFormDefinition.getDefaultNextDaySegment(),
      ],
      numPax: 1,
      requestType: 'round_trip',
    };

    return values;
  }

  public initialLandingPageValues(dataOverride?: {
    startDatetimeISO8601?: string;
    endDatetimeISO8601?: string;
    destinationName: string;
    destinationLocationAutocompleteId: string;
    originName?: string;
    originLocationAutocompleteId?: string;
  }): IFlightSearchRequestForm {
    const values: IFlightSearchRequestForm = this.initialValues();

    if (dataOverride) {
      return this.formatMarketProps(values, dataOverride);
    }

    return values;
  }

  public static getDefaultFlightSegment(): IFlightSearchRequestFormFlightSegment {
    const prefill = false;
    return {
      origin: prefill ? 'San Francisco, CA, USA' : null,
      originId: prefill ? 'ChIJIQBpAG2ahYAR_6128GcTUEo' : null,
      originAutocompleteSessionToken: prefill ? '94283b86-87d4-4852-bc5a-4e8d1eed49bf' : null,
      destination: prefill ? 'San Diego, CA, USA' : null,
      destinationId: prefill ? 'ChIJSx6SrQ9T2YARed8V_f0hOg0' : null,
      destinationAutocompleteSessionToken: prefill ? '94283b86-87d4-4852-bc5a-4e8d1eed49bf' : null,
      // we must set everything unrelated to zero to ensure that the Moment dates are evaluated as equal when serialized
      // otherwise, the difference in milliseconds will cause Formik to think the form has changed
      departDate: moment()
        .set({ hour: 9, minute: 0, seconds: 0, milliseconds: 0 })
        .add(DEFAULT_DEPART_DATE_OFFSET_DAYS, 'days'),
      departTime: moment().set({
        hour: 9,
        minute: 0,
        seconds: 0,
        milliseconds: 0,
      }),
      numPax: 1,
    };
  }

  public static getDefaultNextDaySegment(
    withSegment?: IFlightSearchRequestFormFlightSegment,
  ): IFlightSearchRequestFormFlightSegment {
    const nextDaySegment = FlightSearchRequestFormDefinition.getDefaultFlightSegment();

    if (withSegment) {
      return merge(nextDaySegment, {
        origin: withSegment.destination,
        originId: withSegment.destinationId,
        departDate: withSegment.departDate,
        departTime: withSegment.departTime,
      });
    }
    nextDaySegment.departDate = nextDaySegment.departDate.add(1, 'days');
    return nextDaySegment;
  }

  public static getDefaultReturnSegment(
    withSegment: IFlightSearchRequestFormFlightSegment,
  ): IFlightSearchRequestFormFlightSegment {
    const defaultSegment = FlightSearchRequestFormDefinition.getDefaultFlightSegment();

    const returnSegment = merge(defaultSegment, {
      origin: withSegment.destination,
      originId: withSegment.destinationId,
      destination: withSegment.origin,
      destinationId: withSegment.originId,
      departDate: cloneDeep(withSegment.departDate),
      departTime: withSegment.departTime,
      numPax: withSegment.numPax,
    });

    returnSegment.departDate = returnSegment.departDate.add(1, 'days');
    return returnSegment;
  }

  public static resolveTripType(values: any, viewMap: ITripTypesMap) {
    let res = viewMap.roundTrip;
    if (values.requestType === 'one_way') {
      res = viewMap.oneWay;
    } else if (values.requestType === 'multi_city') {
      res = viewMap.multiCity;
    } else {
      res = viewMap.roundTrip;
    }
    return res;
  }

  private formatMarketProps(
    original: IFlightSearchRequestForm,
    props: {
      startDatetimeISO8601?: string;
      endDatetimeISO8601?: string;
      destinationName: string;
      destinationLocationAutocompleteId: string;
      originName?: string;
      originLocationAutocompleteId?: string;
    },
  ): IFlightSearchRequestForm {
    return this.valuesFromDestination(original, {
      destination: props.destinationName,
      destinationId: props.destinationLocationAutocompleteId,
      origin: props.originName,
      originId: props.originLocationAutocompleteId,
      departDatetime: props.startDatetimeISO8601
        ? moment(props.startDatetimeISO8601)
            .set({
              hour: 9,
              minute: 0,
              seconds: 0,
              milliseconds: 0,
            })
            .subtract(1, 'day')
        : moment()
            .set({
              hour: 9,
              minute: 0,
              seconds: 0,
              milliseconds: 0,
            })
            .add(DEFAULT_DEPART_DATE_OFFSET_DAYS, 'day'),
      returnDatetime: props.endDatetimeISO8601
        ? moment(props.endDatetimeISO8601)
            .set({
              hour: 9,
              minute: 0,
              seconds: 0,
              milliseconds: 0,
            })
            .add(1, 'day')
        : moment()
            .set({
              hour: 9,
              minute: 0,
              seconds: 0,
              milliseconds: 0,
            })
            .add(DEFAULT_DEPART_DATE_OFFSET_DAYS + 1, 'day'),
    });
  }

  /*
   * Generates FlightSegmentSearchForm initialization data using the passed in destination attributes
   * Example: initializing a form using the data from a MarketDestination
   */
  private valuesFromDestination(
    current: IFlightSearchRequestForm,
    props: IMappedLandingPageProps,
  ): IFlightSearchRequestForm {
    const { destination, destinationId, departDatetime, returnDatetime, origin, originId } = props;

    const first = current.flightSegments[0];
    const second = current.flightSegments[1];

    if (destination && destinationId) {
      first.destination = destination;
      first.destinationId = destinationId;
      second.origin = destination;
      second.originId = destinationId;
    }

    if (origin && originId) {
      first.origin = origin;
      first.originId = originId;
      second.destination = origin;
      second.destinationId = originId;
    }

    if (departDatetime) {
      first.departDate = departDatetime;
      first.departTime = departDatetime;
    }

    if (returnDatetime) {
      second.departDate = returnDatetime;
      second.departTime = returnDatetime;
    } else {
      const newReturnDatetime = cloneDeep(departDatetime);
      newReturnDatetime.add(1, 'day');
      second.departDate = newReturnDatetime;
      second.departTime = newReturnDatetime;
    }

    current.flightSegments[0] = first;
    current.flightSegments[1] = second;

    return current;
  }

  /**
   * adjustFormValuesPreSubmit
   *
   * @param formValue
   *
   * Some forms will require a massaging of data prior to form submission, and adjustFormValuesPreSubmit is the standard place for that logic
   * In this case, we need to take the singular Pax dropdown value and apply it to every segment to meet the TripType agnostic behavior of Hermes
   */
  public static adjustFormValuesPreSubmit(formValue: IFlightSearchRequestForm): IFlightSearchRequestForm {
    formValue.flightSegments.forEach((segment) => {
      // Used to be this for some reason. It may have to do with applying the correct pax after a page load, but not sure
      // segment.numPax = segment.numPax || formValue.numPax;
      segment.numPax = formValue.numPax;
    });
    return formValue;
  }
}
