import React, { FunctionComponent, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Row from '@/components/layout/Row';
import Col from '@/components/layout/Col';
import Logo from '@/components/layout/Logo';
import DesignText from '@/components/typography/DesignText';
import FooterLink from '@/components/layout/PageFooter/FooterLink';
import useIsMarketAppBrowser from '@/hooks/useIsMarketAppBrowser';
import ContentContainer from '@/components/layout/ContentContainer/ContentContainer';
import styles from './styles.module.scss';

interface IProps {}

const PageFooter: FunctionComponent<IProps> = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [termsText, setTermsText] = useState<string>('Terms of service');
  const [privacyText, setPrivacyText] = useState<string>('Privacy policy');

  useEffect(() => {
    setTermsText(isMobile ? 'Terms' : 'Terms of service');
    setPrivacyText(isMobile ? 'Privacy' : 'Privacy policy');
  }, [isMobile]);

  const { isMarketAppBrowser } = useIsMarketAppBrowser();
  if (isMarketAppBrowser) return null;

  return (
    <div className={styles.PageFooter}>
      <ContentContainer>
        <div style={{ marginTop: 10 }}>
          <Row>
            <Col xs={0} sm={4} md={4} lg={4} xl={4}>
              <Logo color="grey" />
            </Col>
            <Col xs={24} sm={12} md={12} lg={10} xl={10}>
              <Row>
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  <div className={styles.NavLinkSection}>
                    <div className={styles.NavLinkSectionTitle}>
                      <DesignText size="medium" color="primary" strong>
                        Explore
                      </DesignText>
                    </div>

                    <FooterLink to="/events" text="Events" />
                    <FooterLink to="/destinations" text="Destinations" />
                    <FooterLink to="/airports" text="Airports" />
                  </div>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  <div className={styles.NavLinkSection}>
                    <div className={styles.NavLinkSectionTitle}>
                      <DesignText size="medium" color="primary" strong>
                        Company
                      </DesignText>
                    </div>
                    <FooterLink to="/safety" text="Safety" />
                    <FooterLink to="/realtime" text="Real-time" />
                    <FooterLink to="/faq" text="FAQ" />
                  </div>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  <div className={styles.NavLinkSection}>
                    <div className={styles.NavLinkSectionTitle}>
                      <DesignText size="medium" color="primary" strong>
                        Legal
                      </DesignText>
                    </div>
                    <FooterLink to="/terms" text={termsText} />
                    <FooterLink to="/privacy" text={privacyText} />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={8} md={8} lg={10} xl={10}>
              <Row>
                <Col xs={8} sm={0} md={0} lg={0} xl={0}>
                  <div className={styles.NavContactSection}>
                    <Logo color="grey" />
                  </div>
                </Col>
                <Col xs={16} sm={24} md={24} lg={24} xl={24}>
                  <div className={styles.NavContactSection}>
                    <FooterLink
                      strong
                      to="mailto:takeoff@flymoment.com"
                      text="takeoff@flymoment.com"
                      icon="envelope"
                      textSize={15}
                    />
                    <div className={styles.NavContactSectionText}>
                      <DesignText style={{ fontSize: 16 }} thin>
                        <span className="hidden-xs hidden-sm">Copyright&nbsp;</span>
                        <span>&copy;</span>
                        <span>&nbsp;2023 - Moment</span>
                      </DesignText>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </ContentContainer>
    </div>
  );
};

export default PageFooter;
