import { useEffect, useState } from 'react';
import Reporty from '@/utility/errorHandling/Reporty';

const useIsMarketAppBrowser = () => {
  const [isMarketAppBrowser, setIsMarketAppBrowser] = useState(false);
  const [isMarketAppBrowserSet, setIsMarketAppBrowserSet] = useState(false);

  useEffect(() => {
    if (window?.localStorage) {
      const isTrue = window.localStorage.getItem('isMarketAppBrowser');
      setIsMarketAppBrowser(!!isTrue);
    } else {
      Reporty.error(new Error('Local storage is not present on window'));
      setIsMarketAppBrowser(false);
    }
    setIsMarketAppBrowserSet(true)
  }, []);

  return { isMarketAppBrowser, isMarketAppBrowserSet };
};

export default useIsMarketAppBrowser;
