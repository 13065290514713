import React, { FunctionComponent } from 'react';
import styles from './LogoBackground.module.scss';

interface IProps {
  children: React.ReactNode;
}

const LogoBackground: FunctionComponent<IProps> = ({ children }) => <div className={styles.container}>{children}</div>;

export default LogoBackground;
