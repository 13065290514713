import React, { FunctionComponent } from 'react';
import { Card as AntCard } from 'antd';
import { CardProps } from 'antd/lib/card';
import cx from 'classnames';
import LoadingComponentOverlay from '../loading/LoadingComponentOverlay';

import styles from './Card.module.scss';

interface IProps extends CardProps {
  selected?: boolean;
}

const Card: FunctionComponent<IProps> = (props) => {
  const { children, loading, bodyStyle, selected, ...passProps } = props;
  return (
    <AntCard
      {...passProps}
      bodyStyle={{ padding: 0 }}
      className={cx(props.className, { [`${styles.selected}`]: selected })}
    >
      <div
        style={{
          position: 'relative',
          display: 'block',
          height: 'auto',
          boxSizing: 'border-box',
        }}
      >
        <LoadingComponentOverlay loading={loading} />
        <div style={{ position: 'relative' }}>
          <div className="ant-card-body" style={bodyStyle}>
            {children}
          </div>
        </div>
      </div>
    </AntCard>
  );
};

export default Card;
