const localConfig = {
  path: '/',
};

const secureConfig = {
  path: '/',
  secure: true,
  domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
};

const sessionCookieConfig = process.env.NEXT_PUBLIC_ENV_NAME === 'local' ? localConfig : secureConfig;

export default sessionCookieConfig;
