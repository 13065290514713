import { map, cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { IFlightSearchRequestForm, IFlightSearchRequestFormFlightSegment } from '../../forms/flightSearchRequest';
import renameKeys from '../serialization/renameKeys';

interface IInterstitialForm {
  numPax: number;
  flightSegments: IInterstitialFormSegment[];
  requestType: string; // enum of oneWay, roundTrip, multiCity
  shouldTriggerValidation?: boolean;
}

interface IInterstitialFormSegment {
  origin: string | null;
  originId: string | null;
  originAutocompleteSessionToken: string | null;
  destination: string | null;
  destinationId: string | null;
  destinationAutocompleteSessionToken: string | null;
  departDate: string;
  departTime?: string;
  numPax: number | null;
}

const clientToMinifiedFlightSegmentAttributeMap = {
  origin: 'o',
  originId: 'oId',
  destination: 'd',
  destinationId: 'dId',
  departDate: 'dDt',
  departTime: 'dTm',
  numPax: 'p',
};

const clientToMinifiedRequestFormAttributeMap = {
  requestType: 'rt',
  flightSegments: 'fs',
  numPax: 'p',
};

const minifiedToClientFlightSegmentAttributeMap = {
  o: 'origin',
  oId: 'originId',
  d: 'destination',
  dId: 'destinationId',
  dDt: 'departDate',
  dTm: 'departTime',
  p: 'numPax',
};

const minifiedToClientRequestFormAttributeMap = {
  rt: 'requestType',
  fs: 'flightSegments',
  p: 'numPax',
};

interface IStandardizedEncodedForm {
  numPax: number;
  flightSegments: IStandardizedEncodedFormFlightSegment[];
  requestType: string; // enum of oneWay, roundTrip, multiCity
}

interface IStandardizedEncodedFormFlightSegment {
  origin: string | null;
  originId: string | null;
  destination: string | null;
  destinationId: string | null;
  departDate: string;
  departTime: string;
  numPax: number | null;
}

class FlightRequestParamsEncoder {
  static encode(formParams: IFlightSearchRequestForm): string {
    const standardizedForm = this.standardize(cloneDeep(formParams));
    const minifiedFormParams = this.minify(standardizedForm);
    // Remove = padding from encoding to make request params easier to copy from search bar
    return btoa(unescape(encodeURIComponent(JSON.stringify(minifiedFormParams))));
  }

  static decode(encodedParams: string): IFlightSearchRequestForm {
    try {
      const minifiedFormParams = JSON.parse(decodeURIComponent(escape(atob(encodedParams))));

      const interstitial: IInterstitialForm = this.deminify(minifiedFormParams);
      interstitial.flightSegments = map(interstitial.flightSegments, (seg) => {
        const joinedDate = moment(`${seg.departDate}-${seg.departTime}`, 'YYYY-MM-DD-HHmm');
        // @ts-ignore
        seg.departDate = joinedDate;
        // @ts-ignore
        seg.departTime = joinedDate;
        return seg;
      });

      return interstitial as unknown as IFlightSearchRequestForm;
    } catch (e) {
      return null;
    }
  }

  static minify(formParams: IStandardizedEncodedForm) {
    formParams.flightSegments = formParams.flightSegments.map((flightSegmentParams) =>
      renameKeys(flightSegmentParams, clientToMinifiedFlightSegmentAttributeMap),
    );
    return renameKeys(formParams, clientToMinifiedRequestFormAttributeMap);
  }

  /*
   * Because we use FlightSearchFormParams to deterministically generate the routeId, we must standardize the exact order and keys that get encoded
   * Through standardize, we remove superfluous keys and set the order of object keys
   */
  static standardize(formParams: IFlightSearchRequestForm): IStandardizedEncodedForm {
    formParams.flightSegments = formParams.flightSegments.map(
      ({ originAutocompleteSessionToken, destinationAutocompleteSessionToken, ...standardParams }) => {
        const segment = cloneDeep(standardParams);
        // @ts-ignore
        segment.departDate = segment.departDate.format('YYYY-MM-DD');
        // @ts-ignore
        segment.departTime = segment.departTime.format('HHmm');
        return segment;
      },
    );
    const orderedParams = this.orderKeys(formParams);
    return orderedParams as unknown as IStandardizedEncodedForm;
  }

  static orderKeys(formParams: IFlightSearchRequestForm) {
    const orderFn = (unordered, sortOrder: string[]) =>
      Object.keys(unordered)
        .sort((v1, v2) => sortOrder.indexOf(v1) - sortOrder.indexOf(v2))
        .reduce((obj, key) => {
          obj[key] = unordered[key];
          return obj;
        }, {});
    const topLevelOrder = ['flightSegments', 'numPax', 'requestType'];
    const flightSegmentOrder = [
      'origin',
      'originId',
      'destination',
      'destinationId',
      'departDate',
      'departTime',
      'numPax',
    ];
    const orderedParams = orderFn(formParams, topLevelOrder) as IFlightSearchRequestForm;
    orderedParams.flightSegments = orderedParams.flightSegments.map(
      (fs) => orderFn(fs, flightSegmentOrder) as IFlightSearchRequestFormFlightSegment,
    );
    return orderedParams;
  }

  static deminify(minifiedFormParams) {
    minifiedFormParams.fs = minifiedFormParams.fs.map((params) =>
      renameKeys(params, minifiedToClientFlightSegmentAttributeMap),
    );
    return renameKeys(minifiedFormParams, minifiedToClientRequestFormAttributeMap);
  }
}

export default FlightRequestParamsEncoder;
