import { cloneDeep, pick, map, keys } from 'lodash';
import moment, { Moment } from 'moment';

// eslint-disable-next-line import/no-cycle
import parseRequestTypeFromFromForm from '../helpers/parseRequestTypeFromFromForm';

import { IClientForm, IClientFormFlightSegment, IServerForm, IServerFormFlightSegment } from './types';
import renameKeys from '../../../utilities/serialization/renameKeys';

import { clientToServerAttributeMap, serverToClientAttributeMap } from './mappings';

function clientSegmentToServerSegment(flightSegment: IClientFormFlightSegment): IServerFormFlightSegment {
  const newFlightSegment: IClientFormFlightSegment = cloneDeep(flightSegment);
  if (flightSegment.departDate) {
    // @ts-ignore
    const departMoment: Moment | null =
      typeof flightSegment.departDate === 'string'
        ? moment(flightSegment.departDate)
        : cloneDeep(flightSegment.departDate);
    if (flightSegment.departTime) {
      const { departTime } = flightSegment;
      departMoment.set({
        hour: departTime.get('hour'),
        minute: departTime.get('minute'),
        second: 0,
        millisecond: 0,
      });
    }
    newFlightSegment.departDate = departMoment.format('YYYY-MM-DD-HHmm');
  }
  const renamedSegment = renameKeys(newFlightSegment, clientToServerAttributeMap);
  const filteredSegment = pick(renamedSegment, keys(serverToClientAttributeMap)) as IServerFormFlightSegment;
  return filteredSegment;
}

function flightSegmentsForRequestType(clientForm: IClientForm): IClientFormFlightSegment[] {
  const flightSegmentsFromForm = clientForm.flightSegments;
  const requestType = clientForm.requestType ? clientForm.requestType : parseRequestTypeFromFromForm(clientForm);

  if (requestType === 'one_way') {
    return [flightSegmentsFromForm[0]];
  }
  if (requestType === 'round_trip') {
    return [flightSegmentsFromForm[0], flightSegmentsFromForm[1]];
  }
  if (requestType === 'multi_city') {
    return flightSegmentsFromForm;
  }

  return [];
}

function clientSegmentsToServerSegments(clientForm: IClientForm): IServerFormFlightSegment[] {
  const flightSegments = flightSegmentsForRequestType(clientForm);
  const serverSegments = map<IClientFormFlightSegment, IServerFormFlightSegment>(
    flightSegments,
    clientSegmentToServerSegment,
  );

  return serverSegments;
}

/**
 * clientToServer
 *
 * Takes in the raw flight option request object generated by the form and transforms the object
 * to whittle the associated flight segments down to the number that matches their request type,
 * converts the field names to the attribute names required by the query schema, and turns Moment objects
 * into formatted date strings
 */
export default function clientToServer(clientForm: IClientForm): IServerForm {
  const serverSegments = clientSegmentsToServerSegments(clientForm);
  return { segments: serverSegments };
}
