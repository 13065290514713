import React, { FunctionComponent } from 'react';
import style from './styles.module.scss';

interface IProps {
  children: React.ReactNode;
}

const NavBar: FunctionComponent<IProps> = ({ children }) => <div className={style.NavBar}>{children}</div>;

export default NavBar;
