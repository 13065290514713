import { useContext } from 'react';
import { PulseContext } from '@/components/design/containers/PulseContainer/PulseContext';

interface IResult {
  pulseKey: number;
  triggerPulse?: () => void;
}

const usePulse = (): IResult => {
  const context = useContext(PulseContext);

  return context;
};

export default usePulse;
