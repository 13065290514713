import api, { apiError } from '../utilities/api';
import { ITrip } from '../types/trips';

interface IGetCustomerProfilePayload {
  uuid: string;
  name: string;
  phoneNumber: string;
  portalCrmUrl: string;
  userUuid: string;
}

export async function getCustomerProfile(authSecret, customerUuid): Promise<IGetCustomerProfilePayload> {
  const path = `/v1/internal/front-plugin/customers/${customerUuid}?auth_secret=${authSecret}`;

  try {
    const { data } = await api.request({ path, protectRoute: true });
    return data.data;
  } catch (err) {
    throw apiError(err);
  }
}

interface IGetCustomerFrontContactPayload {
  id: string;
  name: string;
  handles: {
    source: string;
    handle: string;
  }[];
}

export async function getCustomerFrontContact(authSecret, contactId: string): Promise<IGetCustomerFrontContactPayload> {
  const path = `/v1/internal/front-plugin/contacts/${contactId}?auth_secret=${authSecret}`;

  try {
    const { data } = await api.request({ path, protectRoute: true });
    return data.data;
  } catch (err) {
    throw apiError(err);
  }
}

interface IGetCustomerMetricsPayload {
  lifetimeBlockHours: number;
  ltmBlockHours: number;
  futureBlockHours: number;
}

export async function getCustomerMetrics(authSecret, customerUuid): Promise<IGetCustomerMetricsPayload> {
  const path = `/v1/internal/front-plugin/customers/${customerUuid}/trips/metrics?auth_secret=${authSecret}`;

  try {
    const { data } = await api.request({ path, protectRoute: true });
    return data.data;
  } catch (err) {
    throw apiError(err);
  }
}

export async function getCustomerUpcomingTrips(authSecret, customerUuid): Promise<ITrip[]> {
  const path = `/v1/internal/front-plugin/customers/${customerUuid}/trips/upcoming?auth_secret=${authSecret}`;

  try {
    const { data } = await api.request({ path, protectRoute: true });
    return data.data;
  } catch (err) {
    throw apiError(err);
  }
}

export async function getCustomerPastTrips(authSecret, customerUuid): Promise<ITrip[]> {
  const path = `/v1/internal/front-plugin/customers/${customerUuid}/trips/past?auth_secret=${authSecret}`;

  try {
    const { data } = await api.request({ path, protectRoute: true });
    return data.data;
  } catch (err) {
    throw apiError(err);
  }
}
