import React, { FunctionComponent, Reducer, useReducer } from 'react';
import {
  FLIGHT_SEARCH_REQUEST_COMPLETE_STATUS,
  FLIGHT_SEARCH_REQUEST_ERROR_STATUS,
  FLIGHT_SEARCH_REQUEST_IDLE_STATUS,
  FLIGHT_SEARCH_REQUEST_PENDING_STATUS,
} from './constants';

import { IFlightSearchResultGroup } from '../../types/flightSearch';
import { IFlightSearchRequestForm } from '../../forms/flightSearchRequest';

export interface IProvidedState {
  requestId?: string;
  currentRequest: IFlightSearchRequestState;
  setRequestPending?: (request: { requestId?: string; poll?: number }) => void;
  setRequestComplete?: (request: IFlightSearchRequestCompleteState) => void;
  setRequestError?: (request: IFlightSearchRequestErrorState) => void;
  setRequestIdle?: () => void;
}

interface IProps {
  children: React.ReactNode;
}

const DefaultState: IProvidedState = {
  currentRequest: { status: FLIGHT_SEARCH_REQUEST_IDLE_STATUS },
};

export const FlightSearchRequestContext = React.createContext(DefaultState);

function reducer(state, action) {
  switch (action.type) {
    case 'setRequestPending': {
      return {
        status: FLIGHT_SEARCH_REQUEST_PENDING_STATUS,
        poll: action.payload.poll || 500,
        requestId: action.payload.requestId,
      };
    }
    case 'setRequestComplete': {
      return {
        status: FLIGHT_SEARCH_REQUEST_COMPLETE_STATUS,
        resultGroups: action.payload.resultGroups,
        flightSearchRequest: action.payload.flightSearchRequest,
        requestId: action.payload.requestId,
      };
    }
    case 'setRequestError': {
      return {
        status: FLIGHT_SEARCH_REQUEST_ERROR_STATUS,
        requestId: action.payload.requestId,
        error: action.payload.error,
        errorType: action.payload.errorType,
      };
    }
    case 'setRequestIdle': {
      return {
        status: FLIGHT_SEARCH_REQUEST_IDLE_STATUS,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Any time we add another field to the other types, we must add the option type here
type IFlightSearchRequestState = {
  status: 'complete' | 'pending' | 'idle' | 'error' | string;
  requestId?: string;
  resultGroups?: IFlightSearchResultGroup[];
  poll?: number;
  error?: string;
  errorType?: string;
  flightSearchRequest?: IFlightSearchRequestForm;
};

type IFlightSearchRequestCompleteState = {
  status: 'complete';
  requestId?: string;
  resultGroups?: IFlightSearchResultGroup[];
  flightSearchRequest?: IFlightSearchRequestForm;
};

type IFlightSearchRequestErrorState = {
  status: 'error';
  requestId?: string;
  error?: string;
  errorType?:
    | 'SEARCH_MULTI_CITY'
    | 'SEARCH_TOO_MANY_PAX'
    | 'SEARCH_DEPARTS_TOO_SOON'
    | 'SEARCH_INTERNATIONAL'
    | 'SEARCH_NO_VIABLE_AIRPORTS'
    | 'SEARCH_ARRIVE_BEFORE_DEPART'
    | 'SEARCH_DEPART_IN_PAST'
    | string;
};

export const FlightSearchRequestProvider: FunctionComponent<IProps> = ({ children }) => {
  const [currentRequestState, dispatch] = useReducer<
    Reducer<IFlightSearchRequestState, { type: string; payload: any }>
  >(reducer, {
    status: FLIGHT_SEARCH_REQUEST_IDLE_STATUS,
  });

  const setRequestPending = (request: { requestId?: string; poll?: number }) => {
    dispatch({ type: 'setRequestPending', payload: request });
  };

  const setRequestComplete = (request: IFlightSearchRequestCompleteState) => {
    dispatch({ type: 'setRequestComplete', payload: request });
  };

  const setRequestError = (request: IFlightSearchRequestErrorState) => {
    dispatch({ type: 'setRequestError', payload: request });
  };

  const setRequestIdle = () => {
    dispatch({ type: 'setRequestIdle', payload: null });
  };

  return (
    <FlightSearchRequestContext.Provider
      value={{
        requestId: currentRequestState.requestId,
        currentRequest: currentRequestState,
        setRequestPending,
        setRequestComplete,
        setRequestError,
        setRequestIdle,
      }}
    >
      {children}
    </FlightSearchRequestContext.Provider>
  );
};
