import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './Logo.module.scss';

interface IProps {
  color: 'white' | 'grey' | 'primary';
  size?: 'default' | 'small' | 'big';
}

const Logo: FunctionComponent<IProps> = ({ size = 'default', color = 'white' }) => (
  <div className={classNames(styles.base, styles[color], styles[size])} />
);

export default Logo;
