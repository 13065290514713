import { FunctionComponent, useEffect } from 'react';
import LogRocket from 'logrocket';
import useCurrentUser from '@jetslash/market-frontend-shared-core/src/hooks/useCurrentUser';
import * as Sentry from '@sentry/nextjs';

interface IProps {}

const SetAnalyticsContext: FunctionComponent<IProps> = () => {
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    if (currentUser?.uuid) {
      if (LogRocket) {
        LogRocket.identify(currentUser?.uuid, {
          email: currentUser?.email,
          display_name: currentUser?.name,
          preferred_name: currentUser?.name,
          name: currentUser?.name,
          first_name: currentUser.firstName,
          last_name: currentUser.lastName,
          created_at: currentUser.createdAt,
        });

        LogRocket.getSessionURL((sessionURL) => {
          Sentry.configureScope((scope) => {
            scope.setExtra('LogRocket', sessionURL);
          });
        });
      }

      Sentry.setUser({ id: currentUser.uuid, email: currentUser.email });

      if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY) {
        window.analytics.identify(currentUser.uuid, {
          name: currentUser.name,
          email: currentUser.email,
          displayName: currentUser.name,
          createdAt: currentUser.createdAt,
          customerUuid: currentUser.customerUuid || 'customer_pending_signup',
        });
      }
    }
  }, [currentUser?.uuid, currentUser?.email, currentUser?.name, currentUser?.firstName, currentUser?.lastName]);

  return null;
};

export default SetAnalyticsContext;
