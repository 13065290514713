import { FunctionComponent } from 'react';

import { FlightSearchRequestFormDefinition } from '@jetslash/market-frontend-shared-core/src/forms/flightSearchRequest';
import {
  fieldsAfterIndex,
  fieldsBeforeIndex,
} from '@jetslash/market-frontend-shared-core/src/utilities/form/dateFieldHelpers';
import Icon from '@/components/Icon';
import { Col, Row } from '../../layout';
import { Button } from '../../design';
import { Text } from '../../typography';

import LocationAutocompleteField from '../fields/LocationAutocompleteField';
import DatePickerField from '../fields/DatePickerField';
import TimePickerField from '../fields/TimePickerField';

interface IProps {
  flightSegment: any;
  index: any;
  values: any;
  arrayHelpers: any;
  isLastSegment: boolean;
}

const FlightSegmentFieldGroup: FunctionComponent<IProps> = ({
  flightSegment,
  index,
  values,
  arrayHelpers,
  isLastSegment,
}) => (
  <Row key={index}>
    <Col span={24} textLeft>
      <Text>{`Segment ${index + 1}`}</Text>
    </Col>
    <Col xs={24} sm={24} md={12} lg={7} xl={7} className="thin-margin-input-sm">
      <LocationAutocompleteField name={`flightSegments.${index}.origin`} placeholder="Origin address" />
    </Col>
    <Col xs={24} sm={24} md={12} lg={7} xl={7} className="thin-margin-input-sm">
      <LocationAutocompleteField
        name={`flightSegments.${index}.destination`}
        shouldOverwriteThisFieldNameIfEmpty={`flightSegments.${index + 1}.origin`}
        placeholder="Destination address"
      />
    </Col>
    <Col xs={12} sm={12} md={12} lg={4} xl={4}>
      <DatePickerField
        name={`flightSegments.${index}.departDate`}
        adjustTheseFieldsToBeLaterThanThis={fieldsAfterIndex(
          index,
          values.flightSegments.length,
          'flightSegments.!!INDEX!!.departDate',
        )}
        adjustTheseFieldsToBeEarlierThanThis={fieldsBeforeIndex(index, 'flightSegments.!!INDEX!!.departDate')}
        style={{ width: '100%' }}
      />
    </Col>
    <Col xs={12} sm={12} md={12} lg={4} xl={4}>
      <TimePickerField name={`flightSegments.${index}.departTime`} style={{ width: '100%' }} />
    </Col>
    <Col
      xs={{ span: 6, offset: 6 }}
      sm={{ span: 6, offset: 6 }}
      md={{ span: 6, offset: 6 }}
      lg={{ span: 1, offset: 0 }}
      xl={{ span: 1, offset: 0 }}
      textCenter
    >
      {index > 1 && isLastSegment && (
        <Button
          aria-label="removeSegment"
          style={{ marginTop: 3, marginBottom: 14 }}
          shape="circle"
          type="primary"
          onClick={() => arrayHelpers.remove(index)}
        >
          {' '}
          <Icon name="minus" />
        </Button>
      )}
    </Col>
    <Col
      xs={{ span: 6, offset: 0 }}
      sm={{ span: 6, offset: 0 }}
      md={{ span: 6, offset: 0 }}
      lg={1}
      xl={1}
      className="text-center"
    >
      {isLastSegment && (
        <Button
          aria-label="addSegment"
          style={{ marginTop: 3, marginBottom: 14 }}
          shape="circle"
          type="primary"
          onClick={() =>
            arrayHelpers.insert(index + 1, FlightSearchRequestFormDefinition.getDefaultNextDaySegment(flightSegment))
          }
        >
          <Icon name="plus" />
        </Button>
      )}
    </Col>
  </Row>
);

export default FlightSegmentFieldGroup;
