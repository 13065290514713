import React, { FunctionComponent } from 'react';
import Icon from '@/components/Icon';
import ComponentLink from '@/components/navigation/ComponentLink';
import styles from './styles.module.scss';

interface IProps {
  to?: string;
  icon?: string;
  text: string;
  onClick?: () => void;
}

const NavMenuItem: FunctionComponent<IProps> = ({ to, icon, text, onClick }) => {
  if (to) {
    return (
      <ComponentLink href={to} callback={onClick}>
        <div className={styles.NavMenuItem}>
          {icon && (
            <>
              <Icon name={icon} />
              &nbsp;&nbsp;
            </>
          )}
          {text}
        </div>
      </ComponentLink>
    );
  }
  return (
    <a onClick={onClick}>
      <div className={styles.NavMenuItem}>
        {icon && (
          <>
            <Icon name={icon} />
            &nbsp;&nbsp;
          </>
        )}
        {text}
      </div>
    </a>
  );
};

export default NavMenuItem;
