import React, { Component, FunctionComponent } from 'react';
import { deleteSession, getRefreshToken } from '@jetslash/market-frontend-shared-core/src/api';
import Reporty from '@/utility/errorHandling/Reporty';
import JwtUtility from '@jetslash/market-frontend-shared-core/src/utilities/auth/jwt/JwtUtility';
import LogRocket from 'logrocket';

interface IInMemoryToken {
  token: string;
  expiry: number;
}

// Global variable for us to set the memory token to
// Allows us to set a JWT in memory without exposing it through local storage
// By making it a global, we can access the in-memory token from anywhere, particularly the getAuthToken helper we pass to Axios
let inMemoryToken: IInMemoryToken;

/**
 * setInMemoryToken
 * @param token: a raw JWT token string
 *
 * Encapsulates the logic necessary to put the app in a 'logged in' state
 * NOTE: Protected pages in the app will be blocked until authIsHydrated gets set to false and the currentUser gets set as a result, so this is a bit of a misnomer
 */
function setInMemoryToken(token: string) {
  if (token) {
    try {
      inMemoryToken = {
        token,
        expiry: JwtUtility.getExp(token),
      };
    } catch (e) {
      Reporty.error(e);
      inMemoryToken = null;
    }
  } else {
    inMemoryToken = null;
  }
}

/**
 * refreshAccessToken
 *
 * Attempts to refresh the current inMemoryToken by calling getRefreshToken, which will use the httpOnly refreshToken cookie to pull a new accessToken
 * If we are successful, we set the resulting token to inMemoryToken
 */
async function refreshAccessToken() {
  try {
    const data = await getRefreshToken();
    if (data?.data) {
      setInMemoryToken(data.data.jwtToken);
    } else {
      // May want to explicitly set null in this case
    }
  } catch (error) {
    console.log(error);
    // May want to explicitly set null in this case, though we don't want to log the user out just because of a connection hiccup
  }
  const jwtToken = inMemoryToken;

  return jwtToken;
}

/**
 * getToken
 *
 * As an exported member of AuthWrapper, provides access to the in-memory token throughout the app
 */
async function getToken() {
  return inMemoryToken?.token;
}

/**
 * logout
 *
 * Provides logout functionality through the rest of the app (though there is typically more logout functionality than what can be defined here)
 * We must call deleteSession first so that we can remove the httpOnly refreshToken cookie.
 */
async function logout(clearoutMethod = () => {}) {
  try {
    await deleteSession({}); // We do not want to catch this error since the user can't really log out unless the httpOnly refreshToken cookie has been removed
    // eslint-disable-next-line no-empty
  } catch (e) {}
  inMemoryToken = null;
  try {
    LogRocket.startNewSession();
  } catch (e) {
    // throw e
  }
  clearoutMethod();
  window.localStorage.setItem('logout', Date.now().toString());
}

// Gets the display name of a JSX component for dev tools
// eslint-disable-next-line @typescript-eslint/no-shadow
const getDisplayName = (Component) => Component.displayName || Component.name || 'Component';

function withAuthSync(WrappedComponent: FunctionComponent<any>) {
  return class extends Component {
    interval: any;

    // eslint-disable-next-line react/state-in-constructor
    public state = {
      authIsHydrated: false,
    };

    // eslint-disable-next-line react/static-property-placement
    static displayName = `withAuthSync(${getDisplayName(WrappedComponent)})`;

    // eslint-disable-next-line react/sort-comp
    async pollForAccessToken() {
      if (inMemoryToken) {
        if (JwtUtility.tokenIsExpired(inMemoryToken.token)) {
          await refreshAccessToken();
          this.setState({
            authIsHydrated: true,
          });
        }
      } else {
        await refreshAccessToken();
        // I'm worried that setState was causing the entire tree to re-render and that's the only reason why getAuthToken works
        // TODO: investigate
        this.setState({
          authIsHydrated: true,
        });
      }
      // This prevents the entire component tree from re-rendering every time we poll
      if (!this.state.authIsHydrated) {
        this.setState({
          authIsHydrated: true,
        });
      }
    }

    constructor(props) {
      super(props);
      this.pollForAccessToken = this.pollForAccessToken.bind(this);
      this.syncLogout = this.syncLogout.bind(this);
    }

    async componentDidMount() {
      this.interval = setInterval(this.pollForAccessToken, 20000);
      await this.pollForAccessToken();
      window.addEventListener('storage', this.syncLogout);
    }

    componentWillUnmount() {
      clearInterval(this.interval);
      window.removeEventListener('storage', this.syncLogout);
      window.localStorage.removeItem('logout');
    }

    syncLogout(event) {
      if (event.key === 'logout') {
        try {
          LogRocket.startNewSession();
        } catch (e) {
          // throw e
        }
        window.location.href = '/users/log-in';
      }
    }

    render() {
      return (
        <WrappedComponent {...this.props} authIsHydrated={this.state.authIsHydrated} jwtToken={inMemoryToken?.token} />
      );
    }
  };
}

export { setInMemoryToken, logout, withAuthSync, getToken };
