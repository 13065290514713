import React, { CSSProperties, FunctionComponent } from 'react';
import Link from 'next/link';

import { UrlObject } from 'url';
import { useRouter } from 'next/router';
import useFlashMessage from '@/hooks/useFlashMessage';
import styles from './ComponentLink.module.scss';

declare type Url = string | UrlObject;

interface IProps {
  href: Url;
  as?: Url;
  shallow?: boolean;
  style?: CSSProperties;
  callback?: () => void;
  children: React.ReactNode;
}

const ComponentLink: FunctionComponent<IProps> = ({
  href,
  as = undefined,
  shallow = false,
  style,
  callback,
  children,
}) => {
  const router = useRouter();
  const { removeAllFlashMessages } = useFlashMessage();

  const onClick = (event) => {
    removeAllFlashMessages();
    event.preventDefault();
    if (callback) {
      callback();
    }
    if (event && (event.ctrlKey || event.metaKey)) {
      let win;

      if (typeof href === 'string') {
        win = window.open(href, '_blank');
      } else {
        win = window.open(href.pathname, '_blank');
      }

      if (event.shiftKey) {
        win.focus();
      }
    } else {
      router.push(href, as, { shallow });
    }
  };

  // This guarantees we get prefetch, though we should check that this works
  return (
    <Link href={href} passHref className={styles.base} onClick={onClick} style={style}>
      {children}
    </Link>
  );
};

export default ComponentLink;
