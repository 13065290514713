import { useContext } from 'react';
import { FlightSearchRequestContext, IProvidedState } from './index';

const useFlightSearchRequest = (): IProvidedState => {
  const context = useContext(FlightSearchRequestContext);

  if (context === undefined) {
    throw new Error('useFlightSearchRequest must be used within a FlightSearchRequestContextProvider');
  }

  return context;
};

export default useFlightSearchRequest;
