import React, { FunctionComponent } from 'react';
import { Radio as AntdRadio } from 'antd';
import { RadioGroupProps } from 'antd/lib/radio';
import { RadioButtonProps } from 'antd/lib/radio/radioButton';

const Group: FunctionComponent<RadioGroupProps> = (props) => <AntdRadio.Group {...props} />;

const Button: FunctionComponent<RadioButtonProps> = (props) => <AntdRadio.Button {...props} />;

export default { Group, Button };
