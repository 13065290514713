import qs from 'qs';
import { IClientForm } from './types';
import clientToServer from './clientToServer';

/**
 * clientToQuery
 *
 * Generates the URL parameters for a flight option `create` from the client form
 */
export default function clientToQuery(clientForm: IClientForm): string {
  const finalParams = clientToServer(clientForm);
  return `${qs.stringify(finalParams, {
    arrayFormat: 'indices',
    encode: false,
  })}`;
}
