/* eslint-disable no-console, no-underscore-dangle */
import React from 'react';

import axios, { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { camelizeResponsePayload, decamelizeRequestPayload } from './utilities/api/keyCamelization';

// The AppConfigurator is intended to be the common interface for all the shared configuration / initialization for the app.
// Any platform specific configurations can be done in their entry index.js files and passed to this to ensure the common interface
// for all shared app logic. These would be native/index.js and web/index.js
const globalAny: any = global;

export class AppConfigurator {
  constants: any;

  loggingEnabled: boolean;

  getAuthToken: (url?: string) => Promise<string>;

  handleUnauthorizedRequest?: () => Promise<string>;

  constructor(configs: any) {
    this.constants = cloneDeep(configs.constants);
    this.constants.ENV = configs.ENV;
    this.getAuthToken = configs.getAuthToken;
    this.handleUnauthorizedRequest = configs.handleUnauthorizedRequest;
    globalAny.ENV = this.constants.ENV;
    const turnOnLogging = false;
    this.loggingEnabled =
      (this.constants.ENV.ENV_NAME === 'local' ||
        this.constants.ENV.ENV_NAME === 'dev' ||
        this.constants.ENV.ENV_NAME === 'demo') &&
      turnOnLogging;
  }

  // THIS SHOULD ONLY BE RUN ONCE IN THE ENTRY INDEX FILES FOR EACH PLATFORM
  configure() {
    this.configureAxios();
  }

  configureAxios() {
    const BLACK_LIST_NO_JWT = [
      'v1/sessions',
      '/sessions',
      '/v1/sessions',
      '/v1/sessions/poll',
      '/v1/users/email-confirmations',
      'v1/users/email-confirmations',
      '/v1/sessions/token-native',
    ];

    // Sets the default URL for Axios to use based on our environment variables
    axios.defaults.baseURL = this.constants.ENV.API_HOST;
    axios.defaults.withCredentials = true; // Passes up our refresh token cookie
    // decamelize all the keys going to our backend
    axios.interceptors.request.use(
      (config) => {
        const newConfig = {
          ...config,
          data: decamelizeRequestPayload(config.data),
          params: decamelizeRequestPayload(config.params),
        };
        // This auth token is necessary to bypass basic auth on all environments except for Prod and Local
        if (this.constants.ENV.APP_AUTH_TOKEN) {
          newConfig.headers['X-MARKET-APP-AUTH-TOKEN'] = this.constants.ENV.APP_AUTH_TOKEN;
        }

        // Get a fresh token every time
        // to ensure everything is up to date with the current session
        // Respect the blacklist
        if (
          (config.method === 'delete' || BLACK_LIST_NO_JWT.indexOf(config.url.split('?')[0]) === -1) &&
          !config.url.split('?')[0].startsWith('/v1/prismic') &&
          !config.url.split('?')[0].startsWith('https://api.darksky.net')
        ) {
          try {
            newConfig.headers.TokenValidatedAt = moment().toISOString();
            return this.getAuthToken(config.url)
              .then((authToken) => {
                if (authToken && authToken.length) {
                  newConfig.headers.Authorization = `Bearer ${authToken}`;
                }

                return Promise.resolve(newConfig);
              })
              .catch((e) => Promise.reject(e));
          } catch (e) {
            return Promise.reject(e);
          }
        } else {
          return Promise.resolve(newConfig);
        }
      },
      // TODO: maybe decamelize errors coming from the server?
      (error) => Promise.reject(error),
    );

    // camelize all the keys from our backend
    axios.interceptors.response.use(
      (response) => {
        if (this.loggingEnabled) {
          const printResponse = cloneDeep(response);
          printResponse.data = null;
          printResponse.request._cachedResponse = null;
          printResponse.request._response = null;
          console.log(printResponse);
        }
        return Promise.resolve(response);
      },
      (error: AxiosError) => {
        if (this.loggingEnabled) {
          console.warn('ERROR REQUEST');
          console.log(error);
        }
        // If we see a 401 response from a 'protected' page, we want to log the user out and send them to the sign out page
        // Because Web and Native will handle this differently, we need to pass in a general handleUnauthorizedRequest method
        // to AppConfigurator
        if (error?.response?.status === 401) {
          if (error.config.headers['Client-Protected'] && this.handleUnauthorizedRequest) {
            this.handleUnauthorizedRequest().catch((e) => {
              console.error(e);
            });
          }
        }
        if (error?.response?.data) {
          error.response.data = camelizeResponsePayload(error.response.data);
        }
        return Promise.reject(error);
      },
    );
  }
}

export default AppConfigurator;

/* eslint-disable func-names */
export const withAppConfigurator = (WrappedComponent: any, configurator: any) =>
  function (props: any) {
    return <WrappedComponent {...props} {...configurator} />;
  };
