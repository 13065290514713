import React, { FunctionComponent, useState } from 'react';

interface IProps {
  children: React.ReactNode;
}

interface IProvidedState {
  pulseKey: number;
  triggerPulse?: () => void;
}

const DefaultState: IProvidedState = {
  pulseKey: 0,
};

export const PulseContext = React.createContext(DefaultState);

export const PulseContextProvider: FunctionComponent<IProps> = (props) => {
  const [pulseKey, setPulseKey] = useState(0);

  const triggerPulse = () => {
    setPulseKey(pulseKey + 1);
  };

  return <PulseContext.Provider value={{ pulseKey, triggerPulse }}>{props.children}</PulseContext.Provider>;
};
