import React, { FunctionComponent, useEffect, useState } from 'react';
import { has } from 'lodash';
import uuidv4 from 'uuid/v4';
import { AutoComplete, Input } from 'antd';
import queryLocationAutocompleteOptions from '@jetslash/market-frontend-shared-core/src/utilities/locationAutocomplete/queryLocationAutocompleteOptions';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import Icon from '../../Icon';
import ValidationWrapper from './helpers/ValidationWrapper';

const DEBOUNCE_MS = 200;

interface IProps {
  name: string;
  shouldOverwriteThisFieldName?: string;
  shouldOverwriteThisFieldNameIfEmpty?: string;
  placeholder?: string;
}

const mapDataSourceItemsToOptionElems = (items) =>
  items
    .filter((item) => item && item.source !== 'error')
    .map((item) => (
      <AutoComplete.Option
        key={item.value}
        value={item.value}
        theme="light"
        title={item.label}
        className={`${item.source}-autocomplete-option`}
        data-testid='LocationAutocompleteOption'
      >
        <span>
          <i
            className={item.source === 'airport' ? 'fa fa-plane' : 'fa fa-map-marker-alt'}
            style={item.source !== 'airport' ? { marginLeft: 2, marginRight: 6 } : { marginRight: 3 }}
          />
          {item.label}
        </span>
      </AutoComplete.Option>
    ));

const LocationAutocompleteField: FunctionComponent<IProps> = ({ ...props }) => {
  const [field, meta] = useField({ name: props.name });
  // Prevents the dropdown from showing if the user has tabbed off a search input before the async search has completed
  // Without this, this will happen if:
  // 1. User types in a few letters, triggering onSearch
  // 2. User tabs off
  // 3. onSearch finishes, calling updateSearchOptions
  // 4. This triggers setDataSourceItems, which will cause the dropdown to update
  const [showResults, setShowResults] = useState(true);

  const { shouldOverwriteThisFieldName, shouldOverwriteThisFieldNameIfEmpty } = props;

  const locationIdFieldName = `${props.name}Id`;
  const [idField, idMeta] = useField({ name: locationIdFieldName });
  const { setFieldValue, values } = useFormikContext();

  // let [overwriteIfEmptyField, overwriteIfEmptyMeta] = useField({ name: shouldOverwriteThisFieldNameIfEmpty });

  const { name, value, onChange, onBlur } = field;
  const { error, touched } = meta;

  const [currentUserPosition, setCurrentUserPosition] = useState<{
    lat: number | null;
    lng: number | null;
  }>({
    lat: null,
    lng: null,
  });

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentUserPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {
          /* If this errors out, we want to treat it as if geolocation is not available and do nothing */
        },
      );
    } else {
      /* geolocation IS NOT available */
    }
  }, []);

  const [dataSourceItems, setDataSourceItems] = useState([]);
  // Using state to lock in initial values
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [autocompleteSessionToken, setAutocompleteSessionToken] = useState(uuidv4());
  const [currentQuery, setCurrentQuery] = useState(null);
  const [waitingForAutomaticSelection, setWaitingForAutomaticSelection] = useState(false);

  const errorMessage = touched && !waitingForAutomaticSelection && (error || idMeta.error);
  const eitherTouched = touched || idMeta.touched;

  const [timerId, setTimerId] = useState(null);
  const debounced =
    (delay: number, fn: any) =>
    (...args: any) => {
      if (timerId) {
        clearTimeout(timerId);
      }
      setTimerId(
        setTimeout(() => {
          fn(...args);
          setTimerId(null);
        }, delay),
      );
    };

  const updateSearchOptions = async (query) => {
    const autocompleteResponseItems = await queryLocationAutocompleteOptions(
      query,
      autocompleteSessionToken,
      currentUserPosition.lat,
      currentUserPosition.lng,
    );
    setDataSourceItems(autocompleteResponseItems);
  };

  const onSearch = debounced(DEBOUNCE_MS, updateSearchOptions);

  const onAutocompleteBlur = (textInput): any => {
    // If the text input field matched the Id field, we know the user blurred away without selecting an option
    if (value === idField.value) {
      setFieldValue(locationIdFieldName, '');
    }
    return onBlur(name)(textInput);
  };

  const dataSourceOptions = mapDataSourceItemsToOptionElems(dataSourceItems);

  const handleSelectedOption = async (selectedOptionValue, selectedOptionLabel) => {
    // we use the actual onChange from Formik to change the location id
    await setFieldValue(locationIdFieldName, selectedOptionValue);
    // Using the passed in name, we set the text field input view
    await setFieldValue(name, selectedOptionLabel);
    // Set the autocomplete session token on the location so that we can retrieve PlaceDetails for it later
    await setFieldValue(`${name}AutocompleteSessionToken`, autocompleteSessionToken);

    if (shouldOverwriteThisFieldName) {
      await setFieldValue(shouldOverwriteThisFieldName, selectedOptionLabel);
      await setFieldValue(`${shouldOverwriteThisFieldName}Id`, selectedOptionValue);
      await setFieldValue(`${shouldOverwriteThisFieldName}AutocompleteSessionToken`, autocompleteSessionToken);
    } else if (shouldOverwriteThisFieldNameIfEmpty) {
      const valueOfOverwriteField = '1';
      if (
        has(values, shouldOverwriteThisFieldNameIfEmpty) &&
        (!valueOfOverwriteField || !valueOfOverwriteField.length)
      ) {
        await setFieldValue(shouldOverwriteThisFieldNameIfEmpty, selectedOptionLabel);
        await setFieldValue(`${shouldOverwriteThisFieldNameIfEmpty}Id`, selectedOptionValue);
        await setFieldValue(`${shouldOverwriteThisFieldNameIfEmpty}AutocompleteSessionToken`, autocompleteSessionToken);
      }
    }
  };

  const handleAutomaticSelection = (option) => {
    handleSelectedOption(option.value, option.label);
  };

  const handleUserSelection = (selectedOptionValue, elt) => {
    const selectedOptionLabel = selectedOptionValue && selectedOptionValue.length > 0 ? elt.title : '';
    handleSelectedOption(selectedOptionValue, selectedOptionLabel);
  };

  const selectFirstOptionForQuery = async (query) => {
    if (!query || query.length < 3) {
      setCurrentQuery(null);
      setWaitingForAutomaticSelection(false);
      return;
    }
    const autocompleteResponseItems = await queryLocationAutocompleteOptions(
      query,
      autocompleteSessionToken,
      currentUserPosition.lat,
      currentUserPosition.lng,
    );
    if (autocompleteResponseItems && autocompleteResponseItems.length) {
      const selectThisOption = autocompleteResponseItems[0];
      handleAutomaticSelection(selectThisOption);
      setTimeout(() => {
        setCurrentQuery(null);
        setWaitingForAutomaticSelection(false);
      }, 300);
    } else {
      setCurrentQuery(null);
      setWaitingForAutomaticSelection(false);
    }
  };

  const { placeholder } = props;

  return (
    <div
      style={{
        paddingLeft: 5,
        paddingRight: 5,
      }}
    >
      <ValidationWrapper error={errorMessage} touched={eitherTouched}>
        <AutoComplete
          onBlur={onAutocompleteBlur}
          onSearch={(query) => {
            setShowResults(true);
            onChange(name)(query);
            setCurrentQuery(query);
            onSearch(query);
          }}
          onInputKeyDown={(e: any) => {
            // This is a hacky workaround to enable TAB selection as required by SERVER-7988
            // We will need to switch to a different UI provider if we want that ability out of the box
            if (e.keyCode === 9) {
              setShowResults(false);
              setWaitingForAutomaticSelection(true);
              selectFirstOptionForQuery(currentQuery).catch(() => {
                setCurrentQuery(null);
                setWaitingForAutomaticSelection(false);
              });
            }
          }}
          onSelect={handleUserSelection}
          value={value}
          dataSource={showResults ? dataSourceOptions : []}
          data-testid={`${name}-LocationAutocompleteField`}
        >
          <Input
            prefix={<Icon name="map-marker-alt" style={{ color: 'rgba(0,0,0,.25)' }} />}
            onFocus={(event) => {
              event.target.select();
            }}
            placeholder={placeholder}
          />
        </AutoComplete>
      </ValidationWrapper>
    </div>
  );
};

export default LocationAutocompleteField;

LocationAutocompleteField.propTypes = {
  name: PropTypes.string.isRequired,
};
