import api, { apiError } from '../utilities/api';

import { IApi } from '../types/api/serialized';

const FLIGHT_OPTIONS_ENDPOINT = '/v1/results';

/**
 * postFlightSearch
 *
 * @param parameterizedFlightSearchRequestForm: serialized in searchFlightResults by FlightSearchRequestFormDefinition.serializers.clientToQuery most likely
 *
 * Posts the flight option search form and returns the corresponding requestId if it succeeds, along with a polling timeout
 */

interface IFlightSearchRequestResponse {
  data: {
    status: number;
    data: {
      requestId: string;
      status: 'pending';
      poll: number;
    };
  };
}

export async function postFlightSearch(
  parameterizedFlightSearchRequestForm: string,
): Promise<IFlightSearchRequestResponse> {
  const method = 'POST';
  const path = `${FLIGHT_OPTIONS_ENDPOINT}?${parameterizedFlightSearchRequestForm}`;

  try {
    const data: IFlightSearchRequestResponse = await api.request({
      path,
      method,
    });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

interface IFlightSearchApiCompletePayload {
  requestId: string;
  datetime: string;
  status: 'complete';
  data: IApi.Serialized.FlightSearchCompletePayload;
}

interface IFlightSearchApiErrorPayload {
  requestId: string;
  status: 'error';
  error: string;
}

interface IFlightSearchApiPendingPayload {
  requestId: string;
  status: 'pending';
  poll: number;
}
/**
 * getAircraftGroupOptionsSearchResult
 *
 * @param requestId: The uuid of the flight option request we need to grab search results for
 * @param parameterizedFlightSearchRequestForm: A stringfied version of the search form parameters to use to kick off a new search if the request id does not exist
 *
 * Once we know the request uuid, we use this endpoint to fetch the actual flight option items. It can return one of a few states:
 *
 * complete: We have found finished results that match the provided requestId, and have returned flightSearchresults under the 'items' key
 * pending: We found an unfinished search matching the requestId, and we should request again in 'poll' milliseconds
 * error: The request has failed, possibly due to it not existing, it expiring, or an async validation error (like an international search)
 */
export async function getFlightSearchResults(
  requestId: string,
  parameterizedFlightSearchRequestForm: string = '',
): Promise<IFlightSearchApiCompletePayload | IFlightSearchApiErrorPayload | IFlightSearchApiPendingPayload> {
  const method = 'GET';
  const path = `${FLIGHT_OPTIONS_ENDPOINT}/${requestId}?${parameterizedFlightSearchRequestForm}`;

  try {
    const data: {
      data: {
        data: IFlightSearchApiCompletePayload | IFlightSearchApiErrorPayload | IFlightSearchApiPendingPayload;
      };
    } = await api.request({
      path,
      method,
    });
    return data.data.data;
  } catch (err) {
    throw apiError(err);
  }
}
