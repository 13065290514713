import { FieldArray } from 'formik';
import { FunctionComponent } from 'react';
import FlightSegmentFieldGroup from './FlightSegmentFieldGroup';

interface IProps {
  values: any;
}

const MultiCityFlightSearch: FunctionComponent<IProps> = ({ values }) => (
  <FieldArray
    name="flightSegments"
    render={(arrayHelpers) => (
      <div>
        {values.flightSegments.map((flightSegment: any, index: number) => (
          <FlightSegmentFieldGroup
            key={`flightSegmentsGroup[${index}]`}
            flightSegment={flightSegment}
            index={index}
            values={values}
            arrayHelpers={arrayHelpers}
            isLastSegment={index === values.flightSegments.length - 1}
          />
        ))}
      </div>
    )}
  />
);

export default MultiCityFlightSearch;
