import { WebViewMessage } from '@jetslash/market-frontend-shared-core/src/services/webView/types';
import Reporty from '@/utility/errorHandling/Reporty';

class WebViewMessageDispatcher {
  payload: WebViewMessage;

  constructor() {
    this.payload = {} as WebViewMessage;
  }

  public success(data) {
    this.payload.status = 1;
    this.payload.data = data;
    return this;
  }

  public error(errorMessage: string) {
    this.payload.status = 0;
    this.payload.errorMessage = errorMessage;
    return this;
  }

  public msg(data = null) {
    this.payload.status = 2;
    this.payload.data = data;
    return this;
  }

  public signOut() {
    this.payload.signOut = true;
    return this;
  }

  public flashMessage(type: 'success' | 'info' | 'warning' | 'error', message: string, title: string = null) {
    this.payload.flashMessage = {
      title,
      type,
      message,
    };
    return this;
  }

  public responseFlashMessage(response) {
    let flashMessagePayload;
    if (response?.flashMessage) flashMessagePayload = response.flashMessage;
    if (response?.data?.flashMessage) {
      flashMessagePayload = response.data.flashMessage;
    } else if (response?.data?.data?.flashMessage) {
      flashMessagePayload = response.data.data.flashMessage;
    }

    if (flashMessagePayload) {
      this.payload.flashMessage = {
        type: flashMessagePayload.type,
        message: flashMessagePayload.message,
      };
    }
    return this;
  }

  public closeWebView() {
    this.payload.status = 1;
    this.payload.data = null;
    this.payload.closeWebView = true;
    return this;
  }

  public redirectTo(url: string) {
    this.payload.redirectUrl = url;
    return this;
  }

  public dispatch(): void {
    const successObjectStringified = JSON.stringify(this.payload);
    setTimeout(() => {
      try {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(successObjectStringified);
        } else {
          Reporty.error('ReactNativeWebView is not present on window');
          setTimeout(() => {
            if (window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage(successObjectStringified);
            } else {
              Reporty.error('ReactNativeWebView is not present on window after second timeout');
              window.postMessage(successObjectStringified, '*'); // We may eventually want to remove this entirely, especially if we are passing sensitive data
            }
          }, 500);
        }
      } catch (e) {
        Reporty.error(e);
      }
    }, 300);
  }
}

export default WebViewMessageDispatcher;
