import React, { FunctionComponent } from 'react';
import { Modal as AntdModal } from 'antd';
import { ModalProps } from 'antd/lib/modal';

// Style for this is contained in styles/vendor/antModal.scss because
// Antd doesn't provide the customization we want
// We should move away from Antd...
interface IProps extends ModalProps {
  modalType?: 'medium' | 'page';
  mobileFullScreen?: boolean;
}

const Modal: FunctionComponent<IProps> = (props) => {
  const { modalType = 'medium', mobileFullScreen } = props;
  return (
    <AntdModal {...props} className={`${modalType}-antd-modal${mobileFullScreen ? ' mobile-full-screen-modal' : ''}`} />
  );
};

export default Modal;
