import React, { FunctionComponent } from 'react';
import styles from '@/utility/auth/JwtTokenDebugView.module.scss';
import moment from 'moment-timezone';
import JwtUtility from '@jetslash/market-frontend-shared-core/src/utilities/auth/jwt/JwtUtility';

interface IProps {
  jwtToken: string;
}

const JwtTokenDisplay: FunctionComponent<IProps> = ({ jwtToken }) => {
  const decodedToken = JwtUtility.decodeToken(jwtToken);
  const displayExpiresAt = `Expires at: ${moment(decodedToken.exp * 1000)}`;
  const displayExpiresIn = `Expires in: ${(decodedToken.exp * 1000 - Date.now()) / 1000} seconds`;
  return (
    <div>
      <div className={styles.text}>{displayExpiresAt}</div>
      <div className={styles.text}>{displayExpiresIn}</div>
      <pre className={styles.text}>{JSON.stringify(decodedToken, null, 2)}</pre>
    </div>
  );
};

export default JwtTokenDisplay;
