import React, { FunctionComponent } from 'react';
import Image from 'next/image';
import DesignText from '@/components/typography/DesignText';
import styles from './UserTestimonialCard.module.scss';

interface IProps {
  imageUrl: string;
  title: string;
  subtitle: string;
  content: string;
}

const UserTestimonialCard: FunctionComponent<IProps> = ({ imageUrl, title, subtitle, content }) => (
  <div className={styles.container}>
    <div className={styles.imageContainer}>
      {imageUrl && (
        <Image
          alt=""
          src={imageUrl}
          fill
          style={{
            objectFit: 'cover',
          }}
        />
      )}
    </div>
    <div className={styles.bodyContainer}>
      <div>
        <DesignText size="medium" strong color="primary" style={{ fontSize: 20 }}>
          {title}
        </DesignText>
      </div>
      {subtitle && (
        <div>
          <DesignText
            size="medium"
            thin
            color="secondary"
            style={{
              fontSize: 16,
              marginBottom: 8,
              textTransform: 'uppercase',
            }}
          >
            {subtitle}
          </DesignText>
        </div>
      )}
      <div>
        <DesignText>{content.split(' ').slice(0, 25).join(' ').concat('...')}</DesignText>
      </div>
    </div>
  </div>
);

export default UserTestimonialCard;
