import React, { FunctionComponent } from 'react';
import ComponentLink from '@/components/navigation/ComponentLink';
import style from './styles.module.scss';

interface IProps {
  to: string;
  text: string;
}

const NavLink: FunctionComponent<IProps> = ({ to, text }) => (
  <div className={style.NavLink}>
    <ComponentLink href={to}>{text}</ComponentLink>
  </div>
);

export default NavLink;
