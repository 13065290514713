import React, { FunctionComponent, SyntheticEvent } from 'react';
import { isMobile } from 'react-device-detect';
import * as PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';

import { Select as AntSelect } from 'antd';
import moment, { Moment } from 'moment';
import ValidationWrapper from './helpers/ValidationWrapper';
import styles from './TimePickerField.module.scss';

interface IProps {
  name: string;
  className?: string;
  style?: any;
}

const TimePickerFieldMobile: FunctionComponent<IProps> = ({ ...props }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta] = useField({ name: props.name });
  const { name, value } = field;
  const { setFieldValue } = useFormikContext();

  const momentToTimeOption = (momentDate: Moment) => {
    if (momentDate) {
      let roundedTime;
      if (momentDate.minute() >= 30) {
        roundedTime = momentDate.minute(30).second(0);
      } else {
        roundedTime = momentDate.minute(0).second(0);
      }
      return roundedTime.format('hh:mma');
    }

    return momentDate;
  };

  const timeOptionToMoment = (timeOptionString: string) => moment(timeOptionString, 'hh:mma');

  const handleTimeSelect = (event: SyntheticEvent) => {
    // @ts-ignore
    const momentForTimeOption = timeOptionToMoment(event.target.value);
    setFieldValue(name, momentForTimeOption);
  };

  return (
    <label className={styles.container}>
      <select
        className={styles.select}
        onChange={handleTimeSelect}
        value={momentToTimeOption(value)}
        data-testid={`${name}-TimePickerFieldMobile`}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
        {timeOptions.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
};

const TimePickerFieldBrowser: FunctionComponent<IProps> = ({ ...props }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta] = useField({ name: props.name });
  const { name, value } = field;
  const { setFieldValue } = useFormikContext();

  const momentToTimeOption = (momentDate: Moment) => {
    if (momentDate) {
      let roundedTime;
      if (momentDate.minute() >= 30) {
        roundedTime = momentDate.minute(30).second(0);
      } else {
        roundedTime = momentDate.minute(0).second(0);
      }
      return roundedTime.format('hh:mma');
    }

    return momentDate;
  };

  const timeOptionToMoment = (timeOptionString: string) => moment(timeOptionString, 'hh:mma');

  const handleTimeSelect = (timeOption) => {
    const momentForTimeOption = timeOptionToMoment(timeOption);
    setFieldValue(name, momentForTimeOption);
  };

  const startingValue = momentToTimeOption(value);

  return (
    <AntSelect
      className="ant-select-override"
      onChange={handleTimeSelect}
      defaultValue={startingValue}
      value={startingValue}
      data-testid={`${name}-TimePickerFieldBrowser`}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      {timeOptions.map((option) => (
        <AntSelect.Option key={option} value={option}>
          {option}
        </AntSelect.Option>
      ))}
    </AntSelect>
  );
};

const TimePickerField: FunctionComponent<IProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta] = useField({ name: props.name });
  const { error, touched } = meta;

  return (
    <div
      style={{
        paddingLeft: 5,
        paddingRight: 5,
        width: '100%',
      }}
      className="time-picker"
    >
      <ValidationWrapper error={error} touched={touched}>
        {isMobile ? <TimePickerFieldMobile {...props} /> : <TimePickerFieldBrowser {...props} />}
      </ValidationWrapper>
    </div>
  );
};
const timeOptions = [
  '12:00am',
  '12:30am',
  '01:00am',
  '01:30am',
  '02:00am',
  '02:30am',
  '03:00am',
  '03:30am',
  '04:00am',
  '04:30am',
  '05:00am',
  '05:30am',
  '06:00am',
  '06:30am',
  '07:00am',
  '07:30am',
  '08:00am',
  '08:30am',
  '09:00am',
  '09:30am',
  '10:00am',
  '10:30am',
  '11:00am',
  '11:30am',
  '12:00pm',
  '12:30pm',
  '01:00pm',
  '01:30pm',
  '02:00pm',
  '02:30pm',
  '03:00pm',
  '03:30pm',
  '04:00pm',
  '04:30pm',
  '05:00pm',
  '05:30pm',
  '06:00pm',
  '06:30pm',
  '07:00pm',
  '07:30pm',
  '08:00pm',
  '08:30pm',
  '09:00pm',
  '09:30pm',
  '10:00pm',
  '10:30pm',
  '11:00pm',
  '11:30pm',
];

TimePickerField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default TimePickerField;
