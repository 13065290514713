import React, { FunctionComponent } from 'react';

import * as Sentry from '@sentry/nextjs';
import { Elements } from '@stripe/react-stripe-js';
import NextNProgress from 'nextjs-progressbar';
import LogRocket from 'logrocket';

import { AppContextProvider } from '@jetslash/market-frontend-shared-core/src/appContext';
import { LoadingContextProvider } from '@jetslash/market-frontend-shared-core/src/contexts/LoadingContextProvider';
import { FlashMessageContextProvider } from '@/contexts/FlashMessageContext';

import { ErrorStatusHandler } from '@/components/layout/errorPages/ErrorStatusHandler';
import SetAnalyticsContext from '@/components/analytics/SetAnalyticsContext';
import AppSeo from '@/components/seo/AppSeo';
import ErrorStatus500 from '@/components/layout/errorPages/ErrorStatus500';

import JwtTokenDebugView from '@/utility/auth/JwtTokenDebugView';
import { CustomerDelegateContextProvider } from '@jetslash/market-frontend-shared-core/src/contexts/CustomerDelegatesContext';

interface IProps {
  pageProps: any;
  stripePromise: any;
  children: React.ReactNode;
}

const resetErrorStatus = ({ resetError }) => <ErrorStatus500 onBack={resetError} />;
/**
 * PageResourcesWrapper
 *
 * @param pageProps
 * @param stripePromise
 * @param children
 * @constructor
 *
 * This is where we define the global tooling and resources for our application
 * With the React Context pattern, we must wrap our App component in these resources to 'provide' them
 *
 * LoadingContextProvider: exposes loading state handlers
 * FlashMessageContextProvider: exposes tooling to display flashMessages
 * Elements: Stripe component that lets us use Stripe Elements throughout the app
 * ErrorStatusHandler: Sets the page to an error screen depending on the status of an errant requests
 * SetAnalyticsContext: Listens for changes in the user to update user identity in tools like Segment, Sentry, and LogRocket
 * Sentry.ErrorBoundary: Catches client-side errors and displays a generic error page
 */
const PageResourcesWrapper: FunctionComponent<IProps> = ({ pageProps, stripePromise, children }) => (
  <LoadingContextProvider>
    <FlashMessageContextProvider>
      <CustomerDelegateContextProvider>
        <AppContextProvider {...pageProps}>
          <Elements stripe={stripePromise}>
            <ErrorStatusHandler>
              <NextNProgress color="black" startPosition={0.3} stopDelayMs={200} height={3} showOnShallow />
              <SetAnalyticsContext />
              <AppSeo />
              {process.env.NEXT_PUBLIC_JWT_DEBUG_VIEW_ON && <JwtTokenDebugView />}
              <Sentry.ErrorBoundary
                fallback={resetErrorStatus}
                onMount={() => {
                  if (LogRocket) {
                    LogRocket.getSessionURL((sessionURL) => {
                      Sentry.configureScope((scope) => {
                        scope.setExtra('LogRocket', sessionURL);
                      });
                    });
                  }
                }}
                beforeCapture={(scope) => {
                  if (LogRocket) {
                    LogRocket.getSessionURL((sessionURL) => {
                      scope.setExtra('LogRocket', sessionURL);
                    });
                  }
                }}
              >
                {children}
              </Sentry.ErrorBoundary>
            </ErrorStatusHandler>
          </Elements>
        </AppContextProvider>
      </CustomerDelegateContextProvider>
    </FlashMessageContextProvider>
  </LoadingContextProvider>
);

export default PageResourcesWrapper;
