import { ReactNode } from 'react';
import uuidv4 from 'uuid/v4';
import { IFlashMessage } from '@jetslash/market-frontend-shared-core/src/types/flashMessage';

export function generateFlashMessage(
  type: 'success' | 'info' | 'warning' | 'error' | 'danger',
  message: string | ReactNode,
): IFlashMessage {
  return {
    uuid: uuidv4(),
    type,
    message,
  };
}
