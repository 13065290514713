import React, { FunctionComponent, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Header from '@/components/typography/Header';
import styles from './CityCarousel.module.scss';

interface IProps {}
let interval;

const rotate = keyframes`
  0% {
    transform: rotateX(180deg);
    opacity: 0;
  }
  6% {
    opacity: 0;
  }
  24% {
    transform: rotateX(0deg);
    opacity: 1;
  }
  74% {
    transform: rotateX(0deg);
    opacity: 1;
  }
  84% {
    opacity: 0;
  }
  100% {
    transform: rotateX(180deg);
    opacity: 0;
  }
`;

const Wrapper = styled.span`
  transform-origin: 50% 100%;
  position: absolute;
  display: inline-block;

  span {
    opacity: 0;
    transform-origin: 50% 100%;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-name: ${rotate};
    position: absolute;
    display: inline-block;
  }
`;

const AnimateWord = ({ word }: { word: string }) => (
  <Wrapper>
    <span>{word}</span>
  </Wrapper>
);

const CityCarousel: FunctionComponent<IProps> = () => {
  const leftCities = [
    'Palo Alto',
    'Burbank',
    'San Diego',
    'Tucson',
    'Austin',
    'Denver',
    'Fresno',
    'Wichita',
    'Santa Fe',
    'Bozeman',
  ];
  const rightCities = [
    'Palm Springs?',
    'Boise?',
    'Spokane?',
    'Truckee?',
    'Albuquerque?',
    'Dayton?',
    'Fort Worth?',
    'White Plains?',
    'San Jose?',
    'Bakersfield?',
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const animationDelay = 3000;

  const setNextIndex = () => {
    if (currentIndex < leftCities.length - 1) {
      setCurrentIndex((prevValue) => {
        if (prevValue < leftCities.length - 1) {
          return prevValue + 1;
        }
        return 0;
      });
    }
  };

  const animateHeadline = () => {
    interval = setInterval(() => {
      setNextIndex();
    }, animationDelay);
  };

  useEffect(() => {
    animateHeadline();
  }, []);

  useEffect(() => {
    let resizeTimer;
    window.addEventListener('resize', () => {
      try {
        clearInterval(interval);
      } catch (e) {
        /* empty */
      }
      document.body.classList.add('resize-animation-stopper');
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        document.body.classList.remove('resize-animation-stopper');
        animateHeadline();
      }, 400);
    });
  }, []);

  return (
    <div className={styles.CityCarousel}>
      <div className="" />
      <div className="carousel-left-container">
        <Header level={1} color="white">
          <span>Meeting in&nbsp;</span>
          <AnimateWord word={leftCities[currentIndex]} />
        </Header>
      </div>
      <div className="carousel-right-container">
        <Header level={1} color="white">
          <span>dinner in&nbsp;</span>
          <AnimateWord word={rightCities[currentIndex]} />
        </Header>
      </div>
    </div>
  );
};

export default CityCarousel;
