import React, { FunctionComponent, useState } from 'react';
import { ICustomerDelegateRecipient } from '../../models/customerDelegateRecipientModel';
import { ICustomerDelegateGrantor } from '../../models/customerDelegateGrantorModel';
import { getDelegateGrantors } from '../../api/delegateGrantorApi';
import { getDelegateRecipients } from '../../api/delegateRecipientApi';
import usePlatformTools from '../PlatformToolsContext/usePlatformTools';

interface IProps {
  children: React.ReactNode;
}

interface IProvidedState {
  customerDelegatesInitialized: boolean;
  delegateRecipients: ICustomerDelegateRecipient[];
  delegateGrantors: ICustomerDelegateGrantor[];
  fetchCustomerDelegates?: () => Promise<any>;
  refreshCustomerDelegates?: () => Promise<any>;
}

const DefaultState: IProvidedState = {
  customerDelegatesInitialized: false,
  delegateRecipients: [],
  delegateGrantors: [],
};

export const CustomerDelegateContext = React.createContext(DefaultState);

export const CustomerDelegateContextProvider: FunctionComponent<IProps> = (props) => {
  const [state, setState] = useState(DefaultState);
  const { Reporty } = usePlatformTools();

  const fetchCustomerDelegates = async () => {
    try {
      const delegateGrantorsResults = await getDelegateGrantors();
      const delegateRecipientsResults = await getDelegateRecipients();

      setState({
        delegateRecipients: delegateRecipientsResults,
        delegateGrantors: delegateGrantorsResults,
        customerDelegatesInitialized: true,
      });
    } catch (e) {
      Reporty.error(e);
      setState({
        delegateRecipients: null,
        delegateGrantors: null,
        customerDelegatesInitialized: true,
      });
    }
  };

  const refreshCustomerDelegates = async () => {
    setState({
      customerDelegatesInitialized: false,
      delegateRecipients: null,
      delegateGrantors: null,
    })
    await fetchCustomerDelegates()
  }

  const actions = {
    fetchCustomerDelegates,
    refreshCustomerDelegates
  };

  return (
    <CustomerDelegateContext.Provider value={{ ...state, ...actions }}>
      {props.children}
    </CustomerDelegateContext.Provider>
  );
};
