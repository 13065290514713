const PAGE_CATEGORIES: {
  [key: string]: 'Static' | 'Airports' | 'Events' | 'Locations';
} = {
  STATIC: 'Static',
  AIRPORTS: 'Airports',
  EVENTS: 'Events',
  LOCATIONS: 'Locations',
};

export { PAGE_CATEGORIES };
