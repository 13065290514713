import moment from 'moment-timezone';

import { IDisplayAttributesSegment } from '../../../types/displayAttributes';
import { IFlightSearchRequestForm, IFlightSearchRequestFormFlightSegment } from '../types';
import parseRequestTypeFromFromForm from '../helpers/parseRequestTypeFromFromForm';

export default function segmentsToClient(segments: IDisplayAttributesSegment[]): IFlightSearchRequestForm {
  const requestSegments: IFlightSearchRequestFormFlightSegment[] = segments.map((segment) => ({
      origin: segment.originAirport.displayName,
      originId: segment.originAirport.slug,
      destination: segment.destinationAirport.displayName,
      destinationId: segment.destinationAirport.slug,
      departDate: moment(segment.departDatetimeISO8601),
      departTime: moment(segment.departDatetimeISO8601),
      numPax: segment.pax,
    }));

  return {
    requestType: parseRequestTypeFromFromForm({ flightSegments: requestSegments }),
    flightSegments: requestSegments,
    numPax: requestSegments[0].numPax,
  };
}
