import qs from 'qs';
import moment from 'moment';
import uuidv4 from 'uuid/v4';
import { merge } from 'lodash';
import api, { apiError } from '../utilities/api';
import { TripStates } from '../models/Trip/constants';
import { ITrip } from '../types/trips';

export interface IGetTripsResponse {
  data: ITrip[];
  paginationMeta: {
    count: number;
    page: number;
    perPage: number;
  };
}

const API_VERSION = 'v1';

export async function getTrips(
  tripStatus: TripStates | null = null,
  page: number = 1,
  perPage: number = 5,
  syncAllStartedAt: string = null,
): Promise<IGetTripsResponse> {
  let path = `/${API_VERSION}/trips`;

  let tripStatusParam = null;
  switch (tripStatus) {
    case 'saved':
      tripStatusParam = 'saved';
      break;
    case 'bookedAndNotFlown':
      tripStatusParam = 'booked_and_not_flown';
      break;
    case 'bookedAndFlown':
      tripStatusParam = 'booked_and_flown';
      break;
    default:
      break;
  }

  let query = {
    page,
    per_page: perPage,
    status: tripStatusParam,
  };

  if (syncAllStartedAt) {
    query = merge(query, {
      syncAllStartedAt,
      requestStartedAt: moment().toISOString(),
      requestUuid: uuidv4(),
    });
  }

  path += `?${qs.stringify(query)}`;

  try {
    const { data } = await api.request({ path, protectRoute: true });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

export interface IGetTripResponse {
  data: IGetTripResponsePayload;
}

export interface IGetTripResponsePayload {
  data: ITrip;
}

export async function getTrip(pnr: string): Promise<IGetTripResponsePayload> {
  const path = `/${API_VERSION}/trips/${pnr}`;
  try {
    const { data }: IGetTripResponse = await api.request({
      path,
      protectRoute: true,
    });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}
