import React, { CSSProperties, FunctionComponent } from 'react';
import cx from 'classnames';
import TextTransition, { presets } from '@/components/typography/TextTransition';
import styles from './DesignText.module.scss';

interface IProps {
  size?: 'small' | 'default' | 'title' | 'medium' | 'large' | 'xl' | 'xxl' | 'huge';
  color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'default' | 'white' | 'surface';
  strong?: boolean;
  thin?: boolean;
  style?: CSSProperties;
  className?: string;
  italic?: boolean;
  transition?: boolean;
  pullLeft?: boolean;
  pullRight?: boolean;
  id?: string;
  responsive?: boolean;
  richText?: boolean;
  strikethrough?: boolean;
  children: React.ReactNode;
}

const DesignText: FunctionComponent<IProps> = ({
  children,
  size = 'default',
  color = 'default',
  strong,
  style = {},
  thin,
  className = '',
  italic,
  transition,
  pullLeft,
  pullRight,
  id,
  responsive = false,
  richText = false,
  strikethrough = false,
}) => {
  if (transition) {
    return (
      <span
        className={cx(
          className,
          styles.DesignText,
          styles[`${color}Color`],
          styles[`${size}Size`],
          { [`${styles.strong}`]: strong },
          { [`${styles.thin}`]: thin },
          { [`${styles.italic}`]: italic },
          { [`${styles.responsive}`]: responsive },
          { [`${styles.richText}`]: richText },
        )}
        style={style}
        id={id}
      >
        {/* @ts-ignore */}
        <TextTransition inline text={children} right={pullRight} springConfig={presets.wobbly} />
      </span>
    );
  }
  return (
    <span
      className={cx(
        className,
        styles.DesignText,
        styles[`${color}Color`],
        styles[`${size}Size`],
        { [`${styles.strong}`]: strong },
        { [`${styles.thin}`]: thin },
        { [`${styles.italic}`]: italic },
        { [`${styles.pullLeft}`]: pullLeft },
        { [`${styles.pullRight}`]: pullRight },
        { [`${styles.responsive}`]: responsive },
        { [`${styles.richText}`]: richText },
        { [`${styles.strikethrough}`]: strikethrough },
      )}
      style={style}
      id={id}
    >
      {children}
    </span>
  );
};

export default DesignText;
