import api, { apiError } from '../utilities/api';
import { IContactDetailsForm } from '../forms/contactDetails';
import { IUserProfile } from '../contexts/CheckoutContext/types';
import { IChangePhoneNumberForm } from '../forms/changePhoneNumber';
import { ISignUpPhoneNumberConfirmationForm } from '../forms/signUpPhoneNumberConfirmation';
import { IChangePasswordForm } from '../forms/changePassword';
import { IAPIFlashMessage, IPayload, IResponse } from './types';

export interface IPostContactDetailsResponse extends IResponse<IPayload<IUserProfile>> {}

export interface IGetUserProfileResponse extends IResponse<IPayload<IUserProfile>> {}

const API_VERSION = 'v1';
const PROFILE_PATH = `/${API_VERSION}/profile`;

/**
 * getUserProfile
 */
export async function getUserProfile(): Promise<IUserProfile> {
  const path = PROFILE_PATH;

  try {
    const { data }: IGetUserProfileResponse = await api.request({
      path,
      protectRoute: true,
    });
    return data.data;
  } catch (err) {
    throw apiError(err);
  }
}

/**
 *  patchContactDetails
 *
 *  @param contactDetailsFormData
 */
export async function patchContactDetails(
  contactDetailsFormData: IContactDetailsForm,
): Promise<IPayload<IUserProfile>> {
  const path = PROFILE_PATH;
  const method = 'PATCH';

  try {
    const { data }: IPostContactDetailsResponse = await api.request({
      path,
      method,
      data: {
        contactInfo: contactDetailsFormData,
      },
      protectRoute: true,
    });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

export interface IPatchChangeEmailResponseData {
  user: IUserProfile;
  flashMessage?: IAPIFlashMessage;
}

export interface IPatchChangeEmailResponsePayload extends IPayload<IPatchChangeEmailResponseData> {}
export interface IPatchChangeEmailResponse extends IResponse<IPatchChangeEmailResponsePayload> {}

export async function patchChangeEmail({ email, currentPassword }): Promise<IPatchChangeEmailResponsePayload> {
  const path = `${PROFILE_PATH}/email`;
  const method = 'PATCH';

  try {
    const { data }: IPatchChangeEmailResponse = await api.request({
      path,
      method,
      data: {
        user: {
          email,
          currentPassword,
        },
      },
      protectRoute: true,
    });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

/**
 * patchChangePhoneNumber
 *
 * @param phoneNumberEntryData: form containing the phoneNumber of the new user
 *
 * Market::PhoneNumberController#update
 *
 * Updates the new user with an unconfirmed phone number
 */

export interface IPatchChangePhoneNumberResponseData {}
export interface IPatchChangePhoneNumberResponse extends IResponse<IPayload<IPatchChangePhoneNumberResponseData>> {}

export async function patchChangePhoneNumber(
  changePhoneNumberData: IChangePhoneNumberForm,
): Promise<IPayload<IPatchChangePhoneNumberResponseData>> {
  const path = `${PROFILE_PATH}/phone-number`;
  const method = 'PATCH';

  try {
    const { data }: IPatchChangePhoneNumberResponse = await api.request({
      path,
      method,
      data: {
        user: changePhoneNumberData,
      },
      protectRoute: true,
    });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

/**
 * patchSignUpPhoneNumberConfirmation
 *
 * @param phoneNumberConfirmationData: form containing the sms confirmation token
 *
 * Market::PhoneNumberController#confirm
 *
 * Applies the sms confirmation token to the user, finishing off the user creation process
 */
export interface IPatchConfirmPhoneNumberResponse extends IResponse<IPayload<IPatchChangePhoneNumberResponseData>> {}
export async function patchConfirmPhoneNumber(
  confirmPhoneNumberData: ISignUpPhoneNumberConfirmationForm,
): Promise<IPayload<IPatchChangePhoneNumberResponseData>> {
  const path = `${PROFILE_PATH}/phone-number/confirm`;
  const method = 'PATCH';

  try {
    const { data }: IPatchConfirmPhoneNumberResponse = await api.request({
      path,
      method,
      data: {
        user: confirmPhoneNumberData,
      },
      protectRoute: true,
    });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}

/**
 * patchChangePassword
 *
 * @param changePasswordData: form containing new password and old password
 *
 * Market::User::ChangePasswordController
 *
 * Applies the sms confirmation token to the user, finishing off the user creation process
 */
export interface IPatchChangePasswordResponseData {}
export interface IPatchChangePasswordResponse extends IResponse<IPayload<IPatchChangePasswordResponseData>> {}

export async function patchChangePassword(
  changePasswordData: IChangePasswordForm,
): Promise<IPayload<IPatchChangePasswordResponseData>> {
  const path = `${PROFILE_PATH}/password`;
  const method = 'PATCH';

  try {
    const { data }: IPatchChangePasswordResponse = await api.request({
      path,
      method,
      data: {
        user: changePasswordData,
      },
      protectRoute: true,
    });
    return data;
  } catch (err) {
    throw apiError(err);
  }
}
