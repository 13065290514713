import React, { CSSProperties, FunctionComponent, ReactNode, SyntheticEvent } from 'react';
import { isMobile } from 'react-device-detect';
import { Select as AntSelect } from 'antd';
import { useField, useFormikContext } from 'formik';
import cx from 'classnames';

import styles from '@/components/forms/fields/SelectField.module.scss';

import ValidationWrapper from './helpers/ValidationWrapper';

interface ISelectFieldProps {
  value: any;
  onBlur: (value: any) => Promise<any>;
  onChange: (value: string | number) => Promise<any>;
  options: SelectOption[];

  className?: string;
  // interface is extended and the extension does not require style
  // eslint-disable-next-line react/no-unused-prop-types
  style?: CSSProperties;
}

interface ISelectFieldBrowserProps extends ISelectFieldProps {
  placeholder?: string | React.ReactNode;
  disabled?: boolean;
  icon?: React.ReactNode;
}

export const SelectFieldBrowser: FunctionComponent<ISelectFieldBrowserProps> = ({
  onBlur,
  onChange,
  value,
  options,
  disabled,
  icon,
  placeholder,
  className = '',
}) => (
  <AntSelect
    onBlur={onBlur}
    onChange={onChange}
    defaultValue={value}
    disabled={disabled}
    value={value}
    style={{ padding: 0 }}
    placeholder={placeholder}
    className={className}
  >
    {options.map((option: any) => (
      <AntSelect.Option key={option.value} value={option.value} data-testid={`${option.value}`}>
        {icon}
        {option.label}
      </AntSelect.Option>
    ))}
  </AntSelect>
);

interface ISelectFieldMobileProps extends ISelectFieldProps {
  placeholder?: string;
  mobileIconBefore?: 'user' | 'suitcase';
}

export const SelectFieldMobile: FunctionComponent<ISelectFieldMobileProps> = ({
  onBlur,
  onChange,
  value,
  options,
  mobileIconBefore,
  placeholder,
  className = '',
  style,
}) => (
  <div className={styles.mobileContainer} style={style}>
    <div className={styles.downCaret}>
      <label
        className={cx(
          styles.container,
          className,
          { [`${styles.iconBefore}`]: !!mobileIconBefore },
          styles[mobileIconBefore],
        )}
        onBlur={onBlur}
      >
        <select
          className={cx(styles.select, styles.mobile, {
            [`${styles.iconBefore}`]: !!mobileIconBefore,
          })}
          onChange={(event: SyntheticEvent<HTMLSelectElement>) => onChange(event.currentTarget.value)}
          value={value}
        >
          {placeholder && (
            <option key={null} value={null} selected hidden disabled>
              {placeholder}
            </option>
          )}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </label>
    </div>
  </div>
);

type SelectOption = {
  value: string | number;
  label: string | number;
};

interface IProps {
  name: string;
  options: SelectOption[];
  className?: string;
  icon?: React.ReactNode;
  autoComplete?: string;
  disabled?: boolean;
  mobileIconBefore?: 'user';
  placeholder?: string | ReactNode;
}

const SelectField: FunctionComponent<IProps> = ({ ...props }) => {
  const [field, meta] = useField({ name: props.name });
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const { value, name } = field;

  const { options, icon, disabled, placeholder } = props;

  const onChange = async (newValue) => {
    await setFieldValue(name, newValue);
    await setFieldTouched(name, newValue);
  };

  const onBlur = async () => {
    await setFieldTouched(name);
  };

  const browserPlaceholder = icon ? (
    <span>
      {icon}
      {placeholder}
    </span>
  ) : (
    placeholder
  );

  return (
    <div
      style={{
        paddingLeft: 5,
        paddingRight: 5,
      }}
      data-testid={`${name}-SelectField`}
    >
      <ValidationWrapper error={meta.error} touched={meta.touched}>
        {isMobile ? (
          <SelectFieldMobile
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            disabled={disabled}
            options={options}
            icon={icon}
            mobileIconBefore={props.mobileIconBefore}
            // @ts-ignore
            placeholder={placeholder}
          />
        ) : (
          <SelectFieldBrowser
            onBlur={onBlur}
            onChange={onChange}
            icon={icon}
            value={value}
            disabled={disabled}
            options={options}
            placeholder={browserPlaceholder}
          />
        )}
      </ValidationWrapper>
    </div>
  );
};

export default SelectField;
