import React from 'react';
import Reporty from '@/utility/errorHandling/Reporty';

interface IPageAttributes {
  category?: 'Static' | 'Airports' | 'Events' | 'Locations';
  name?: string;
}

// Allows us to pass custom values to analytics.page
// NOTE: If we add any more page types with custom attributes, we must add the path prefix to MANUAL_PAGE_PATHS in usePageViews to prevent duplicate paging
const useCustomPageViews = (pageAttributes: IPageAttributes = { category: null, name: null }) => {
  React.useEffect(() => {
    try {
      if (pageAttributes.category && pageAttributes.name) {
        if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY) {
          window.analytics.page(pageAttributes.category, pageAttributes.name);
        }
      }
    } catch (e) {
      Reporty.error(e);
    }
  }, []);
};

export default useCustomPageViews;
