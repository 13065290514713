import React, { FunctionComponent } from 'react';

interface IProps {
  authIsHydrated?: boolean;
  jwtToken?: string;
  children: React.ReactNode;
}

interface IProvidedState {
  authIsHydrated?: boolean;
  jwtToken?: string;
}

const DefaultState: IProvidedState = {};

export const AuthenticatedContext = React.createContext(DefaultState);

export const AuthenticatedContextProvider: FunctionComponent<IProps> = (props) => {
  const { authIsHydrated, jwtToken } = props;

  return (
    <AuthenticatedContext.Provider value={{ authIsHydrated, jwtToken }}>{props.children}</AuthenticatedContext.Provider>
  );
};
