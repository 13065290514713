// Product Events - Flight options viewing
export const EVENT_PRODUCT_CLICKED = 'Product Clicked';
export const EVENT_PRODUCT_LIST_FILTERED = 'Product List Filtered';
export const EVENT_PRODUCTS_SEARCHED = 'Products Searched';
export const EVENT_PRODUCT_LIST_VIEWED = 'Product List Viewed';
export const EVENT_PRODUCT_VIEWED = 'Product Viewed';

// Sign Up Events
export const EVENT_SIGN_UP_STARTED = 'User Sign Up Started';
export const EVENT_SIGN_UP_STEP_VIEWED = 'User Sign Up Step Viewed';

// Log in Events
export const EVENT_LOG_IN_STARTED = 'User Log In Started';

// Checkout events
export const EVENT_CHECKOUT_STARTED = 'Checkout Started';
export const EVENT_CHECKOUT_STEP_VIEWED = 'Checkout Step Viewed';
export const EVENT_PAYMENT_INFO_ENTERED = 'Payment Info Entered';
export const EVENT_CHECKOUT_STEP_COMPLETE = 'Checkout Step Complete';

export type EventTrackingEventName =
  | 'Product Clicked'
  | 'Product List Filtered'
  | 'Products Searched'
  | 'Product List Viewed'
  | 'Product Viewed'
  | 'User Sign Up Started'
  | 'User Sign Up Step Viewed'
  | 'User Log In Started'
  | 'Checkout Started'
  | 'Checkout Step Viewed'
  | 'Payment Info Entered'
  | 'Checkout Step Complete';
