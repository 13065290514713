import { showAlert } from '@/components/alert/showAlert';
import ChatPrefillHelper from '@jetslash/market-frontend-shared-core/src/utilities/chat/ChatPrefillHelper';
import { goToUrl } from '@/utility/urlHelpers';
import DesignText from '@/components/typography/DesignText';
import CTAButton from '@/components/design/CTAButton';
import { FunctionComponent } from 'react';
import {
  ERROR_MESSAGE_DEFAULT_FLIGHT_SEARCH_TEXT,
  ERROR_MESSAGE_DEFAULT_HEADER,
  ERROR_MESSAGE_DEFAULT_TEXT,
} from '@jetslash/market-frontend-shared-core/src/utilities/errorHandling/constants';
import useCurrentUser from '@jetslash/market-frontend-shared-core/src/hooks/useCurrentUser';

interface IProps {
  link: string;
  buttonText: string;
  text?: string;
}

const RequestErrorAlertBody: FunctionComponent<IProps> = ({ link, buttonText, text = ERROR_MESSAGE_DEFAULT_TEXT }) => {
  const { currentUser } = useCurrentUser();
  const onClick = () => {
    goToUrl(link);
  };

  const showConciergeLink = currentUser?.conciergeEnabled;

  return (
    <div data-testid="RequestErrorAlertBody">
      <div style={{ marginBottom: 14 }}>
        <DesignText richText>{text}</DesignText>
      </div>
      {showConciergeLink && (
        <>
          <div style={{ marginBottom: 14 }}>
            <DesignText richText>{ERROR_MESSAGE_DEFAULT_FLIGHT_SEARCH_TEXT}</DesignText>
          </div>
          <div style={{ marginTop: 35 }}>
            <CTAButton text={buttonText} onClick={onClick} />
          </div>
        </>
      )}
    </div>
  );
};

const showFlightSearchRequestError = (errorMessage: string = null, errorType: string = null) => {
  showAlert(
    ERROR_MESSAGE_DEFAULT_HEADER,
    <RequestErrorAlertBody
      link={ChatPrefillHelper.path(ChatPrefillHelper.typeForSearchError(errorType))}
      text={errorMessage}
      buttonText="Chat"
    />,
  );
};

export default showFlightSearchRequestError;
