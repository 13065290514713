import React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import ErrorStatus500 from './ErrorStatus500';
import ErrorStatus400 from './ErrorStatus400';

interface IDefaultState {
  setErrorStatusCode?: (statusCode: number) => {};
}

const defaultState: IDefaultState = {};
// A context will be the way that we allow components lower down
// the tree to trigger the display of an error page
const ErrorStatusContext = React.createContext(defaultState);

// The top level component that will wrap our app's core features
export const ErrorStatusHandler = ({ children }) => {
  const router = useRouter();
  const [errorStatusCode, setErrorStatusCode] = React.useState();

  // Make sure to "remove" this status code whenever the user
  // navigates to a new URL. If we didn't do that, then the user
  // would be "trapped" into error pages forever
  React.useEffect(() => {
    const handleRouteChange = () => {
      setErrorStatusCode(undefined);
    };

    // Listen for changes to the current location.
    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      // cleanup the listener on unmount
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  // This is what the component will render. If it has an
  // errorStatusCode that matches an API error, it will only render
  // an error page. If there is no error status, then it will render
  // the children as normal
  const renderContent = () => {
    if (errorStatusCode && (errorStatusCode >= 500 || errorStatusCode === 0)) {
      return <ErrorStatus500 />;
    }
    if (errorStatusCode && errorStatusCode >= 400) {
      return <ErrorStatus400 />;
    }

    // ... more HTTP codes handled here

    return children;
  };

  // We wrap it in a useMemo for performance reasons. More here:
  // https://kentcdodds.com/blog/how-to-optimize-your-context-value/
  const contextPayload = React.useMemo(() => ({ setErrorStatusCode }), [setErrorStatusCode]);

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  // @ts-ignore
  return <ErrorStatusContext.Provider value={contextPayload}>{renderContent()}</ErrorStatusContext.Provider>;
};

ErrorStatusHandler.propTypes = { children: PropTypes.node.isRequired };

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useErrorStatus = () => React.useContext(ErrorStatusContext);
