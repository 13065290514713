import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import colors from '@jetslash/market-frontend-shared-core/src/styles/constants/colors';
import LoadingAnimation from './LoadingAnimation';

const styles = {
  modalBackground: {
    position: 'absolute',
    margin: 'auto',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    backgroundColor: colors.surfaceTransparent,
    zIndex: 999,
  } as React.CSSProperties,
  activityIndicatorWrapper: {
    // backgroundColor: "#FFFFFF",
    minHeight: 60,
    height: '100%',
    width: '100%',
    margin: 'auto',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  } as React.CSSProperties,
};

interface IProps {
  loading: boolean;
}

const LoadingComponentOverlay: FunctionComponent<IProps> = ({ loading }) => {
  if (loading) {
    return (
      <div style={styles.modalBackground}>
        <div style={styles.activityIndicatorWrapper}>
          <LoadingAnimation />
        </div>
      </div>
    );
  }
  return null;
};

LoadingComponentOverlay.propTypes = {
  loading: PropTypes.bool,
};

LoadingComponentOverlay.defaultProps = {
  loading: false,
};

export default LoadingComponentOverlay;
