const clientToServerAttributeMap = {
  flightSegments: 'segments',
  requestType: 'request_type',
  origin: 'orig',
  originId: 'orig_id',
  originAutocompleteSessionToken: 'orig_session',
  destination: 'dest',
  destinationId: 'dest_id',
  destinationAutocompleteSessionToken: 'dest_session',
  departDate: 'depart',
  departTime: null,
  numPax: 'pax',
};

const serverToClientAttributeMap = {
  segments: 'flightSegments',
  request_type: 'requestType',
  orig: 'origin',
  orig_id: 'originId',
  orig_session: 'originAutocompleteSessionToken',
  dest: 'destination',
  dest_id: 'destinationId',
  dest_session: 'destinationAutocompleteSessionToken',
  depart: 'departDate',
  pax: 'numPax',
};

export { clientToServerAttributeMap, serverToClientAttributeMap };
