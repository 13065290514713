// https://docs.google.com/spreadsheets/d/1POE8V8XacpD53ubFnUxLeOqpaGpfALUETsmdqXqSuRc/edit#gid=0
export default {
  brandPrimary: '#182e4d',
  brandSecondary: '#ad384a',
  brandInfo: '#ad384a',
  brandLight: '#e6e7e8',
  brandDanger: '#dc3545',
  brandWarning: '#ffc107',
  brandSuccess: '#28a745',
  brandSurface: '#e6e7e8',
  brandSurfaceOpaque: '#e6e7e8',
  brandWhite: 'white',

  // Web color definitions
  primary: '#182e4d',
  primaryLight: '#305c9b',
  primaryFeint: '#d7e3f3',
  primaryTransparent: '#182e4d80',
  secondary: '#ad384a',
  secondaryTransparent: '#ad384a80',
  info: '#ad384a',
  light: 'e6e7e8',
  // Surface
  surface: '#e6e7e8',
  surfaceExtraDark: '#323437',
  surfaceDark: '#63676c',
  surfaceAlmostDark: '#b1b4b7',
  surfaceTransparent: '#e6e7e840',
  // Danger
  danger: '#dc3545',
  dangerLight: '#eb8c95',
  dangerLightest: '#fae3e5',
  // Warning
  warning: '#a07800',
  warningLight: '#ffc107',
  warningLightest: '#ffe7a0',
  // Success
  success: '#28a745',
  successLight: '#5dd879',
  successLightest: '#d8f6df',
  // Standards
  black: '#000000',
  defaultTextColor: '#63676c',
};
