import React, { FunctionComponent, useState } from 'react';
import NavMenuBody from '@/components/layout/PageHeader/NavMenuBody';
import NavMenuBtn from '@/components/layout/PageHeader/NavMenuBtn';
import style from './styles.module.scss';

interface IProps {
  children: React.ReactNode;
}

const NavMenu: FunctionComponent<IProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={style.NavMenu}>
      <NavMenuBtn
        onClick={() => {
          setOpen(!open);
        }}
        open={open}
      />
      <NavMenuBody open={open}>{children}</NavMenuBody>
    </div>
  );
};

export default NavMenu;
