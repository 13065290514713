import React, { FunctionComponent } from 'react';
import DesignText from '@/components/typography/DesignText';
import ErrorPageLayout from './ErrorPageLayout';

interface IProps {
  message?: string;
}

const ErrorStatus400: FunctionComponent<IProps> = ({ message = 'The page you are looking for does not exist.' }) => (
  <ErrorPageLayout>
    <div style={{ marginBottom: 40 }}>
      <DesignText size="xxl" color="white" responsive>
        Oops, it looks like you&apos;ve flown off course!
      </DesignText>
    </div>
    <div style={{ marginBottom: 40 }}>
      <DesignText size="xl" color="white" responsive>
        {message}
      </DesignText>
    </div>
  </ErrorPageLayout>
);

export default ErrorStatus400;
