import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import Button from './Button';
import Icon from '../Icon';
import styles from './CTAButton.module.scss';

interface IProps {
  text: string;
  mobileText?: string;
  icon?: string;
  htmlType?: 'submit' | 'button';
  onClick: () => void;
  disabled?: boolean;
  style?: any;
  type?: 'primary' | 'default';
}

const CTAButton: FunctionComponent<IProps> = ({
  text,
  icon,
  onClick,
  disabled,
  style,
  type = 'primary',
  htmlType = 'submit',
  mobileText = null,
}) => {
  const defaultIconName = 'check';
  const iconName = icon || defaultIconName;

  let renderText = <>&nbsp;&nbsp;{text}</>;
  if (mobileText) {
    renderText = (
      <>
        <span className={classNames(styles.base, styles.desktop)}>&nbsp;&nbsp;{text}</span>
        <span className={classNames(styles.base, styles.mobile)}>&nbsp;&nbsp;{mobileText}</span>
      </>
    );
  }
  return (
    <Button
      block
      className={styles.base}
      type={type}
      onClick={onClick}
      disabled={disabled}
      style={style}
      htmlType={htmlType}
    >
      <Icon name={iconName} style={{ lineHeight: '28px' }} />
      {renderText}
    </Button>
  );
};

export default CTAButton;
