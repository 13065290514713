import * as Sentry from '@sentry/nextjs';
import LogRocket from 'logrocket';
import { IPlatformToolReporty } from '@jetslash/market-frontend-shared-core/src/types/platform';

function error(e, data = {}, originalError = null) {
  try {
    // Need to delay until session URL is Available for us to report session
    if (LogRocket) {
      LogRocket.getSessionURL((sessionURL) => {
        Sentry.configureScope((scope) => {
          scope.setExtra('error', originalError || e);
          scope.setExtra('contextData', data);
          scope.setExtra('LogRocket', sessionURL);
          Sentry.captureException(e);
        });
      });
    } else {
      Sentry.configureScope((scope) => {
        scope.setExtra('contextData', data);
        scope.setExtra('error', originalError || e);
        Sentry.captureException(e);
      });
    }
    console.error(e);
  } catch (exception) {
    Sentry.captureException(exception);
  }
}

function log(msg) {
  console.info(msg);
  Sentry.captureMessage(msg);
}

function captureApiError(e) {
  try {
    const err = e.originalError || e;
    if (err.response) {
      if (err.response.status === 500) {
        error('Internal Server Error', { sentryXhr: err.request.sentryXhr }, e);
      } else {
        error(err.statusMessage, { sentryXhr: err.request.sentryXhr });
      }
      // client received an error response (5xx, 4xx)
    } else if (err.request) {
      error(err.statusMessage, { sentryXhr: err.request.sentryXhr });
    } else {
      error(e);
    }
  } catch (caughtError) {
    error(caughtError);
  }
}
const Reporty: IPlatformToolReporty = {
  error,
  log,
  captureApiError,
};

export default Reporty;
