import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Icon from '@/components/Icon';
import ComponentLink from '@/components/navigation/ComponentLink';
import styles from './styles.module.scss';

interface IProps {
  to: string;
  text: string;
  strong?: boolean;
  icon?: string;
  aTag?: boolean;
  textSize?: number;
}

const FooterLink: FunctionComponent<IProps> = ({ to, text, strong, icon, aTag = false, textSize }) => (
  <div
    className={classNames(styles.FooterLink, {
      [`${styles.strong}`]: strong,
    })}
  >
    {icon && (
      <>
        <Icon outline name={icon} />
        &nbsp;&nbsp;
      </>
    )}
    {aTag && <a href={to}>{text}</a>}
    {!aTag && (
      <ComponentLink style={{ fontSize: textSize }} href={to}>
        {text}
      </ComponentLink>
    )}
  </div>
);

export default FooterLink;
