import 'core-js'; // Imports polyfills
import '../styles/globals.scss';
import '../styles/cityCarousel.scss';
import '@/providers/LogRocket/LogRocketInitSnippet';

import React, { useEffect } from 'react';

import { AppProps } from 'next/app';
import { loadStripe } from '@stripe/stripe-js/pure';

import AppConfigurator from '@jetslash/market-frontend-shared-core/src/appConfigurator';
import { PlatformToolsContextProvider } from '@jetslash/market-frontend-shared-core/src/contexts/PlatformToolsContext';
import { CurrentUserContextProvider } from '@jetslash/market-frontend-shared-core/src/contexts/CurrentUserContext';

import FrontPluginContextProvider from '@/contexts/FrontPluginContext';

import { publicEnv } from '@/appConfig';
import { AuthenticatedContextProvider } from '@/contexts/AuthenticatedContext';
import useUtmParameters from '@/hooks/useUtmParamters';
import usePageViews from '@/utility/eventTracking/usePageViews';
import { getToken, logout, withAuthSync } from '@/utility/auth/authWrapper';
import Reporty from '@/utility/errorHandling/Reporty';
import PersistenceProvider from '@/providers/persistence/PersistenceProvider';
import PageResourcesWrapper from '@/components/configuration/PageResourcesWrapper';
import Head from 'next/head';
import useAnalyticsTrackingProvider from '@/providers/platformTools/useAnalyticsTrackingProvider';
import { FlightSearchRequestProvider } from '@jetslash/market-frontend-shared-core/src/contexts/FlightSearchRequestContext';

// Load up any new app configurations through this object.
// It'll get passed to AppConfigurator to be handled specifically.
const envVars = publicEnv;

const platformConfiguration = {
  ENV: envVars,
  constants: {
    platform: 'web',
    stripePromise: loadStripe(envVars.STRIPE_CLIENT_PUBLIC),
  },
  getAuthToken: getToken,
  handleUnauthorizedRequest: async () => {
    await logout();
  },
  platformTools: {
    Reporty,
    PersistenceProvider,
  },
};

const noOverlayWorkaroundScript = `
  window.addEventListener('error', event => {
    event.stopImmediatePropagation()
  })

  window.addEventListener('unhandledrejection', event => {
    event.stopImmediatePropagation()
  })
`;

// in a <Head> component:

declare global {
  interface Window {
    analytics: any;
    Sentry: {
      withScope: any;
      captureException: any;
      configureScope: (scope: any) => any;
    };
    LogRocket: {
      getSessionURL: (sessionURL: any) => any;
    };
    ReactNativeWebView?: {
      postMessage: (message: string) => void;
    };
    // @ts-ignore
    postMessage: (message: any, targetOrigin: string, transfer?: Transferable[]) => void;
    conversationsClient: any;
  }
}

export const appConfigurator = new AppConfigurator(platformConfiguration);

appConfigurator.configure(); // This gets called multiple times when running on Next JS dev

const WrappedApp = withAuthSync(Index);

WrappedApp.displayName = 'WrappedApp';

export default WrappedApp;

// eslint-disable-next-line
function Index(props: AppProps & { authIsHydrated?: boolean; jwtToken?: string }) {
  const { Component, pageProps, authIsHydrated, jwtToken } = props;

  // Any time the page changes we need to update utmParams with the new location
  const { setWithQueryString } = useUtmParameters();
  useEffect(() => {
    setWithQueryString(globalThis?.location?.search);
  }, [globalThis?.location?.search]);

  usePageViews();

  return (
    <>
      <Head>
        {process.env.NODE_ENV !== 'production' && (
          <script dangerouslySetInnerHTML={{ __html: noOverlayWorkaroundScript }} />
        )}

        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <PlatformToolsContextProvider
        Reporty={platformConfiguration.platformTools.Reporty}
        PersistenceProvider={platformConfiguration.platformTools.PersistenceProvider}
        useAnalyticsTrackingProvider={useAnalyticsTrackingProvider}
      >
        <FrontPluginContextProvider>
          <FlightSearchRequestProvider>
            <AuthenticatedContextProvider authIsHydrated={authIsHydrated} jwtToken={jwtToken}>
              <CurrentUserContextProvider jwtToken={jwtToken} authIsHydrated={authIsHydrated}>
                <PageResourcesWrapper
                  pageProps={pageProps}
                  stripePromise={platformConfiguration.constants.stripePromise}
                >
                  <Component {...pageProps} />
                </PageResourcesWrapper>
              </CurrentUserContextProvider>
            </AuthenticatedContextProvider>
          </FlightSearchRequestProvider>
        </FrontPluginContextProvider>
      </PlatformToolsContextProvider>
    </>
  );
}

Index.displayName = 'App';
