import React, { FunctionComponent } from 'react';
import Header from '@/components/typography/Header';
import Row from '@/components/layout/Row';
import Col from '@/components/layout/Col';
import UserTestimonialCard from '@/components/landingPages/UserTestimonialCard';
import { IUserTestimonialSection } from '@/pageGeneration/services/prismicApi/userTestimonials';
import styles from './UserTestimonialSection.module.scss';

interface IProps {
  content: IUserTestimonialSection;
}

const UserTestimonialSection: FunctionComponent<IProps> = ({ content }) => {
  const { testimonials } = content;

  const displayAircraftTypeSubtitle = (manufacturer = null, name = null) => {
    if (manufacturer && name) {
      return `${manufacturer} ${name}`;
    }
    if (manufacturer) {
      return manufacturer;
    }
    if (name) {
      return name;
    }
    return null;
  };

  return (
    <div className={styles.container} data-testid="UserTestimonialSectionContainer">
      <Header level={3} color="primary" strong>
        {content.title}
      </Header>
      <Header level={5} thin marginBottom={24}>
        {content.subtitle}
      </Header>
      <Row gutter={[30, 10]}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <UserTestimonialCard
            imageUrl={testimonials[0]?.aircraftType?.image?.url}
            title={testimonials[0].name}
            subtitle={displayAircraftTypeSubtitle(
              testimonials[0].aircraftType?.manufacturer,
              testimonials[0].aircraftType?.name,
            )}
            content={testimonials[0].text}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <UserTestimonialCard
            imageUrl={testimonials[1]?.aircraftType?.image?.url}
            title={testimonials[1].name}
            subtitle={displayAircraftTypeSubtitle(
              testimonials[1].aircraftType?.manufacturer,
              testimonials[1].aircraftType?.name,
            )}
            content={testimonials[1].text}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <UserTestimonialCard
            imageUrl={testimonials[2]?.aircraftType?.image?.url}
            title={testimonials[2].name}
            subtitle={displayAircraftTypeSubtitle(
              testimonials[2].aircraftType?.manufacturer,
              testimonials[2].aircraftType?.name,
            )}
            content={testimonials[2].text}
          />
        </Col>
      </Row>
    </div>
  );
};

export default UserTestimonialSection;
