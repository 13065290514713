import React, { FunctionComponent } from 'react';
import { Col as AntdCol } from 'antd';
import { ColProps } from 'antd/lib/grid';
import classNames from 'classnames';
import styles from './Col.module.scss';

interface IProps extends ColProps {
  textCenter?: boolean;
  textLeft?: boolean;
  textRight?: boolean;
}

const Col: FunctionComponent<IProps> = ({ textCenter, textLeft, textRight, className, ...props }) => (
  <AntdCol
    {...props}
    className={classNames(className, {
      [styles.textCenter]: textCenter,
      [styles.textRight]: textRight,
      [styles.textLeft]: textLeft,
    })}
  />
);

export default Col;
